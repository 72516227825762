import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-slip-layout',
  templateUrl: './loading-slip-layout.component.html',
  styleUrls: ['./loading-slip-layout.component.css']
})
export class LoadingSlipLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
