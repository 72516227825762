<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-6 my-auto    ">
            <nav aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/manageExpenseLog">Manage Expense Log</li>
                    <li class="breadcrumb-item active" aria-current="page">Add Hire Vichele Flat</li>

                </ol>
            </nav>
        </div>
        <div class="col-xl-6 col-12 col-sm-12 col-md-12">
            <ul class="addaccount">
                <li class="my-auto"><button (click)="backTo()" class="btn btn-primary btn-sm text-white px-4"><i
                            class="fas fa-arrow-left"></i> Back</button></li>
            </ul>
        </div>

    </div>

    <div class="row">
        <div class="col-xl-12">
            <form [formGroup]="addHireVehicleFlatForm" (ngSubmit)="addHireVehicleFlat()" enctype="multipart/form-data">
                <div class="row account mb-3">
                    
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Supervisor Name</label>
                                        <select id="supervisornamectrl" formControlName="supervisorname" placeholder="Select Status" class="form-control shadow-none">
                                            <option disabled value="">--Select Supervisor Name--</option>
                                            <option *ngFor="let s of superVisor" value="{{s.full_name}}">{{s.full_name}}</option>
                                               
                                        </select>
                             
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Expense Category</label>
                            <select id="expenseCategoryctrl" formControlName="expenseCategory"  class="form-control shadow-none">
                                <option disabled  value="">--Select Expense Category--</option>
                                <option  value="Hire Vehicle Flat">Hire Vehicle Flat</option>
                              </select>
                             </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Company Name</label>
                            <select id="companynamectrl" formControlName="companyname"  class="form-control shadow-none">
                                <option disabled value="">--Select Company Name--</option>
                                <option *ngFor="let c of company" value="{{c.company_name}}">{{c.company_name}}</option>
                                
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Vehicle Type</label>
                            <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type" class="form-control shadow-none">
                                <option  disabled value="">--Select Vehicle Type--</option>
                                 <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">{{c.vehicletype_name}}</option> 
                                
                            </select>
                          
                        </div>
                    </div>

                    
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Expense Head</label>
                            <input type="text" id="expenseHeadctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Head" formControlName="expenseHead">

                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Quantity </label>
                            <input type="text" id="quantityctrl" class="form-control shadow-none"
                                placeholder="Enter Quantity " formControlName="quantity">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Weight </label>
                            <div class="d-flex align-items-center">
                                <input type="text" class="form-control me-1 shadow-none" id="weightctrl"
                                    placeholder="Enter Weight" formControlName="weight">
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Expense Date</label>
                            <div class="d-flex align-items-center">
                                <input type="date" class="form-control me-1 shadow-none" id="expenseDatectrl"
                                    placeholder="Enter Expense Date" formControlName="expenseDate">
                                
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">License Number</label>
                            <input type="text" id="licenseNumberctrl" class="form-control shadow-none"
                                placeholder="Enter License Number" formControlName="licenseNumber">
                        </div>
                    </div>
                   
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Total Expense Amount</label>
                            <input type="text" id="totalExpenseAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Total Expense Amount" formControlName="totalExpenseAmount">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Expense Description</label>
                            <input type="text" id="expenseDescriptionctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Description" formControlName="expenseDescription">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Detention</label>
                            <input type="text" id="detentionctrl" class="form-control shadow-none"
                                placeholder="Enter Detention" formControlName="detention">
                        </div>
                    </div>
                   
                   
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Toll Tax</label>
                            <input type="text" id="tollTaxctrl" class="form-control shadow-none"
                                placeholder="Enter Toll Tax" formControlName="tollTax">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Labour Charge</label>
                            <input type="text" id="labourChargectrl" class="form-control shadow-none"
                                placeholder="Enter Labour Charge" formControlName="labourCharge">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Other Charges</label>
                            <input type="text" id="otherChargesctrl" class="form-control shadow-none"
                                placeholder="Enter Other Charges" formControlName="otherCharges">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">KM </label>
                            <input type="text" id="KMctrl" class="form-control shadow-none"
                                placeholder="Enter KM" formControlName="KM">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Balance to be Paid</label>
                            <input type="text" id="balancetobePaidctrl" class="form-control shadow-none"
                                placeholder="Enter Balance to be Paid" formControlName="balancetobePaid">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">LS Number</label>
                            <input type="text" id="LRNumberctrl" class="form-control shadow-none"
                                placeholder="Enter LS Number" formControlName="LRNumber">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Vehicle Number</label>
                            <input type="text" id="vehicleNumberctrl" class="form-control shadow-none"
                                placeholder="Enter Vehicle Number" formControlName="vehicleNumber">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Source </label>
                            <input type="text" id="sourcectrl" class="form-control shadow-none"
                                placeholder="Enter Source " formControlName="source">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Destination </label>
                            <input type="text" id="destinationctrl" class="form-control shadow-none"
                                placeholder="Enter Destination " formControlName="destination">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Cash Advance Paid</label>
                            <input type="text" id="cashAdvancePaidctrl" class="form-control shadow-none"
                                placeholder="Enter Cash Advance Paid" formControlName="cashAdvancePaid">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Broker Name</label>
                            <input type="text" id="brokerNamectrl" class="form-control shadow-none"
                                placeholder="Enter Broker Name" formControlName="brokerName">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Broker Number</label>
                            <input type="text" id="brokerNumberctrl" class="form-control shadow-none"
                                placeholder="Enter Broker Number" formControlName="brokerNumber">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            
                            <label for="">Rate</label>
                            <input type="text" id="ratectrl" class="form-control shadow-none"
                                placeholder="Enter Rate " formControlName="rate">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6 my-auto">
                        <div class="row mt-2">
                            <div class="col-xl-6">
                                <div class="form-check">
                                    <input class="form-check-input"   type="radio" formControlName="optradiopod" name="optradiopod" id="lpod" value="Local POD">
                                    <label class="form-check-label" for="lpod">
                                        Local POD
                                    </label>
                                  </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio"  formControlName="optradiopod" name="optradiopod"  id="hpod" value="Head Office POD">
                                    <label class="form-check-label" for="hpod">
                                        Head Office POD
                                    </label>
                                  </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group accountsinp text-center mb-3">
                            <p>Attach Receipt1</p>
                             <input type="file" accept="image/*" (change)="fileChangeEventattachReceipt1($event)" id="attachReceipt1ctrl"
                                class="form-control shadow-none" formControlName="attachReceipt1">

                        </div>

                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group text-center accountsinp  mb-3">
                            <p>Attach Receipt2</p>
                            <input type="file" accept="image/*" id="attachReceipt2ctrl" (change)="fileChangeEventattachReceipt2($event)"
                                class="form-control shadow-none" formControlName="attachReceipt2">

                        </div>

                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group text-center accountsinp  mb-3">
                            <p>Attach Receipt3 </p>
                            <input type="file" accept="image/*" id="attachReceipt3ctrl"
                                (change)="fileChangeEventattachReceipt3($event)" class="form-control shadow-none"
                                formControlName="attachReceipt3">

                        </div>

                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group text-center accountsinp  mb-3">
                            <p>LS Receipt1 </p>
                            <input type="file" accept="image/*" id="LRReceipt1ctrl"
                                (change)="fileChangeEventLRReceipt1($event)" class="form-control shadow-none"
                                formControlName="LRReceipt1">

                        </div>

                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group text-center accountsinp  mb-3">
                            <p>LS Receipt2 </p>
                            <input type="file" accept="image/*" id="LRReceipt2ctrl"
                                (change)="fileChangeEventLRReceipt2($event)" class="form-control shadow-none"
                                formControlName="LRReceipt2">

                        </div>

                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group text-center accountsinp  mb-3">
                            <p>LS Receipt3 </p>
                            <input type="file" accept="image/*" id="LRReceipt3ctrl"
                                (change)="fileChangeEventLRReceipt3($event)" class="form-control shadow-none"
                                formControlName="LRReceipt3">

                        </div>

                    </div>
                    
                    
                    
                   
                    <div class="col-md-12">

                        <div class="row justify-content-center mt-2">
                            <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">

                                <button class="btn btn-primary text-white px-5" type="button" (click)="resetForm()"
                                    mat-raised-button>Reset</button>
                            </div>
                            <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">

                                <button class="btn btn-primary text-white px-5" type="submit" mat-raised-button>Save
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>