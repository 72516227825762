import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-category-expense',
  templateUrl: './category-expense.component.html',
  styleUrls: ['./category-expense.component.css']
})
export class CategoryExpenseComponent implements OnInit {
  myArr: any=[]
  catArr: any=[]
  paramId:any
  month:any;
  userSessionData:any=JSON.parse(sessionStorage.getItem('userSession'));
  monthArrFull : any=["January", "February", "March", "April", "May", "June", "July", "August", "Septemember", "October", "November", "December"];
  monthArr : any=["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  paramCategory: any;
  categoryArray: any=[];
  year: any;
  constructor(private activatedRoute: ActivatedRoute,private router: Router, private superadminService: SuperAdminService) { 
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.paramCategory = param.category
      this.year = param.year;
    })
  }

  ngOnInit(): void {
    this.getMasterDataSubCategoryGroup()
  }
  getMasterDataSubCategoryGroup() {
    this.superadminService.getMasterDataSubCategoryGroup()
      .subscribe((res: { status: any, message: any, data: any }) => {
        this.myArr= res.data;
        this.myArr = this.myArr.filter((item)=>{
          return item._id.year == this.year
        })
        if(this.userSessionData.user_role_id[0]!=1){
          this.myArr = this.myArr.filter((item)=>{
            return item._id.supervisorId==this.userSessionData._id
          })
        }
        for(let i=0; i<this.myArr.length; i++){
          this.catArr.push({"supervisorId":this.myArr[i]._id.supervisorId,"companyId":this.myArr[i]._id.companyId,"month":this.monthArr[this.myArr[i]._id.month-1],"expenseSubCategoryId":this.myArr[i]._id.expenseSubCategoryId, "amount":this.myArr[i].totalExpenseAmount  })
        }
        this.superadminService.getClientList()
          .subscribe((client: { status: any, message: any, data: any }) => {
            if (client.status == true) {
              this.catArr.forEach((x, index) => {
                client.data.filter(y => {
                  if (y._id == x.companyId) this.catArr[index].companyName = y.clientName
                })
              });
              this.superadminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
                if(supervisors.status == true){
                  var supervisorsData = supervisors.data;
                  this.catArr.forEach((item,i)=>{
                    this.catArr[i].SupervisorName = supervisorsData.filter((item)=> this.catArr[i].supervisorId==item._id);
                    this.catArr[i].SupervisorName = this.catArr[i].SupervisorName[0]?this.catArr[i].SupervisorName[0]['full_name']:this.catArr[i].SupervisorName[0]
                  })
                  this.superadminService.getSubCategory().subscribe((subCategories: {status: any, message: any, data: any}) => {
                    if(subCategories.status == true){
                      var subCategoriesData = subCategories.data;
                      this.catArr.forEach((item,i)=>{
                        let category = subCategoriesData.filter((item)=> this.catArr[i].expenseSubCategoryId==item._id);
                        this.catArr[i].expenseSubCategory = category[0]?category[0]['expenseSubCategory']:category[0];
                        this.catArr[i].expenseCategory = category[0]?category[0]['expenseCategory']:category[0];
                      })
                      this.catArr = this.catArr.filter((item) => item.expenseCategory==this.paramCategory);
                      this.categoryArray = this.catArr;
                    }else{
                      console.log(res.message);
                    }
                  }, error => {
                    console.log('error', error);
                  })
                }else{
                  console.log(res.message);
                }
              }, error => {
                console.log('error', error);
              })
            }
            else{
              console.log(res.message);
            }
          }, error => {
            console.log('error', error);
          })
      }
      )
}
backTo(){
  window.history.back()
}
}
