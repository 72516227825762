import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddVechileComponent } from './add-vechile/add-vechile.component';
import { EditVechileComponent } from './edit-vechile/edit-vechile.component';
import { VechileListComponent } from './vechile-list/vechile-list.component';
import { ViewVehicleComponent } from './view-vehicle/view-vehicle.component';

const routes: Routes = [ {
  path: '', component:VechileListComponent,
},
{
  path: 'add-vehicle', component: AddVechileComponent,
},
{
  path: 'edit-vehicle/:id', component: EditVechileComponent,
},
{path:'view/:id', component:ViewVehicleComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageVehicleRoutingModule { }
