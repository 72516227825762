import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { ActivatedRoute, Router } from "@angular/router";
import { Chart } from "chart.js";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SuperAdminService } from "src/app/super-admin.service";

@Component({
  selector: "app-total-lr",
  templateUrl: "./total-lr.component.html",
  styleUrls: ["./total-lr.component.css"],
})
export class TotalLRComponent implements OnInit {
  runningLR: any
  uniqueRes: any = [];
  userSessionData: any = JSON.parse(sessionStorage.getItem("userSession"));
  lrExpenseAmount: any;
  clientList: any[] = [{}];
  invoiceList: any = [{}];
  clientName: any = [];
  arrays: any = [];
  closedArr: any = [];
  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  dataSource: MatTableDataSource<any>;
  displayedColumns: any = ['manualLR_no', 'LR_no', 'TripID', 'vehicle_id', 'vehicle_Types', 'from', 'to', 'date', 'stateCode', 'fixAdhoc', 'startmeterReading', 'endmeterReading', 'tripStartDate', 'tripEndDate', 'noofTools', 'up_down_km', 'extrahour', 'action'];
  year: number;
  summary: any = {};
  lrExpenseArray = [];
  receipts: any[] = [];
  totalExpenseAmount = 0;
  monthExpenses: any = [];
  monthDispenses: any = [];
  userroleid: any;
  pageName: any;
  LRData: any;
  dtOptions: DataTables.Settings = {};

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private superAdminService: SuperAdminService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }


  chart: Chart<"bar", any, string>;

  ngOnInit() {
    this.getInvoice();


    // setTimeout(() => {
    //   this.representation(this.pageName);
    // }, 2000);

    this.route.params.subscribe(params => {
      this.pageName = params['param1'];

      // console.log("data  LR from route parameters", this.pageName)
    })

    this.representation(this.pageName)

    this.userSessionData = JSON.parse(sessionStorage.getItem("userSession"));
    this.clientList = this.userSessionData.clientList;
    this.userroleid = this.userSessionData.user_role_id[0];
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      order: [],
    };
  }

  getLRS() {
    this.superAdminService.getlrs().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.superAdminService.getClientList().subscribe(
            (clients: { status: any; message: any; data: any }) => {
              if (res.status == true) {
                this.spinner.hide();
                //this.receipts = rawdata;
                if (this.userSessionData.user_role_id[0] != 1) {
                  rawdata = rawdata.filter((item: { receipt: { clientId: any; }; }) => {
                    return this.clientList.includes(item.receipt.clientId);
                  });
                }
                let temp: any[];
                rawdata.forEach((element: { receipt: { clientId: any; }; }, i: string | number) => {
                  temp = clients.data.filter(
                    (item: { _id: any; }) => item._id == element.receipt.clientId
                  );
                  rawdata[i].receipt.clientName = temp[0]
                    ? temp[0]["clientName"]
                    : temp[0];
                });
                this.receipts = rawdata.sort(function (a: any, b: any) {
                  return (
                    <any>new Date(b.receipt.created_date) -
                    <any>new Date(a.receipt.created_date)
                  );
                });
                if (this.pageName === 'Total LS') {
                  var onlyReceipts = this.receipts.map((item) => item.receipt);
                  this.dataSource = new MatTableDataSource(onlyReceipts);
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                }
                // else if (this.pageName === 'Pending Invoice') {
                //   // console.log("invoice clientNAme",this.invoiceList.filter((x) => x.clientName))
                //   // console.log("receipts",this.receipts.map(a => a.receipt ))
                //   this.receipts = this.receipts.map(a => a.receipt)
                //   let temp = []

                //   //  console.log("this.receipts final", this.receipts)


                //   this.invoiceList = this.invoiceList.filter((x) => x.clientName)
                //   this.receipts = this.receipts.filter((x) => x.tripEndDate)

                //   console.log("clientssss",this.clientList)
                //   console.log("invoiceList===", this.invoiceList)

                //   this.clientList.map((x: any) => {
                  
                //     this.invoiceList.map((y: any) => {
                //       if (x.clientId === y.clientName ) {
                //         temp.push({ "clientName": y.clientName, "date": x.created_date.split("T")[0] })
                //       }
                //     })
                //   }
                //   )
                  
                  
                //   this.clientName = temp
                //   this.dataSource = new MatTableDataSource(this.clientName);
                //   this.dataSource.paginator = this.paginator;
                //   this.dataSource.sort = this.sort;
                  



                // }
                else if (this.pageName === 'Running LS') {


                  this.receipts = this.receipts.filter(lr => {

                    if (lr.receipt.tripEndDate >= lr.receipt.tripStartDate) {
                      return lr

                    }
                    this.receipts = this.arrays

                    this.arrays.push(lr.receipt)
                    // console.log(this.receipts)


                    // console.log(this.arrays)
                    let onlyReceipts = this.receipts.map((item: { receipts: any; }) => item)

                    this.dataSource = new MatTableDataSource(onlyReceipts);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort
                    this.spinner.hide()

                  })

                }
                else if (this.pageName === 'Closed LS' || this.pageName === 'Total Invoice') {

                  this.receipts = this.receipts.filter(h => {
                    if (h.receipt.tripEndDate !== null) {
                      this.closedArr.push(h.receipt)
                      return h
                    }

                  }

                  )
                  this.receipts = this.closedArr;
                  let onlyClosed = this.closedArr.map((item: { closedArr: []; }) => item)
                  this.dataSource = new MatTableDataSource(onlyClosed)
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort
                  this.spinner.hide()
                }


              } else {
                if (res.status == 401) {
                  this.spinner.hide();
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl("superAuth/login");
                } else {
                  this.toastr.error(res.message);
                  this.spinner.hide();
                }
              }
            },
            (error) => { }
          );
        } else {
          if (res.status == 401) {
            this.spinner.hide();
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.error(res.message);
            this.spinner.hide();
          }
        }
      },
      (error) => {
        this.toastr.error("Something went wrong! Please contact us");
        this.spinner.hide();
      }

    );


  }



  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {

          var rawdata = res.data;
          this.clientList = rawdata;
          let userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
          let userroleid = userSessionData.user_role_id[0];
          if (userroleid != 1) {
            let clients = userSessionData.clientList;
            this.clientList = clients.map((email: any) => {
              return this.clientList.filter(item => item.clientEmail == email)[0]
            });
          }
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.spinner.hide();
            this.toastr.error(res.message);
          }
        }

      });

  }


  filterData(value: string) {
    this.dataSource.filter = value;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === "") {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "consignee_name":
          return compare(
            lower(a.consignee_name),
            lower(b.consignee_name),
            isAsc
          );
        case "consignor_name":
          return compare(
            lower(a.consignor_name),
            lower(b.consignor_name),
            isAsc
          );
        case "clientName":
          return compare(lower(a.clientName), lower(b.clientName), isAsc);
        case "date":
          return compare(a.date, b.date, isAsc);
        case "from":
          return compare(lower(a.from), lower(b.from), isAsc);
        case "to":
          return compare(lower(a.to), lower(b.to), isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value: string) {
      return value ? value.toLowerCase() : value;
    }
  }

  backTo() {
    window.history.back();
  }

  // runningReceipts(){

  //   console.log(this.receipts)
  //   for(let i= 0 ; i < this.receipts.length; i++){
  //   this.receipts = this.receipts.filter(lr => {
  //     if(lr.receipt.tripEndDate < lr.receipt.tripStartDate)
  //      return [lr]
  //      console.log("lr Receipt--",lr.receipt.LR_no)
  //      this.arrays.push(lr.receipt.LR_no)
  //      console.log('data of total LRNO',this.arrays)
  //      this.dataSource = new MatTableDataSource(this.arrays);
  //      this.dataSource.paginator = this.paginator;
  //      this.dataSource.sort = this.sort
  //      this.spinner.hide()
  //   })
  // }}

  representation(event: any) {
    this.spinner.show()
    let value = event
    this.getLRS()
    // console.log(this.receipts, this.pageName)

    switch (value) {

      case 'Total LS':
        this.getLRS();


      // case 'Closed LR':
      //   this.receipts = this.receipts.filter(h => {
      //     if (h.receipt.tripEndDate !== null) {
      //       this.closedArr.push(h.receipt)
      //       return h
      //     }

      //   }

      //   )
      //   this.receipts
      //   // console.log("closed lr datas", this.receipts)
      //   let onlyClosed = this.receipts.map((item: { receipts: []; }) => item)
      //   this.dataSource = new MatTableDataSource(onlyClosed)
      //   this.dataSource.paginator = this.paginator;
      //   this.dataSource.sort = this.sort
      //   this.spinner.hide()

      // case 'Total Invoice':
      //   this.receipts = this.receipts.filter(c => {
      //     if (c.receipt.tripEndDate !== null) {
      //       this.arrays.push(c.receipt)
      //       return c
      //     }
      //   }
      //   )
      //   this.receipts = this.arrays
      //   // console.log("closed lr datas", this.arrays)
      //   let totalInvoice = this.receipts.map((item: { receipts: any; }) => item)
      //   this.dataSource = new MatTableDataSource(totalInvoice)
      //   this.dataSource.paginator = this.paginator;
      //   this.dataSource.sort = this.sort
      //   this.spinner.hide()

      // case 'Pending Invoice':
      //   this.getClientList()
      //   this.getInvoice()
      //     console.log("clientssss",this.clientList)
      //             console.log("invoiceList===", this.invoiceList)

      //   this.receipts = this.receipts.filter(c => {

      //     if (c.receipt.tripEndDate !== null) {

      //       this.arrays.push(c.receipt)
      //       return c

      //     }
          
      //     this.receipts = this.arrays
      //     // console.log("closed lr datas",this.arrays)
      //     let onlyReceipts = this.receipts.map((item: { receipts: any; }) => item)
      //     this.dataSource = new MatTableDataSource(onlyReceipts)
      //     this.dataSource.paginator = this.paginator;
      //     this.dataSource.sort = this.sort
      //     this.spinner.hide()
      //   }
      //   )


    }
  }

  getInvoice() {
    this.superAdminService.getInvoice().subscribe((invoices: { status: any, message: any, data: any }) => {
      if (invoices.status == true) {
        this.spinner.hide();
        let newrawdata = invoices.data;
        this.invoiceList = newrawdata;

      }
    })

  }

}




