import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { environment } from 'src/environments/environment';
// import lgZoom from 'lightgallery/plugins/zoom';
// import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-view-vehicle',
  templateUrl: './view-vehicle.component.html',
  styleUrls: ['./view-vehicle.component.css']
})
export class ViewVehicleComponent {
  paramId:any;
  particlarVehicle:any;
  apiUrl = environment.domain;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private superAdminService: SuperAdminService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    this.activatedRoute.params.subscribe((param) => {
      this.paramId = param.id;
      this.getparticlarVehicle(this.paramId);
    });
  }
  getparticlarVehicle(id: string) {
    this.superAdminService.getparticlarVehicle(id).subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          this.particlarVehicle = res.data[0];
   
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.error(res.message);
          }
        }
      },
      (error) => {}
    );
  }
  backTo() {
    window.history.back()
  }
  // onBeforeSlide = (detail: BeforeSlideDetail): void => {
  //   const { index, prevIndex } = detail;
  //   console.log(index, prevIndex);
  // };
}
