import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';

import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.css']
})
export class NewInvoiceComponent implements OnInit {
  checkInvoiceType: any;
  invtot:any;
  tooltaxPaidtot:any;
  clientName:any;
  extrahour:any;
  vehicles: any;
  clientList = [];
  particlarLR: any;
  kmtot:any;
  users: any;
  paramId;

  editLR = new FormGroup({
    clientList: new FormControl(''),
    ConsigneeName: new FormControl(''),
    ConsigneeAddress: new FormControl(''),
    ConsignorName: new FormControl(''),
    ConsignorAddress: new FormControl(''),
    VehicleNo: new FormControl(''),
    vehicleType: new FormControl(''),
    from: new FormControl(''),
    to: new FormControl('',),
    GSTIN: new FormControl(''),
    date: new FormControl(''),
    RoadPermitNo: new FormControl(''),
    StateCode: new FormControl(''),
    DLY: new FormControl(''),
    DeclaredValue: new FormControl(''),
    fixAdhoc: new FormControl(''),
    quantity: new FormControl(''),
    transportorName: new FormControl(''),
    transportorMobile: new FormControl(''),
    driverName: new FormControl(''),
    driverMobile: new FormControl(''),
    tollAttachment: new FormControl(''),
    loadingSlipAttchment: new FormControl(''),
    Frieght: new FormControl(''),


    startmeterReading: new FormControl(''),
    endmeterReading: new FormControl(''),
    tripSDate: new FormControl(''),
    tripEDate: new FormControl(''),
    outtime: new FormControl(''),
    INtime: new FormControl(''),
    noofTools: new FormControl(''),
    tooltaxPaid: new FormControl(''),
    labourPaid: new FormControl(''),
    otherPayment: new FormControl(''),
    updownkm: new FormControl(''),
    totalhour: new FormControl(''),
    extrahour: new FormControl(''),
    detention: new FormControl(''),
    advance: new FormControl(''),
    balance: new FormControl(''),
    balancePaid: new FormControl(''),
    vehicleRate: new FormControl(''),
    // clientList: new FormControl('', [Validators.required]),
    LR_no: new FormControl('', [Validators.required]),
    // ConsigneeName: new FormControl('', [Validators.required]),
    // ConsigneeAddress: new FormControl('', [Validators.required]),
    // ConsignorName: new FormControl('', Validators.required),
    // ConsignorAddress: new FormControl('', Validators.required),
    // VehicleNo: new FormControl('', [Validators.required]),
    // from: new FormControl('', Validators.required),
    // to: new FormControl('', Validators.required),
    // GSTIN: new FormControl('', [Validators.required]),
    // date: new FormControl('', Validators.required),

    // startmeterReading: new FormControl(''),
    // endmeterReading: new FormControl(''),
    // outtime: new FormControl(''),
    // INtime: new FormControl(''),
    // noofTools: new FormControl(''),
    // tooltaxPaid: new FormControl(''),
    // labourPaid: new FormControl(''),
    // otherPayment: new FormControl(''),
    // updownkm: new FormControl(''),
    // totalhour: new FormControl(''),
    // vehicleRate: new FormControl('')

    //status: new FormControl('', Validators.required)
  })
  constructor(private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,private router: Router,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularInvoice(this.paramId);
    })
  }



  ngOnInit(): void {
    //this.getParticularInvoice();
  }

  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
            console.log(this.particlarLR.clientId[0])
          this.clientList = rawdata;
          this.clientName = this.clientList.filter((item)=>item._id==this.particlarLR.clientId)[0]['clientName'];
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login'); 
          }else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });

  }



  initform() {
    this.editLR.patchValue({
      // Basic Details

      clientList: this.particlarLR.clientName,
      LR_no: this.particlarLR.LR_no,
      //date: this.particlarLR.date,
      ConsigneeName: this.particlarLR.consignee_name,
      ConsigneeAddress: this.particlarLR.consignee_address,
      ConsignorName: this.particlarLR.consignor_name,
      ConsignorAddress: this.particlarLR.consignor_address,
      VehicleNo: this.particlarLR.vehicle_id,
      vehicleType: this.particlarLR.vehicleType,
      //VehicleNo: this.particlarLR.vehicle_no,
      from: this.particlarLR.from,
      to: this.particlarLR.to,
      GSTIN: this.particlarLR.GSTIN,
      date: new Date(this.particlarLR.date).toISOString().split('T')[0],
      RoadPermitNo: this.particlarLR.road_permit_no,
      StateCode: this.particlarLR.stateCode,
      DLY: this.particlarLR.dly_no,
      DeclaredValue: this.particlarLR.declared_value,
      fixAdhoc: this.particlarLR.fixAdhoc,
      quantity: this.particlarLR.quantity,
      transportorName: this.particlarLR.transportorName,
      transportorMobile: this.particlarLR.transportorMobile,
      driverName: this.particlarLR.driverName,
      driverMobile: this.particlarLR.driverMobile,
      Frieght: this.particlarLR.Frieght,

      // Information For office use only

      startmeterReading: this.particlarLR.startmeterReading,
      endmeterReading: this.particlarLR.endmeterReading,
      tripSDate: new Date(this.particlarLR.tripStartDate).toISOString().split('T')[0],
      tripEDate: new Date(this.particlarLR.tripEndDate).toISOString().split('T')[0],
      INtime: this.particlarLR.in_time,
      outtime: this.particlarLR.out_time,
      noofTools: this.particlarLR.noofTools,
      tooltaxPaid: this.particlarLR.tooltaxPaid,
      labourPaid: this.particlarLR.labourPaid,
      otherPayment: this.particlarLR.otherPayment,
      updownkm: this.particlarLR.up_down_km,
      totalhour: this.particlarLR.totalhour,
      extrahour: this.particlarLR.extrahour,
      detention: this.particlarLR.detention,
      advance: this.particlarLR.advance,
      balance: this.particlarLR.balance,
      balancePaid: this.particlarLR.balancePaid,
      vehicleRate: this.particlarLR.vehicleRate,


    })
  }

  getLRDetail(id: string) {
    this.superAdminService.particlarLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.particlarLR = res.data[0].receipts[0];
        this.vehicles = res.data[0].vehiclesDetail;
        this.getClientList();
        this.superAdminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
          if(supervisors.status == true){
            var supervisorsData = supervisors.data;
            this.particlarLR.supervisorId = supervisorsData.filter((item)=> this.particlarLR.supervisorId==item._id);
            this.particlarLR.supervisorId = this.particlarLR.supervisorId[0]?this.particlarLR.supervisorId[0]['full_name']:this.particlarLR.supervisorId[0]
            console.log(this.particlarLR.supervisorId);
          }else{
            console.log(res.message);
          }
        }, error => {
          console.log('error', error);
        })
        this.initform();
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });

  }
  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
    .subscribe((res: { status: any, message: any, data: any }) => {

      if (res.status == true) {
        var rawdata = res.data;
        this.checkInvoiceType = rawdata[0].invoiceType;
        console.log("testin====", this.checkInvoiceType)
        this.users = rawdata[0].invoice;
        console.log("testin---",this.users)

        this.users.forEach( (value:any)=> {
          let balancepaidArray = value.vehicleRate;
          this.invtot =  balancepaidArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)

          let up_down_kmArray = value.up_down_km;
          this.kmtot =  up_down_kmArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)

          let extrahourArray = value.extrahour;
          this.extrahour =  extrahourArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)

           let tollArray = value.tooltaxPaid;
           this.tooltaxPaidtot =  tollArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)
        }); 
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });
  }



  sendInvoice(divName) {
    var data = document.getElementById(divName);
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png', 0.3)
      let pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
      let binary = pdf.output();
      binary = binary ? btoa(binary) : '';
      this.superAdminService.sendInvoice(this.paramId, binary).subscribe((res: any) => {
        if (res.status == true) {
          this.toastr.success(res.message);
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }
      }, error => {
        console.log('error:');
        console.log(error);
      });
    });

  }
  backTo() {
    window.history.back()
  }

}
