import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-view-driver-cash',
  templateUrl: './view-driver-cash.component.html',
  styleUrls: ['./view-driver-cash.component.css']
})
export class ViewDriverCashComponent implements OnInit {

  paramID:any;
  particularDriverExpense:any;

  constructor(public activatedRoute: ActivatedRoute, public superAdminServices: SuperAdminService, public toaster: ToastrService){
    this.activatedRoute.params.subscribe(param => {
      this.paramID = param.id
      this.getParticularDriverCash(this.paramID)
    })

  }

  ngOnInit(): void {

      
  }

  getParticularDriverCash(id: any) {
    this.superAdminServices.getParticularDriverCash(id).subscribe((res: any) => {
      if (res.status == true) {
        this.particularDriverExpense = res.data[0]
        // console.log(this.particularDriverExpense)
   
      }else{
        if(res.status == 401){
          this.toaster.error(res.message)
        }else{
          this.toaster.error(res.message)
        }
      }
    })
  }
  backTo() {
    window.history.back()
  }

}
