<div class="row account">
    <form [formGroup]="editInvoiceForm">
        <div class="row account">
            <div class="col-md-12 border-bottom pb-3 border-gray mb-3">
                <div class="d-flex align-items-center justify-content-between">
                    <h4 class="text-dark fw-bold mb-0 ">Update Invoice Details</h4>
                    <button class="btn shadow-none" mat-dialog-close><i class="fa-solid fa-xmark"></i></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                <div class="form-group accountsinp  mb-3">
                    <label for="">Invoice No</label>
                    <div class="d-flex align-items-center">
                        <input *ngIf="isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Invoice No" id="InvoiceNoctrl" formControlName="InvoiceNo">
                        <input disabled *ngIf="!isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Invoice No" id="InvoiceNoctrl" formControlName="InvoiceNo">

                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                <div class="form-group accountsinp  mb-3">
                    <label for="">Invoice Value</label>
                    <div class="d-flex align-items-center">

                        <input *ngIf="isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Invoice Value" id="InvoiceValuectrl" formControlName="InvoiceValue">
                        <input disabled *ngIf="!isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Invoice Value" id="InvoiceValuectrl" formControlName="InvoiceValue">
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                <div class="form-group accountsinp  mb-3">
                    <label for="">Number of Articles</label>
                    <div class="d-flex align-items-center">

                        <input *ngIf="isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter No. of Article" id="NoofArticlectrl" formControlName="NoofArticle">
                        <input disabled *ngIf="!isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter No. of Article" id="NoofArticlectrl" formControlName="NoofArticle">

                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                <div class="form-group accountsinp  mb-3">
                    <label for="">Weight</label>
                    <div class="d-flex align-items-center">

                        <input *ngIf="isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Weight Value" id="Weightctrl" formControlName="Weight">
                        <input disabled *ngIf="!isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Weight Value" id="Weightctrl" formControlName="Weight">

                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">Freight</label>
                        <div class="d-flex align-items-center">
                            <input type="text" class="form-control me-1 shadow-none" placeholder="Enter Freight Value" id="Frieghtctrl" formControlName="Frieght">
                            
                        </div>
                    </div>
                </div> -->
            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                <div class="form-group accountsinp  mb-3">
                    <label for=""> Customer Name </label>
                    <div class="d-flex align-items-center">

                        <input *ngIf="isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Customer Name" id="customerNamectrl" formControlName="customerName">
                        <input disabled *ngIf="!isRoleSuperVisor" type="text" class="form-control me-1 shadow-none"
                            placeholder="Enter Customer Name" id="customerNamectrl" formControlName="customerName">

                    </div>
                </div>
            </div>
           
            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                <div class="form-group accountsinp  mb-3">
                    <label for="">Invoice Attachment</label>
                    <div class="d-flex align-items-center justify-content-center">
                        <input type="file" accept="image/*,.pdf,.doc" (change)="fileChangeEventAttachment($event)"
                            class="form-control me-1 shadow-none" id="PODctrl" formControlName="POD">
                        <button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="preview()" mat-raised-button >Preview</button>
                        <a #showPreview [href]="previewURL" target="_blank"></a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-12 col-md-12 col-12 col-sm-12 text-center">
                <div class="form-group accountsinp  text-center mb-3">
                    <label for="">Invoice Attachment</label>
                    <div class="d-flex align-items-center justify-content-center">
                        <img src="{{apiUrl+'/'+this.data.acc?.path}}" width="100" height="100" class="mb-3">
                        <button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="preview()" mat-raised-button >Preview</button>
                        <a #showPreview [href]="previewURL" target="_blank"></a>
                    </div>
                </div>
            </div> -->
            <div class="col-xl-12 col-md-12 col-12 col-sm-12 text-center">
                <button mat-raised-button [mat-dialog-close]="true" class="btn btn-outline-dark me-1">Cancel</button>
                <button mat-raised-button (click)="updateInvoice()" class="btn btn-dark ms-1" type="submit">Update</button>
            </div>
        </div>
    </form>
</div>