import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-hire-vehicle-flat',
  templateUrl: './hire-vehicle-flat.component.html',
  styleUrls: ['./hire-vehicle-flat.component.css']
})
export class HireVehicleFlatComponent implements OnInit {
  filesToUpload: Array<File> = [];
  superVisor;
  company;
  vehicletypes;
  addHireVehicleFlatForm = new FormGroup({
    supervisorname: new FormControl('', [Validators.required]),
    expenseHead: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    weight: new FormControl('', [Validators.required]),
    expenseDate: new FormControl('', [Validators.required]),
    licenseNumber: new FormControl('', [Validators.required]),
    totalExpenseAmount: new FormControl('', [Validators.required]),
    expenseDescription: new FormControl('', [Validators.required]),
    attachReceipt1: new FormControl('', [Validators.required]),
    attachReceipt2: new FormControl('', [Validators.required]),
    attachReceipt3: new FormControl('', [Validators.required]),

    detention: new FormControl('', [Validators.required]),
    tollTax: new FormControl('', [Validators.required]),
    labourCharge: new FormControl('', [Validators.required]),
    otherCharges: new FormControl('', [Validators.required]),
    KM: new FormControl('', [Validators.required]),
    balancetobePaid: new FormControl('', [Validators.required]),
    LRNumber: new FormControl('', [Validators.required]),
    vehicleNumber: new FormControl('', [Validators.required]),
    source: new FormControl('', [Validators.required]),
    destination: new FormControl('', [Validators.required]),

    rate: new FormControl('', [Validators.required]),
    LRReceipt1: new FormControl('', [Validators.required]),
    LRReceipt2: new FormControl('', [Validators.required]),
    LRReceipt3: new FormControl('', [Validators.required]),
    cashAdvancePaid: new FormControl('', [Validators.required]),
    brokerName: new FormControl('', [Validators.required]),
    brokerNumber: new FormControl('', [Validators.required]),
    expenseCategory: new FormControl('', [Validators.required]),
    companyname: new FormControl('', [Validators.required]),
    vehicleType: new FormControl('', [Validators.required]),
    optradiopod: new FormControl('', [Validators.required]),



  })
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService) {
  }
  ngOnInit(): void {
    this.getCompanyName();
    this.getSuperVisorName();
    this.getVehicleType();

  }

  getSuperVisorName() {
    this.superAdminService.getSuperVisorName()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.superVisor = rawdata;
          console.log(this.superVisor);
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
  getCompanyName() {
    this.superAdminService.getCompanyName()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.company = rawdata;
          console.log(this.company);
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }
  getVehicleType() {
    this.superAdminService.getVehicleType()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          //console.log(this.company);
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }
  fileChangeEventattachReceipt1(fileInput: any) {

    // this.filesToUpload.push(fileInput.target.files[0]);
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  fileChangeEventattachReceipt2(fileInput: any) {
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  fileChangeEventattachReceipt3(fileInput: any) {
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  fileChangeEventLRReceipt1(fileInput: any) {
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  fileChangeEventLRReceipt2(fileInput: any) {
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  fileChangeEventLRReceipt3(fileInput: any) {
    this.filesToUpload.push(fileInput.target.files[0]);
  }

  addHireVehicleFlat() {
    console.log(this.addHireVehicleFlatForm.valid)
    if (this.addHireVehicleFlatForm.valid) {
      this.removevalidatorcss("supervisornamectrl");
      this.removevalidatorcss("expenseHeadctrl");
      this.removevalidatorcss("quantityctrl");
      this.removevalidatorcss("weightctrl");
      this.removevalidatorcss("expenseDatectrl");
      this.removevalidatorcss("licenseNumberctrl");
      this.removevalidatorcss("totalExpenseAmountctrl");
      this.removevalidatorcss("expenseDescriptionctrl");
      this.removevalidatorcss("attachReceipt1ctrl");
      this.removevalidatorcss("attachReceipt2ctrl");
      this.removevalidatorcss("attachReceipt3ctrl");

      this.removevalidatorcss("detentionctrl");
      this.removevalidatorcss("tollTaxctrl");
      this.removevalidatorcss("labourChargectrl");
      this.removevalidatorcss("otherChargesctrl");
      this.removevalidatorcss("KMctrl");
      this.removevalidatorcss("balancetobePaidctrl");
      this.removevalidatorcss("LRNumberctrl");
      this.removevalidatorcss("vehicleNumberctrl");
      this.removevalidatorcss("sourcectrl");
      this.removevalidatorcss("destinationctrl");

      this.removevalidatorcss("ratectrl");
      this.removevalidatorcss("LRReceipt1ctrl");
      this.removevalidatorcss("LRReceipt2ctrl");
      this.removevalidatorcss("LRReceipt3ctrl");
      this.removevalidatorcss("cashAdvancePaidctrl");
      this.removevalidatorcss("brokerNamectrl");
      this.removevalidatorcss("brokerNumberctrl");
      this.removevalidatorcss("expenseCategoryctrl");
      this.removevalidatorcss("companynamectrl");
      this.removevalidatorcss("vehicleTypectrl");

      var form = {

        "supervisorname": this.addHireVehicleFlatForm.get('supervisorname').value,
        "expenseHead": this.addHireVehicleFlatForm.get('expenseHead').value,
        "quantity": this.addHireVehicleFlatForm.get('quantity').value,
        "weight": this.addHireVehicleFlatForm.get('weight').value,
        "expenseDate": this.addHireVehicleFlatForm.get('expenseDate').value,
        "licenseNumber": this.addHireVehicleFlatForm.get('licenseNumber').value,
        "totalExpenseAmount": this.addHireVehicleFlatForm.get('totalExpenseAmount').value,
        "expenseDescription": this.addHireVehicleFlatForm.get('expenseDescription').value,
        "attachReceipt1": this.addHireVehicleFlatForm.get('attachReceipt1').value,
        "attachReceipt2": this.addHireVehicleFlatForm.get('attachReceipt2').value,
        "attachReceipt3": this.addHireVehicleFlatForm.get('attachReceipt3').value,

        "detention": this.addHireVehicleFlatForm.get('detention').value,
        "tollTax": this.addHireVehicleFlatForm.get('tollTax').value,
        "labourCharge": this.addHireVehicleFlatForm.get('labourCharge').value,
        "otherCharges": this.addHireVehicleFlatForm.get('otherCharges').value,
        "KM": this.addHireVehicleFlatForm.get('KM').value,
        "balancetobePaid": this.addHireVehicleFlatForm.get('balancetobePaid').value,
        "LRNumber": this.addHireVehicleFlatForm.get('LRNumber').value,
        "vehicleNumber": this.addHireVehicleFlatForm.get('vehicleNumber').value,
        "source": this.addHireVehicleFlatForm.get('source').value,
        "destination": this.addHireVehicleFlatForm.get('destination').value,

        "rate": this.addHireVehicleFlatForm.get('rate').value,
        "LRReceipt1": this.addHireVehicleFlatForm.get('LRReceipt1').value,
        "LRReceipt2": this.addHireVehicleFlatForm.get('LRReceipt2').value,
        "LRReceipt3": this.addHireVehicleFlatForm.get('LRReceipt3').value,
        "cashAdvancePaid": this.addHireVehicleFlatForm.get('cashAdvancePaid').value,
        "brokerName": this.addHireVehicleFlatForm.get('brokerName').value,
        "brokerNumber": this.addHireVehicleFlatForm.get('brokerNumber').value,
        "expenseCategory": this.addHireVehicleFlatForm.get('expenseCategory').value,
        "companyname": this.addHireVehicleFlatForm.get('companyname').value,
        "vehicleType": this.addHireVehicleFlatForm.get('vehicleType').value,
        "optradiopod": this.addHireVehicleFlatForm.get('optradiopod').value,


      }
      console.log(form);
      const formData = new FormData();
      const files: Array<File> = this.filesToUpload;
      console.log("Files ", this.filesToUpload);

      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append("uploads[]", this.filesToUpload[i]);
      }
      formData.append('myFormData', JSON.stringify(form));
      //console.log("F",formData);
      this.superAdminService.addHireVehicleFlat(formData)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(['/superAdmin/manageExpenseLog']);
            this.addHireVehicleFlatForm.reset()
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');
              
            }else {
              this.toastr.error(res.message);
            }
            //this.addNewUser.reset()
          }

        }, error => {
        });
    }
    else {
      if (this.addHireVehicleFlatForm.get('supervisorname').invalid) {
        this.addvalidatorcss("supervisornamectrl");
      } else {
        this.removevalidatorcss("supervisornamectrl");
      }

      if (this.addHireVehicleFlatForm.get('expenseCategory').invalid) {
        this.addvalidatorcss("expenseCategoryctrl");
      } else {
        this.removevalidatorcss("expenseCategoryctrl");
      }
      if (this.addHireVehicleFlatForm.get('companyname').invalid) {
        this.addvalidatorcss("companynamectrl");
      } else {
        this.removevalidatorcss("companynamectrl");
      }
      if (this.addHireVehicleFlatForm.get('vehicleType').invalid) {
        this.addvalidatorcss("vehicleTypectrl");
      } else {
        this.removevalidatorcss("vehicleTypectrl");
      }
      if (this.addHireVehicleFlatForm.get('expenseHead').invalid) {
        this.addvalidatorcss("expenseHeadctrl");
      } else {
        this.removevalidatorcss("expenseHeadctrl");
      }

      if (this.addHireVehicleFlatForm.get('quantity').invalid) {
        this.addvalidatorcss("quantityctrl");
      } else {
        this.removevalidatorcss("quantityctrl");
      }
      if (this.addHireVehicleFlatForm.get('weight').invalid) {
        this.addvalidatorcss("weightctrl");
      } else {
        this.removevalidatorcss("weightctrl");
      }

      if (this.addHireVehicleFlatForm.get('expenseDate').invalid) {
        this.addvalidatorcss("expenseDatectrl");
      } else {
        this.removevalidatorcss("expenseDatectrl");
      }
      if (this.addHireVehicleFlatForm.get('licenseNumber').invalid) {
        this.addvalidatorcss("licenseNumberctrl");
      } else {
        this.removevalidatorcss("licenseNumberctrl");
      }
      if (this.addHireVehicleFlatForm.get('totalExpenseAmount').invalid) {
        this.addvalidatorcss("totalExpenseAmountctrl");
      } else {
        this.removevalidatorcss("totalExpenseAmountctrl");
      }
      if (this.addHireVehicleFlatForm.get('expenseDescription').invalid) {
        this.addvalidatorcss("expenseDescriptionctrl");
      } else {
        this.removevalidatorcss("expenseDescriptionctrl");
      }


      if (this.addHireVehicleFlatForm.get('detention').invalid) {
        this.addvalidatorcss("detentionctrl");
      } else {
        this.removevalidatorcss("detentionctrl");
      }
      if (this.addHireVehicleFlatForm.get('tollTax').invalid) {
        this.addvalidatorcss("tollTaxctrl");
      } else {
        this.removevalidatorcss("tollTaxctrl");
      }
      if (this.addHireVehicleFlatForm.get('labourCharge').invalid) {
        this.addvalidatorcss("labourChargectrl");
      } else {
        this.removevalidatorcss("labourChargectrl");
      }
      if (this.addHireVehicleFlatForm.get('otherCharges').invalid) {
        this.addvalidatorcss("otherChargesctrl");
      } else {
        this.removevalidatorcss("otherChargesctrl");
      }
      if (this.addHireVehicleFlatForm.get('KM').invalid) {
        this.addvalidatorcss("KMctrl");
      } else {
        this.removevalidatorcss("KMctrl");
      }
      if (this.addHireVehicleFlatForm.get('balancetobePaid').invalid) {
        this.addvalidatorcss("balancetobePaidctrl");
      } else {
        this.removevalidatorcss("balancetobePaidctrl");
      }
      if (this.addHireVehicleFlatForm.get('LRNumber').invalid) {
        this.addvalidatorcss("LRNumberctrl");
      } else {
        this.removevalidatorcss("LRNumberctrl");
      }
      if (this.addHireVehicleFlatForm.get('vehicleNumber').invalid) {
        this.addvalidatorcss("vehicleNumberctrl");
      } else {
        this.removevalidatorcss("vehicleNumberctrl");
      }
      if (this.addHireVehicleFlatForm.get('source').invalid) {
        this.addvalidatorcss("sourcectrl");
      } else {
        this.removevalidatorcss("sourcectrl");
      }
      if (this.addHireVehicleFlatForm.get('destination').invalid) {
        this.addvalidatorcss("destinationctrl");
      } else {
        this.removevalidatorcss("destinationctrl");
      }
      if (this.addHireVehicleFlatForm.get('cashAdvancePaid').invalid) {
        this.addvalidatorcss("cashAdvancePaidctrl");
      } else {
        this.removevalidatorcss("cashAdvancePaidctrl");
      }
      if (this.addHireVehicleFlatForm.get('brokerName').invalid) {
        this.addvalidatorcss("brokerNamectrl");
      } else {
        this.removevalidatorcss("brokerNamectrl");
      }
      if (this.addHireVehicleFlatForm.get('brokerNumber').invalid) {
        this.addvalidatorcss("brokerNumberctrl");
      } else {
        this.removevalidatorcss("brokerNumberctrl");
      }
      if (this.addHireVehicleFlatForm.get('rate').invalid) {
        this.addvalidatorcss("ratectrl");
      } else {
        this.removevalidatorcss("ratectrl");
      }
      if (this.addHireVehicleFlatForm.get('attachReceipt1').invalid) {
        this.addvalidatorcss("attachReceipt1ctrl");
      } else {
        this.removevalidatorcss("attachReceipt1ctrl");
      }
      if (this.addHireVehicleFlatForm.get('attachReceipt2').invalid) {
        this.addvalidatorcss("attachReceipt2ctrl");
      } else {
        this.removevalidatorcss("attachReceipt2ctrl");
      }
      if (this.addHireVehicleFlatForm.get('attachReceipt3').invalid) {
        this.addvalidatorcss("attachReceipt3ctrl");
      } else {
        this.removevalidatorcss("attachReceipt3ctrl");
      }
      if (this.addHireVehicleFlatForm.get('LRReceipt1').invalid) {
        this.addvalidatorcss("LRReceipt1ctrl");
      } else {
        this.removevalidatorcss("LRReceipt1ctrl");
      }
      if (this.addHireVehicleFlatForm.get('LRReceipt2').invalid) {
        this.addvalidatorcss("LRReceipt2ctrl");
      } else {
        this.removevalidatorcss("LRReceipt2ctrl");
      }
      if (this.addHireVehicleFlatForm.get('LRReceipt3').invalid) {
        this.addvalidatorcss("LRReceipt3ctrl");
      } else {
        this.removevalidatorcss("LRReceipt3ctrl");
      }



    }
  }
  resetForm() {
    this.addHireVehicleFlatForm.reset();
    var a = this.addHireVehicleFlatForm.get('status').value;
    console.log(a);
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }
  backTo() {
    window.history.back()
  }
  
}
