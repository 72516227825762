import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-hire-vehicle-monthly',
  templateUrl: './hire-vehicle-monthly.component.html',
  styleUrls: ['./hire-vehicle-monthly.component.css']
})
export class HireVehicleMonthlyComponent implements OnInit {
  filesToUpload: Array<File> = [];
  superVisor;
  company;
  vehicletypes;
  addHireVehicleMonthlyForm = new FormGroup({
    supervisorname: new FormControl('', [Validators.required]),
    expenseCategory: new FormControl('', [Validators.required]),
    companyname: new FormControl('', [Validators.required]),
    vehicleType: new FormControl('', [Validators.required]),
    vehicleNumber: new FormControl('', [Validators.required]),
    diesel: new FormControl('', [Validators.required]),
    KM: new FormControl('', [Validators.required]),
    expenseHead: new FormControl('', [Validators.required]),
    detention: new FormControl('', [Validators.required]),
    
    police: new FormControl('', [Validators.required]),
    tollTax: new FormControl('', [Validators.required]),
    labourAmount: new FormControl('', [Validators.required]),
    rate: new FormControl('', [Validators.required]),
    expenseAmount: new FormControl('', [Validators.required]),
    expenseDescription: new FormControl('', [Validators.required]),
    LRReceipt1: new FormControl('', [Validators.required]),
      
    
  })
  constructor(private activatedRoute: ActivatedRoute,private router :Router, private toastr: ToastrService, private superAdminService: SuperAdminService) {
   }
   ngOnInit(): void {
    this.getCompanyName();
    this.getSuperVisorName();
    this.getVehicleType();

  }
  getSuperVisorName(){
    this.superAdminService.getSuperVisorName()
    .subscribe((res: { status: any, message: any, data: any }) => {

      if (res.status == true) {
        var rawdata = res.data;
        this.superVisor = rawdata;
        console.log(this.superVisor);
        //this.dtOptions.data = this.users;
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
      }

    }, error => {
    });
  }
  getCompanyName(){
      this.superAdminService.getCompanyName()
        .subscribe((res: { status: any, message: any, data: any }) => {
  
          if (res.status == true) {
            var rawdata = res.data;
            this.company = rawdata;
            console.log(this.company);
            //this.dtOptions.data = this.users;
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');
              
            }else {
              this.toastr.error(res.message);
            }
          }
  
        }, error => {
        });
    
  }
  getVehicleType() {
    this.superAdminService.getVehicleType()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          //console.log(this.company);
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }
  fileChangeEventattachReceipt1(fileInput: any) {

    // this.filesToUpload.push(fileInput.target.files[0]);
     this.filesToUpload.push(fileInput.target.files[0] );
   }
  addHireVehicleMonthly() {
    console.log(this.addHireVehicleMonthlyForm.valid)
    if (this.addHireVehicleMonthlyForm.valid) {
      this.removevalidatorcss("supervisornamectrl");
      this.removevalidatorcss("expenseCategoryctrl");
      this.removevalidatorcss("companynamectrl");
      this.removevalidatorcss("vehicleTypectrl");
      this.removevalidatorcss("vehicleNumberctrl");
      this.removevalidatorcss("dieselctrl");
      this.removevalidatorcss("KMctrl");
      this.removevalidatorcss("expenseHeadctrl");
      this.removevalidatorcss("detentionctrl");
      this.removevalidatorcss("policectrl");
      this.removevalidatorcss("tollTaxctrl");
      this.removevalidatorcss("labourAmountctrl");
      this.removevalidatorcss("ratectrl");
      this.removevalidatorcss("expenseAmountctrl");
      this.removevalidatorcss("expenseDescriptionctrl");

      
      
      var form = {
       
        "supervisorname": this.addHireVehicleMonthlyForm.get('supervisorname').value,
        "expenseCategory": this.addHireVehicleMonthlyForm.get('expenseCategory').value,
        "companyname": this.addHireVehicleMonthlyForm.get('companyname').value,
        "vehicleType": this.addHireVehicleMonthlyForm.get('vehicleType').value,
        "vehicleNumber": this.addHireVehicleMonthlyForm.get('vehicleNumber').value,
        "diesel": this.addHireVehicleMonthlyForm.get('diesel').value,
        "KM": this.addHireVehicleMonthlyForm.get('KM').value,
        "expenseHead": this.addHireVehicleMonthlyForm.get('expenseHead').value,
        "detention": this.addHireVehicleMonthlyForm.get('detention').value,
        "police": this.addHireVehicleMonthlyForm.get('police').value,
        "tollTax": this.addHireVehicleMonthlyForm.get('tollTax').value,
        "rate": this.addHireVehicleMonthlyForm.get('rate').value,
        "expenseAmount": this.addHireVehicleMonthlyForm.get('expenseAmount').value,
        "expenseDescription": this.addHireVehicleMonthlyForm.get('expenseDescription').value,
        
      }
      console.log(form);
      const formData = new FormData();
       const files: Array<File> = this.filesToUpload;
       //console.log("Files ", this.filesToUpload);
      
       for (let i = 0; i < this.filesToUpload.length; i++) {
         formData.append("uploads[]", this.filesToUpload[i]);
       }
      formData.append('myFormData', JSON.stringify(form));
     
    //console.log("F",formData);
    this.superAdminService.addHireVehicleMonthly(formData)
    .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
    
        if (res.status == true) {
          //alert(res.message)
          this.toastr.success(res.message);
          //this.router.navigate(['/superAdmin/manageDriver']);
          this.addHireVehicleMonthlyForm.reset()
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
          //this.addNewUser.reset()
        }
      
    }, error => {
    });
    }
    else {
      if (this.addHireVehicleMonthlyForm.get('supervisorname').invalid) {
        this.addvalidatorcss("supervisornamectrl");
      } else {
        this.removevalidatorcss("supervisornamectrl");
      }
    
      if (this.addHireVehicleMonthlyForm.get('expenseCategory').invalid) {
        this.addvalidatorcss("expenseCategoryctrl");
      } else {
        this.removevalidatorcss("expenseCategoryctrl");
      }
      if (this.addHireVehicleMonthlyForm.get('companyname').invalid) {
        this.addvalidatorcss("companynamectrl");
      } else {
        this.removevalidatorcss("companynamectrl");
      }
      if (this.addHireVehicleMonthlyForm.get('vehicleType').invalid) {
        this.addvalidatorcss("vehicleTypectrl");
      } else {
        this.removevalidatorcss("vehicleTypectrl");
      }
      if (this.addHireVehicleMonthlyForm.get('vehicleNumber').invalid) {
        this.addvalidatorcss("vehicleNumberctrl");
      } else {
        this.removevalidatorcss("vehicleNumberctrl");
      }
      
      if (this.addHireVehicleMonthlyForm.get('diesel').invalid) {
        this.addvalidatorcss("dieselctrl");
      } else {
        this.removevalidatorcss("dieselctrl");
      } 
      if (this.addHireVehicleMonthlyForm.get('KM').invalid) {
        this.addvalidatorcss("KMctrl");
      } else {
        this.removevalidatorcss("KMctrl");
      }
      
      if (this.addHireVehicleMonthlyForm.get('expenseHead').invalid) {
        this.addvalidatorcss("expenseHeadctrl");
      } else {
        this.removevalidatorcss("expenseHeadctrl");
      }
      if (this.addHireVehicleMonthlyForm.get('detention').invalid) {
        this.addvalidatorcss("detentionctrl");
      } else {
        this.removevalidatorcss("detentionctrl");
      }
      if (this.addHireVehicleMonthlyForm.get('expenseAmount').invalid) {
        this.addvalidatorcss("expenseAmountctrl");
      } else {
        this.removevalidatorcss("expenseAmountctrl");
      }
      if (this.addHireVehicleMonthlyForm.get('LRReceipt1').invalid) {
        this.addvalidatorcss("LRReceipt1ctrl");
      } else {
        this.removevalidatorcss("LRReceipt1ctrl");
      }
      
    }
  }
  resetForm() {
    this.addHireVehicleMonthlyForm.reset();
  }
  backTo(){
    window.history.back()
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }

}
