import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-date-wise-dispense',
  templateUrl: './date-wise-dispense.component.html',
  styleUrls: ['./date-wise-dispense.component.css']
})
export class DateWiseDispenseComponent implements OnInit {

  dispenseArray:any=[];
  dispenseArrayList:any=[];
  month:any;
  myArr:any =[]
  paramId:any;
  paramCompany:any;
  paramSupervisor:any;
  userSessionData:any=JSON.parse(sessionStorage.getItem('userSession'));
  monthArrFull : any=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthArr : Array<String>=["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  year: any;
  constructor(private activatedRoute: ActivatedRoute,private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.paramCompany = param.company;
      this.paramSupervisor = param.supervisor;
      this.year = param.year;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
    })
   }

  ngOnInit(): void {
    this.getDispense();
  }
 getDispense() {
    this.superAdminService.getAllDispense()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.superAdminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                var rawdata = res.data;
                this.myArr = rawdata;
                this.myArr = this.myArr.filter((item) => {
                  return item.date.split('T')[0].split('-')[0] == this.year
                })
                if(this.userSessionData.user_role_id[0]!=1){
                  this.myArr = this.myArr.filter((item)=>{
                    return item.supervisorId==this.userSessionData._id
                  })
                }
                for(let i=0; i<this.myArr.length; i++){
                  if(this.monthArr[this.myArr[i].date.split('-')[1]-1]==this.paramId && this.myArr[i].companyId==this.paramCompany && this.myArr[i].supervisorId==this.paramSupervisor){
                    this.dispenseArray.push({"date":this.myArr[i].date.split('T')[0],"accountHolder":this.myArr[i].account_holder, "description":this.myArr[i].description, "amount":this.myArr[i].amount, "supervisorId":this.myArr[i].supervisorId, "companyId": this.myArr[i].companyId })
                  }
                }
                this.dispenseArray.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.dispenseArray[index].companyName = y.clientName
                  })
                });
                this.superAdminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
                  if(supervisors.status == true){
                    var supervisorsData = supervisors.data;
                    this.dispenseArray.forEach((item,i)=>{
                      this.dispenseArray[i].SupervisorName = supervisorsData.filter((item)=> this.dispenseArray[i].supervisorId==item._id);
                      this.dispenseArray[i].SupervisorName = this.dispenseArray[i].SupervisorName[0]?this.dispenseArray[i].SupervisorName[0]['full_name']:this.dispenseArray[i].SupervisorName[0]
                    })
                    this.dispenseArrayList = this.dispenseArray;
                  }else{
                    console.log(res.message);
                  }
                }, error => {
                  console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
          
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
  backTo(){
    window.history.back()
  }
}
