<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">GST Invoice</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LS</li>
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement/view-invoice">View Invoice
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">GST Invoice</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="text-center ">

                    <button mat-raised-button class="btn btn-outline-dark btn-sm px-4"  printSectionId="print-section" >Print</button>&nbsp;
                </div>
                <div class="gstInvoice table-responsive" id="print-section">
                    <div class="newInvoiceBox">
                        <table>
                            <tr>
                                <td colspan="2" class="text-center">
                                    <h1>GST Invoice</h1>
                                    
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    <table class="AddressBox">
                                        <tr>
                                            <td class="text-center borderbottom"> <h2>Details of Receiver (Billed to)</h2> </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><img src="../../../../assets/images/profilephoto.jpg" width="100" alt=""></td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <table class="AddCont">
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>: K LOGISTICS</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Address </td>
                                                        <td>: 1ST FLOOR, PLOT NO</td>
                                                    </tr>
                                                    <tr>
                                                        <td>State with State Code</td>
                                                        <td>: Uttar Pradesh ,09</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GSTIN Number </td>
                                                        <td>: 06BKAPP5757J1ZT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pan No </td>
                                                        <td>: BKAPP5757J</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HSN Code  </td>
                                                        <td>:</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HSN Desc </td>
                                                        <td>: Goods transport agency services for road transport </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Description </td>
                                                        <td>: </td>
                                                    </tr>
                                                    
                                                </table>
                                            </td>
                                        </tr>
                                        
                                    </table>
                                </td>
                                <td class="p-0">
                                    <table class="AddressBox">
                                        <tr>
                                            <td class="text-center borderbottom"> <h2>Details of consignee (Shipped to)</h2> </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><img src="../../../../assets/images/profilephoto.jpg" width="100" alt=""></td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <table class="AddCont">
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>: K LOGISTICS</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Address </td>
                                                        <td>: 1ST FLOOR, PLOT NO.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>State with State Code</td>
                                                        <td>: Uttar Pradesh ,09</td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td>Pan No </td>
                                                        <td>: NA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GSTIN Number </td>
                                                        <td>: NA</td>
                                                    </tr>
                                                   
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    
                                                </table>
                                            </td>
                                        </tr>
                                        
                                    </table>
                                </td>
                             
                            </tr>
                            <tr>
                                <td colspan="2" class="p-0">
                                    <table class="gstBox">
                                        <tr>
                                            <td>No.</td>
                                            <td>Invoice No</td>
                                            <td>TOTAL (A)</td>
                                            <td class="p-0">
                                                <div class="gridbox">
                                                    <div>CGST (B)</div>
                                                    <div>6%</div>
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <div class="gridbox">
                                                    <div>SGST (C)</div>
                                                    <div>6%</div>
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <div class="gridbox">
                                                    <div>IGST (D)</div>
                                                    <div>12%</div>
                                                </div>
                                            </td>
                                            <td>(A+B+C+D) TOTAL</td>
                                        </tr>
                                        <tr>
                                            <td>1. </td>
                                            <td>Amb/Gre/042019/1055</td>
                                            <td>{{this.invtot | number:'1.2-2'}}</td>
                                            <td>
                                                {{this.fivePercent | number:'1.2-2'}}
                                            </td>
                                            <td>
                                                {{this.fivePercent | number:'1.2-2'}}
                                            </td>
                                            <!-- <td>
                                                {{this.eightPercent | number:'1.2-2'}}
                                            </td> -->
                                            <td>
                                                {{"-"}}
                                            </td>
                                            <td>{{this.totalInvValueWithGST | number:'1.2-2'}} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6"><b>Total</b> </td>
                                           
                                            <td><b>{{this.totalInvValueWithGST | number:'1.2-2'}}</b> </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="mb-0 smallText">Note:</p>
                                    <p class="mb-0 smallText">1. For IGST: Transportation of goods by road is exempted by notification no 9/2017 hence no GST is applicable.</p>
                                </td>
                                <td class="text-center">
                                   
                                        <p class="smallText">K LOGISTICS</p>
                                        <p class="mb-0 smallText">Signature</p>
                                    
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>