import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EditLrComponent } from 'src/app/superAdmin/LR-management/edit-lr/edit-lr.component';
import { SuperAdminService } from 'src/app/super-admin.service';
import { environment } from 'src/environments/environment';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.css']
})
export class EditInvoiceComponent implements OnInit {
  filesToUpload: Array<File> = [];
  apiUrl= environment.domain;
  isRoleSuperVisor = true;
  userSessionData;
  userroleid;
  editInvoiceForm = new FormGroup({
    InvoiceNo: new FormControl('', [Validators.required]),
    InvoiceValue: new FormControl('', [Validators.required]),
    NoofArticle: new FormControl('', Validators.required),
    Weight: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d+[.]{1}\d+$|^\d+$/)])),
    // Frieght: new FormControl('', [Validators.required]),
    customerName: new FormControl('', [Validators.required]),
    POD: new FormControl(''),
  })
  previewURL: any;
  @ViewChild('showPreview') showPreview: ElementRef;
  constructor(private toastr: ToastrService, private superAdminService: SuperAdminService, public dialogRef: MatDialogRef<EditLrComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, public dialog: MatDialog,  private sanitizer:DomSanitizer) { }
  ngOnInit(): void {
    this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
    this.userroleid = this.userSessionData.user_role_id[0];
    if (this.userroleid === 2) {
      this.isRoleSuperVisor = false;
    }
    let freight =  this.data?.acc?.Frieght ? this.data?.acc?.Frieght : ''
    this.editInvoiceForm.patchValue({
      // Invoice Details
      InvoiceNo: this.data.acc.InvoiceNo,
      InvoiceValue: this.data.acc.InvoiceValue,
      NoofArticle: this.data.acc.NoofArticle,
      Weight: this.data.acc.Weight,
      // Frieght:freight,
      customerName: this.data.acc.customerName,
      POD: this.data.acc.path,
    })
  }
  fileChangeEventAttachment(fileInput: any) {
    this.filesToUpload=[];
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  updateInvoice() {
    if (this.editInvoiceForm.valid) {
      // Update Invoice Details
      this.removevalidatorcss("InvoiceNoctrl");
      this.removevalidatorcss("InvoiceValuectrl");
      this.removevalidatorcss("NoofArticlectrl");
      this.removevalidatorcss("Weightctrl");
      // this.removevalidatorcss("Frieghtctrl");
      this.removevalidatorcss("customerNamectrl");
      this.removevalidatorcss("PODctrl");

      var form = {

        // Update Invoice Details

        "InvoiceNo": this.editInvoiceForm.get('InvoiceNo').value,
        "InvoiceValue": this.editInvoiceForm.get('InvoiceValue').value,
        "NoofArticle": this.editInvoiceForm.get('NoofArticle').value,
        "Weight": this.editInvoiceForm.get('Weight').value,
        // "Frieght": this.editInvoiceForm.get('Frieght').value,
        "customerName": this.editInvoiceForm.get('customerName').value,
        "path": this.data.acc.path,
        "vId": this.data.acc.id,
        "_id": this.data.id,

      }
      const formData = new FormData();
      const files: Array<File> = this.filesToUpload;
     
      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append("uploads[]", this.filesToUpload[i]);
      }
     formData.append('myFormData', JSON.stringify(form));
      this.superAdminService.updateInvoice(formData)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            //this.editInvoiceForm.reset()
            this.data.getLrDetail();
            this.dialogRef.close();
          }
          else {
            // alert(res.message)
            this.toastr.error(res.message);
            //this.addNewLR.reset()
          }
        }, error => {
        });
    }
    else {
      // Basic Details 

      if (this.editInvoiceForm.get('InvoiceNo').invalid) {
        this.addvalidatorcss("InvoiceNoctrl");
      } else {
        this.removevalidatorcss("InvoiceNoctrl");
      }
      if (this.editInvoiceForm.get('InvoiceValue').invalid) {
        this.addvalidatorcss("InvoiceValuectrl");
      } else {
        this.removevalidatorcss("InvoiceValuectrl");
      }
      if (this.editInvoiceForm.get('NoofArticle').invalid) {
        this.addvalidatorcss("NoofArticlectrl");
      } else {
        this.removevalidatorcss("NoofArticlectrl");
      }
      if (this.editInvoiceForm.get('Weight').invalid) {
        this.addvalidatorcss("Weightctrl");
      } else {
        this.removevalidatorcss("Weightctrl");
      }
      // if (this.editInvoiceForm.get('Frieght').invalid) {
      //   this.addvalidatorcss("Frieghtctrl");
      // } else {
      //   this.removevalidatorcss("Frieghtctrl");
      // }
      if (this.editInvoiceForm.get('customerName').invalid) {
        this.addvalidatorcss("customerNamectrl");
      } else {
        this.removevalidatorcss("customerNamectrl");
      }
      if (this.editInvoiceForm.get('POD').invalid) {
        this.addvalidatorcss("PODctrl");
      } else {
        this.removevalidatorcss("PODctrl");
      }




    }

  }
  preview(){
    let file = this.filesToUpload[0];
    if(file){
      if(['image/jpeg','image/png','image/svg','image/svg+xml'].includes(file.type)){
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width:'80%',
          height:'90vh',
          data:{file:file}
      });
      }
      else{
        let url =URL.createObjectURL(file);
        this.previewURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(()=>{
          this.showPreview.nativeElement.click();
        })
      }
    }
    else{
      let url = this.data.acc.path;
      if(url){
        let fileExtension=url.split('.').slice(-1)[0];
        if(['jpeg','png','jpg','svg'].includes(fileExtension)){
          const dialogRef = this.dialog.open(ImagePreviewComponent, {
            width:'80%',
            height:'90vh',
            data:{file:url}
        });
        }
        else{
          this.previewURL = this.sanitizer.bypassSecurityTrustUrl(this.apiUrl+'/'+url);
          setTimeout(()=>{
            this.showPreview.nativeElement.click();
          })
        }
      }
      else{
        this.toastr.error("No attachment")
      }
    }
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }
}
