import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UsersListComponent } from './users-list/users-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ReactiveFormsModule } from '@angular/forms';
import { sharedModule } from 'src/app/shared/myshared.module';
import { DataTablesModule } from "angular-datatables";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViewComponent } from './view/view.component'

@NgModule({
  declarations: [UsersListComponent, AddUserComponent, EditUserComponent, ViewComponent],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    NgxChartsModule,
    ReactiveFormsModule,
    DataTablesModule,
    sharedModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers:[],
  exports:[
    UserManagementRoutingModule
  ]
})
export class UserManagementModule { }

