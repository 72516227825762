<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Category Expense Report : {{paramCategory}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>

    </div>
<div class="contentbox mt-3">

    <div class="row customepadding">
        <div class="col-md-12 table-responsive ">

            <table datatable class="row-border hover tablelist" *ngIf='categoryArray.length > 0'>
                <thead>
                    <tr>
                        <td>Month</td>
                        <td>Company Name</td>
                        <td>Supervisor Name</td>
                        <td>Category Name</td>
                        <td>Amount</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user1 of categoryArray">
                        <td [routerLink]="['../../../dateWiseCategory', user1.expenseSubCategoryId, user1.companyId, user1.supervisorId,user1.month, this.year]">{{user1.month}}</td>
                        <td [routerLink]="['../../../dateWiseCategory', user1.expenseSubCategoryId, user1.companyId, user1.supervisorId, user1.month, this.year]">{{user1.companyName}}</td>
                        <td [routerLink]="['../../../dateWiseCategory', user1.expenseSubCategoryId, user1.companyId, user1.supervisorId, user1.month, this.year]">{{user1.SupervisorName}}</td>
                        <td [routerLink]="['../../../dateWiseCategory', user1.expenseSubCategoryId, user1.companyId, user1.supervisorId, user1.month, this.year]">{{user1.expenseSubCategory}}</td>
                        <td [routerLink]="['../../../dateWiseCategory', user1.expenseSubCategoryId, user1.companyId, user1.supervisorId, user1.month, this.year]">{{user1.amount}}</td>
                        <!-- <td>{{user1.amount}}</td> -->
                        <td [routerLink]="['../../../dateWiseCategory', user1.expenseSubCategoryId, user1.companyId, user1.supervisorId, user1.month, this.year]">view</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>