<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Driver Cash</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" (click)="backTo()">Manage LS </li>
                    <li class="breadcrumb-item active" aria-current="page">View FDriver Cash</li>

                </ol>
            </nav>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
                <div class="contentbox">
                    <div class="row account">
                        <div class="col-xl-12">
                            <div class="detailsBox">
                                <div class="row">
                                    <div class="col-xl-3">
                                        <label for="">Amount Paid</label>
                                        <h5>{{this.particularDriverExpense.amount_paid}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Date of payment</label>
                                        <h5>{{this.particularDriverExpense.date_of_payment}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Driver Name</label>
                                        <h5>{{this.particularDriverExpense.driver_name}}</h5>
                                    </div>
                                    

                                    <div class="col-xl-6">
                                        <label for="">Payment Mode</label>
                                        <h5>{{this.particularDriverExpense.payment_mode}}</h5>
                                    </div>

                                
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</div>