import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SuperAdminService } from 'src/app/super-admin.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {
  
  EmailLr: any[] = []
  uniqueRes: any = [];
  users: any=[];
  kmtot: any;
  tooltaxPaidtot: any;
  extrahour: any;
  invoiceType: any;
  invtot: any;
  clientName: any = [];
  displayedColumns: any = ['sno', 'tripID', 'created_date', 'action'] 
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('printBtn') printBtn: ElementRef<HTMLElement>;
  @ViewChild(MatSort) sort: MatSort;
  extractLrNoFromActualData = [];
  TripID: any[] = [];
  actualData;
  clientList: any[] = [{}];
  receipt: any[];
  lrNumber: any[] = [];
  receipts: any[] = [];
  userSessionData;
  dropdownSettings
  dataSource:  MatTableDataSource<any>;
  generatedLrs: any = [];
  invoiceList: any = [{}];
  genLr: any[] = [];

  constructor(private router: Router, private superAdminService: SuperAdminService, private toastr: ToastrService,private spinner:NgxSpinnerService) { }

  filterform = new FormGroup({
    TripID: new FormControl('', [Validators.required]),
    LR_no: new FormControl('',[Validators.required] ),
    invoiceType: new FormControl('')
   
    

  });

  ngOnInit(): void {
        this.getLR();
        this.getClientList();
        this.getInvoiceTypes();
        this.TripID = [];
        this.filterform.controls['LR_no'].setValue(null)
  }

  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          
          var rawdata = res.data;
          this.clientList = rawdata;
          let userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
          let userroleid = userSessionData.user_role_id[0];
          if (userroleid != 1) {
            let clients = userSessionData.clientList;
            this.clientList = clients.map(email => {
            return this.clientList.filter(item=>item.clientEmail==email)[0]
          });
          }
          this.superAdminService.getInvoice().subscribe((invoices: { status: any, message: any, data: any }) => {
            
            if (invoices.status == true) {
              this.spinner.hide();
              let newrawdata = invoices.data;
              this.invoiceList = newrawdata;              
              // var tripID = this.invoiceList.filter((x:any) => x.TripID)
              this.dataSource = new MatTableDataSource(this.invoiceList);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;              
            }
            else {
              if (res.status == 401) {
                this.toastr.error(res.message);
                this.spinner.hide();
                window.sessionStorage.clear();
                this.router.navigateByUrl('superAuth/login');
      
              } else {
                this.toastr.error(res.message);
                this.spinner.hide();
              }
            }
          }
          );
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.spinner.hide();
            this.toastr.error(res.message);
          }
        }

      });

  }

  removeDuplicates(arr) {
    let unique = [];
    arr.forEach(element => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
    this.uniqueRes = unique
    
    return unique;
    
}
  

  getLR(){
    this.superAdminService.getlrs()
        .subscribe((res: { status: any, message: any, data: any }) => {
        
          
          if (res.status == true) {
            var rawdata = res.data;
            
           
            var receiptData = []
            for(let i = 0; i < rawdata.length; i++){
              receiptData.push(rawdata[i].receipt)
             
             
              
            }
            var dropTripID = []
            for(let i = 0 ; i < receiptData.length; i++){
              dropTripID.push(receiptData[i].TripID)
              
            }
            this.removeDuplicates(dropTripID)
            // console.log(this.uniqueRes)
            
            
            
            
            
            this.receipt = receiptData.filter((res:any) => res.TripID)
            this.receipt = this.uniqueRes
            
          
          }
          else {
            if (res.status == 401) {
              this.spinner.hide();
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');
              
            }else {
              this.toastr.error(res.message);
              this.spinner.hide();
            }
          }

        }, error => {
            this.toastr.error('Something went wrong! Please contact us');
            this.spinner.hide();
        });
        


  }

  
  backTo() {
    window.history.back()
  }
  onItemSelect($event) {
  }

  
  async onFocusGetLr(event: any) {
    // debugger;
    // let TripId = event.target.value
    // let TripIds = []

    // for(let i = 0; i < this.receipt.length; i++){
    //   debugger;
    //     TripIds.push(this.receipt[i].TripID)
    //     console.log(this.receipt[i].TripID)

    // }
    // console.log("hm tests",TripIds)

    // if(TripIds === TripId){
    //   console.log("done",this.receipt)


    let id = event.target.value
    this.superAdminService.getlrsbyTripId(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          console.log(rawdata);
          
          rawdata = rawdata.filter((item)=> item.TripID);
          this.EmailLr = []
          rawdata.map(x => {
            this.EmailLr.push(x.LR_no)
          })


          this.generatedLrs = []
          this.invoiceList.map(x => {
            x.invoice.map(y => {
              this.generatedLrs.push(y.LR_no)
            })
          })

          this.genLr = []

          for (let i = 0; i < this.generatedLrs.length; i++) {
            for (let j = 0; j < this.generatedLrs[i].length; j++) {
              this.genLr.push(this.generatedLrs[i][j])
            }
          }



          let temp = []

          for (let i = 0; i < this.EmailLr.length; i++) {
            var bool = false;
            for (let j = 0; j < this.genLr.length; j++) {
              if (this.EmailLr[i] == this.genLr[j]) {
                bool = true
              }
            }
            if (bool == false) {
              temp.push({ item_id: i, item_text: this.EmailLr[i] });
            }
          }
          this.lrNumber = temp;

          this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All'

          };

        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      });


    }
    
  
  getObjectListFromData(ids) {
    return this.lrNumber.filter(item => ids.includes(item.item_id))
  }

  removevalidatorcss(id: string) {
    // document.getElementById(id).classList.remove('validatorcss');
  }

  addvalidatorcss(id: string) {
    // document.getElementById(id).classList.add('validatorcss');
  }

  generateInvoice() {
    // console.log('Actual data ', this.getObjectListFromData(this.filterform.value.LR_no.map(item => item.item_id)));
    this.extractLrNoFromActualData =[];
    let data:any = this.filterform.value.LR_no
    this.actualData = this.getObjectListFromData(data.map((item:any) => item.item_id))
    this.actualData.map((x:any) => {
      this.extractLrNoFromActualData.push(x.item_text)
    })
    // this.extractLrNoFromActualData = 


    if (this.filterform.valid) {

      this.removevalidatorcss("clientNamectrl");
      this.removevalidatorcss("invoiceTypectrl");
      this.removevalidatorcss("LR_noctrl");
      var form = {
        "TripID": this.filterform.get("TripID").value,
        "invoiceType": this.filterform.get("invoiceType").value,
        // "LR_no": this.filterform.get("LR_no").value
        "LR_no": this.extractLrNoFromActualData
      }
      console.log("tripid form =====>",form)
      this.superAdminService.generateInvoixeUsingTripID({ form })
      
        .subscribe((res: { status: any, message: any, data: any }) => {
          const abc = res
          console.log(abc);
          
          if (res.status == true) {
            var rawdata = res.data;
            this.toastr.success(res.message);
            this.ngOnInit();
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
          }

        });
    } else {
      if (this.filterform.get('clientName').invalid) {
        this.addvalidatorcss("clientNamectrl");
      } else {
        this.removevalidatorcss("clientNamectrl");
      }
      if (this.filterform.get('invoiceType').invalid) {
        this.addvalidatorcss("invoiceTypectrl");
      } else {
        this.removevalidatorcss("invoiceTypectrl");
      }
      if (this.filterform.get('LR_no').invalid) {
        this.addvalidatorcss("LR_noctrl");
      } else {
        this.removevalidatorcss("LR_noctrl");
      }
    }
  }

  filterData(value){
    this.dataSource.filter = value;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'clientName':
          return compare(lower(a.clientName), lower(b.clientName), isAsc);
        case 'created_date':
          return compare(a.created_date, b.created_date, isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string , b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value){
      return value?value.toLowerCase():value;
    }
  }

  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;

          this.users.forEach((value: any) => {
            let balancepaidArray = value.balancePaid;
            this.invtot = balancepaidArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let up_down_kmArray = value.up_down_km;
            this.kmtot = up_down_kmArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let extrahourArray = value.extrahour;
            this.extrahour = extrahourArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let tollArray = value.tooltaxPaid;
            this.tooltaxPaidtot = tollArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)
            //console.log("balancePaid",this.invtot);
            setTimeout(()=>{
              let printBtn: HTMLElement = this.printBtn.nativeElement;
              printBtn.click();
            });
          });
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }

  sendInvoice(divName, Id) {
    this.superAdminService.getParticularInvoice(Id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;

          this.users.forEach((value: any) => {
            let balancepaidArray = value.balancePaid;
            this.invtot = balancepaidArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let up_down_kmArray = value.up_down_km;
            this.kmtot = up_down_kmArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let extrahourArray = value.extrahour;
            this.extrahour = extrahourArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let tollArray = value.tooltaxPaid;
            this.tooltaxPaidtot = tollArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

          });
          // send invoice begins
          setTimeout(()=>{
            var data = document.getElementById(divName);
            data.style.display = 'block';
            html2canvas(data).then(canvas => {
              var imgWidth = 208;
              var imgHeight = canvas.height * imgWidth / canvas.width;
              const contentDataURL = canvas.toDataURL('image/png', 0.3)
              let pdf = new jsPDF('p', 'mm', 'a4');
              pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
              let binary = pdf.output();
              data.style.display = 'none';
              binary = binary ? btoa(binary) : '';
              this.superAdminService.sendInvoice(Id, binary).subscribe((res: any) => {
                if (res.status == true) {
                  this.toastr.success(res.message);
                }
                else {
                  if (res.status == 401) {
                    this.toastr.error(res.message);
                    window.sessionStorage.clear();
                    this.router.navigateByUrl('superAuth/login');

                  } else {
                    this.toastr.error(res.message);
                  }
                }
              }, error => {
              });
            });
          })
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }

  deleteInvoice(_id) {
    if (this.superAdminService.deleteDailogBox()) {
      this.superAdminService.deleteInvoice(_id)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            this.toastr.success(res.message);
            // this.ngOnInit();
            this.ngOnInit()
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
          }

        }, error => {
        });
    }
  }

  getInvoiceTypes() {
    this.superAdminService.getInvoiceTypes()
      .subscribe((res: { status: any, message: any, data: any }) => {
        debugger;

        if (res.status == true) {
          var rawdata = res.data;
          console.log("rawdaat type", rawdata)
          this.invoiceType = rawdata;

          console.log(this.invoiceType)
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
          } else {
            this.toastr.error(res.message);
          }
        }

      });

  }

}
