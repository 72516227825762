import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { config } from "src/app/config";
import { SuperAdminService } from "src/app/super-admin.service";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { ImagePreviewComponent } from "../../LR-management/image-preview/image-preview.component";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-edit-vechile",
  templateUrl: "./edit-vechile.component.html",
  styleUrls: ["./edit-vechile.component.css"],
})
export class EditVechileComponent implements OnInit {
  Tollfiles: Array<File> = [];
  TollfilesString = [];  

  permitPreview:any
  rcPreview:any;
  insuracePreview:any;
  permit5yPreview:any;
  roadTexPreview:any;
  fitnessPreview:any;
  ownerpanPreview:any;
  owneradharPreview:any;
  cancelledChequePreview:any
  apiUrl = environment.domain;
  vehicletypes;
  vehicleTypesNames = [];
  DriverNames = [];
  driver;
  particlarVehicle;
  paramId;
  fileSource: string[] = [];
  slipURL: any;
  previewLoadingSlipURL: any;
  fileNameLoad: any;
  particlarLR: any;
  filteredVehicleTypes: Observable<string[]>;
  filteredDriverName: Observable<string[]>;
  @ViewChild("showSlip") showSlip: ElementRef;
  filesToUpload: Array<File> = [];
  fieldsUploaded: any = {
    permit: null,
    RCCopy: null,
    insurance: null,
    permitYears: null,
    roadTax: null,
    fitness: null,
    ownerPancard: null,
    ownerAadharCard: null,
    cancelledCheque: null,
  };
  editVehicle = new FormGroup({
    vehicleNumber: new FormControl("", [Validators.required]),
    vehicleType: new FormControl("", [Validators.required]),
    ownerName: new FormControl(""),
    driverName: new FormControl(""),
    makerName: new FormControl(""),
    chassisNumber: new FormControl(""),
    engineNumber: new FormControl(""),
    permitNumber: new FormControl(""),
    policyNumber: new FormControl(""),
    city: new FormControl(""),
    permitDueDate: new FormControl(""),
    permitYearsDueDate: new FormControl(""),
    purchaseDate: new FormControl(""),
    roadTaxDate: new FormControl(""),
    insuranceDueDate: new FormControl(""),
    fitnessDueDate: new FormControl(""),
    pollutionExpiryDate: new FormControl(""),
    permit: new FormControl(null),
    RCCopy: new FormControl(null),
    insurance: new FormControl(null),
    permitYears: new FormControl(null),
    roadTax: new FormControl(null),
    fitness: new FormControl(null),
    ownerPancard: new FormControl(null),
    ownerAadharCard: new FormControl(null),
    cancelledCheque: new FormControl(null),
  });
  get ff() {
    return this.editVehicle.controls;
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private superAdminService: SuperAdminService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    this.activatedRoute.params.subscribe((param) => {
      this.paramId = param.id;
      this.getparticlarVehicle(this.paramId);
    });
  }

  ngOnInit(): void {
    this.getDriverName();
    this.getVehicleType();
    this.jqueryCode()
  }

  jqueryCode(){
    $('.editImg').on('click', function(){
      $(this).parent().parent().addClass('d-none').removeClass('d-flex')
      $(this).parent().parent().prev().addClass('d-flex').removeClass('d-none')
    })
    $('.canceluplod').on('click', function(){
      $(this).parent().parent().next().addClass('d-flex').removeClass('d-none')
      $(this).parent().parent().addClass('d-none').removeClass('d-flex')
    })
    
  }
  getparticlarVehicle(id: string) {
    this.superAdminService.getparticlarVehicle(id).subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          this.particlarVehicle = res.data[0];
          console.log('particlarVehicle',this.particlarVehicle)
          // this.fileNameLoad = this.particlarVehicle;
          // console.log(this.fileNameLoad);
          this.initform();
          // if(this.particlarVehicle.permitDueDate == null ||this.particlarVehicle.permitYearsDueDate== null||this.particlarVehicle.purchaseDate == null || this.particlarVehicle.roadTaxDate ==null||this.particlarVehicle.insuranceDueDate==null||this.particlarVehicle.fitnessDueDate==null || this.particlarVehicle.pollutionExpiryDate == null){
          //     this.initforms()
          // }else{
          // this.initform();
          // }
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.error(res.message);
          }
        }
      },
      (error) => {}
    );
  }

  // addTollFiles() {
  //   const formData = new FormData();

  //   for (let i = 0; i < this.Tollfiles.length; i++) {
  //     formData.append("Tollfiles[]", this.Tollfiles[i]);
  //   }
  // }

  resetimg(image:any){
    switch (image) {
      case 'permit':
        this.permitPreview =''
        break;
        case 'rc':
          this.rcPreview =''
        break;
        case 'insurance':
          this.insuracePreview =''
        break;
        case 'permit5y':
          this.permit5yPreview =''
        break;
        case 'roadtax':
          this.roadTexPreview =''
        break;
        case 'fitness':
          this.fitnessPreview =''
        break;
        case 'ownerpan':
          this.ownerpanPreview =''
        break;
        case 'owneradhar':
          this.owneradharPreview =''
        break;
        case 'cancheck':
          this.cancelledChequePreview =''
        break;
      default:

    }
  }
  
  initform() {
    this.editVehicle.patchValue({
      // Basic Details
      vehicleNumber: this.particlarVehicle.vehicle_no,
      vehicleType: this.particlarVehicle.type,
      ownerName: this.particlarVehicle.ownerName,
      driverName: this.particlarVehicle.driverName,
      makerName: this.particlarVehicle.makerName,
      chassisNumber: this.particlarVehicle.chassisNumber,
      engineNumber: this.particlarVehicle.engineNumber,
      permitNumber: this.particlarVehicle.permitNumber,
      policyNumber: this.particlarVehicle.policyNumber,
      city: this.particlarVehicle.city,
      permitDueDate: this.particlarVehicle.permitDueDate ? new Date(this.particlarVehicle.permitDueDate).toISOString().split("T")[0] : '' ,
      permitYearsDueDate:this.particlarVehicle.permitYearsDueDate ?  new Date(this.particlarVehicle.permitYearsDueDate).toISOString().split("T")[0] : '',
      purchaseDate: this.particlarVehicle.purchaseDate ? new Date(this.particlarVehicle.purchaseDate).toISOString().split("T")[0] : '',
      roadTaxDate: this.particlarVehicle.roadTaxDate ? new Date(this.particlarVehicle.roadTaxDate).toISOString().split("T")[0] : '',
      insuranceDueDate:this.particlarVehicle.insuranceDueDate ?  new Date(this.particlarVehicle.insuranceDueDate).toISOString().split("T")[0] : '',
      fitnessDueDate: this.particlarVehicle.fitnessDueDate ? new Date(this.particlarVehicle.fitnessDueDate).toISOString().split("T")[0] : '',
      pollutionExpiryDate: this.particlarVehicle.pollutionExpiryDate? new Date(this.particlarVehicle.pollutionExpiryDate).toISOString().split("T")[0] : '',
    });
    this.permitPreview = this.apiUrl+'/uploads/'+this.particlarVehicle.permit
    this.rcPreview= this.apiUrl+'/uploads/'+this.particlarVehicle.RCCopy;
    this.insuracePreview= this.apiUrl+'/uploads/'+this.particlarVehicle.insurance;
    this.permit5yPreview= this.apiUrl+'/uploads/'+this.particlarVehicle.permitYears;
    this.roadTexPreview= this.apiUrl+'/uploads/'+this.particlarVehicle.roadTax;
    this.fitnessPreview= this.apiUrl+'/uploads/'+this.particlarVehicle.fitness;
    this.ownerpanPreview= this.apiUrl+'/uploads/'+this.particlarVehicle.ownerPancard;
    this.owneradharPreview= this.apiUrl+'/uploads/'+this.particlarVehicle.ownerAadharCard;
    this.cancelledChequePreview= this.apiUrl+'/uploads/'+this.particlarVehicle.cancelledCheque;
  }

  initforms() {
    this.editVehicle.patchValue({
      // Basic Details
      vehicleNumber: this.particlarVehicle.vehicle_no,
      vehicleType: this.particlarVehicle.type,
      ownerName: this.particlarVehicle.ownerName,
      driverName: this.particlarVehicle.driverName,
      makerName: this.particlarVehicle.makerName,
      chassisNumber: this.particlarVehicle.chassisNumber,
      engineNumber: this.particlarVehicle.engineNumber,
      permitNumber: this.particlarVehicle.permitNumber,
      policyNumber: this.particlarVehicle.policyNumber,
      city: this.particlarVehicle.city,
    });
  }

  preview(field: any) {
    let file = this.fieldsUploaded[field];
    if (file) {
      if (
        ["image/jpeg", "image/png", "image/svg", "image/svg+xml"].includes(
          file.type
        )
      ) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: "80%",
          height: "90vh",
          data: { file: file },
        });
      } else {
        let url = URL.createObjectURL(file);
        this.slipURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showSlip.nativeElement.click();
        });
      }
    } else {
      let imagePath = this.particlarVehicle[field];
      if (imagePath) {
        let fileExtension = imagePath.split(".").slice(-1)[0];
        if (["jpeg", "png", "jpg", "svg"].includes(fileExtension)) {
          const dialogRef = this.dialog.open(ImagePreviewComponent, {
            width: "80%",
            height: "90vh",
            data: { file: "uploads/" + imagePath },
          });
        } else {
          this.slipURL = this.sanitizer.bypassSecurityTrustUrl(
            this.apiUrl + "/uploads/" + imagePath
          );
          setTimeout(() => {
            this.showSlip.nativeElement.click();
          });
        }
      } else {
        this.toastr.error("No attachment");
      }
    }
  }
  getDriverName() {
    this.superAdminService.getDriverName().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.driver = rawdata;
          this.driver.forEach((item) => {
            if (item.driver_name) {
              this.DriverNames.push(item.driver_name);
            }
          });
          this.filteredDriverName = this.ff.driverName.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterDriverName(value || ""))
          );
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.error(res.message);
          }
        }
      },
      (error) => {}
    );
  }
  private _filterDriverName(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNames.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  getVehicleType() {
    this.superAdminService.getVehicleType().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          console.log('vehicle',this.vehicletypes);
          
          this.vehicletypes.forEach((item) => {
            this.vehicleTypesNames.push(item.vehicletype_name);
          });
          this.filteredVehicleTypes = this.ff.vehicleType.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterVehicleType(value || ""))
          );
          //this.dtOptions.data = this.users;
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.error(res.message);
          }
        }
      },
      (error) => {}
    );
  }
  private _filterVehicleType(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehicleTypesNames.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  fileChangeEventPermit(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['permit'] = fileInput.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.permitPreview = reader.result;
        console.log(this.permitPreview)
      }

  }
  fileChangeEventRC(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    //this.product.photo = fileInput.target.files[0]['name'];
    this.fieldsUploaded['RCCopy'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.rcPreview = reader.result;
        console.log(this.permitPreview)
      }
  }
  fileChangeEventInsurance(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['insurance'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.insuracePreview = reader.result;
        console.log(this.insuracePreview)
      }
  }
  fileChangeEventPermitYears(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['permitYears'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.permit5yPreview = reader.result;
      }
  }
  fileChangeEventRoadTax(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['roadTax'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.roadTexPreview = reader.result;
      }
  }
  fileChangeEventFitness(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['fitness'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.fitnessPreview = reader.result;
      }
  }
  fileChangeEventOwnerPancard(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['ownerPancard'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.ownerpanPreview = reader.result;
      }
  }
  fileChangeEventOwnerAadharcard(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['ownerAadharCard'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.owneradharPreview = reader.result;
      }
  }
  fileChangeEventCancelledCheque(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['cancelledCheque'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.cancelledChequePreview = reader.result;
      }
  }
  updateVehicle() {
    let fieldKeys: Array<String> = [];
    let fieldValues: Array<File> = [];
    for (let i in this.fieldsUploaded) {
      let file = this.fieldsUploaded[i];
      if (file) {
        fieldKeys.push(i);
        fieldValues.push(file);
      }
    }
    if (this.editVehicle.valid) {
      this.removevalidatorcss("vehicleNumberctrl");
      this.removevalidatorcss("vehicleTypectrl");
      this.removevalidatorcss("ownerNamectrl");
      this.removevalidatorcss("driverNamectrl");
      this.removevalidatorcss("makerNamectrl");
      this.removevalidatorcss("chassisNumberctrl");
      this.removevalidatorcss("engineNumberctrl");
      this.removevalidatorcss("permitNumberctrl");
      this.removevalidatorcss("policyNumberctrl");
      this.removevalidatorcss("cityctrl");
      this.removevalidatorcss("permitDueDatectrl");
      this.removevalidatorcss("permitYearsDueDatectrl");
      this.removevalidatorcss("purchaseDatectrl");
      this.removevalidatorcss("roadTaxDatectrl");
      this.removevalidatorcss("insuranceDueDatectrl");
      this.removevalidatorcss("fitnessDueDatectrl");
      this.removevalidatorcss("pollutionExpiryDatectrl");
      this.removevalidatorcss("permitctrl");
      this.removevalidatorcss("RCCopyctrl");
      this.removevalidatorcss("insurancectrl");
      this.removevalidatorcss("permitYearsctrl");
      this.removevalidatorcss("roadTaxctrl");
      this.removevalidatorcss("fitnessctrl");
      this.removevalidatorcss("ownerPancardctrl");
      this.removevalidatorcss("ownerAadharCardctrl");
      this.removevalidatorcss("cancelledChequectrl");

      var form = {
        _id: this.particlarVehicle._id,
        vehicleNumber: this.editVehicle.get("vehicleNumber").value,
        vehicleType: this.editVehicle.get("vehicleType").value,
        ownerName: this.editVehicle.get("ownerName").value,
        driverName: this.editVehicle.get("driverName").value,
        makerName: this.editVehicle.get("makerName").value,
        chassisNumber: this.editVehicle.get("chassisNumber").value,
        engineNumber: this.editVehicle.get("engineNumber").value,
        permitNumber: this.editVehicle.get("permitNumber").value,
        policyNumber: this.editVehicle.get("policyNumber").value,
        city: this.editVehicle.get("city").value,
        permitDueDate: this.editVehicle.get("permitDueDate").value,
        permitYearsDueDate: this.editVehicle.get("permitYearsDueDate").value,
        purchaseDate: this.editVehicle.get("purchaseDate").value,
        roadTaxDate: this.editVehicle.get("roadTaxDate").value,
        insuranceDueDate: this.editVehicle.get("insuranceDueDate").value,
        fitnessDueDate: this.editVehicle.get("fitnessDueDate").value,
        pollutionExpiryDate: this.editVehicle.get("pollutionExpiryDate").value,
        permit: this.particlarVehicle.permit,
        RCCopy: this.particlarVehicle.RCCopy,
        insurance: this.particlarVehicle.insurance,
        permitYears: this.particlarVehicle.permitYears,
        roadTax: this.particlarVehicle.roadTax,
        fitness: this.particlarVehicle.fitness,
        ownerPancard: this.particlarVehicle.ownerPancard,
        ownerAadharCard: this.particlarVehicle.ownerAadharCard,
        cancelledCheque: this.particlarVehicle.cancelledCheque,
      };
      const formData = new FormData();
      // const files: Array<File> = this.filesToUpload;
      for (let i = 0; i < fieldValues.length; i++) {
        // i < this.filesToUpload.length
        // formData.append("uploads[]", this.filesToUpload[i]);
        formData.append("uploads[]", fieldValues[i]);
      }
      // for (let i = 0; i < this.filesToUpload.length; i++) {
      //   formData.append("uploads[]", this.filesToUpload[i]);
      // }

      //formData.append("uploads[]",JSON.stringify(files));
      //console.log('form data variable :   ' + formData.toString());
      // const formData = new FormData();

      // for (var i = 0; i < this.fileSource.length; i++) {
      //   formData.append("file[]", this.fileSource[i]);
      // }
      formData.append("myFormData", JSON.stringify(form));
      formData.append("updatedFields", JSON.stringify(fieldKeys));
      this.superAdminService.updateVehicle(formData).subscribe(
        (res: { status: any; message: any; authorization: any; code: any }) => {
          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(["/superAdmin/manageVehicle"]);
            //this.addNewDispense.reset()
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl("superAuth/login");
            } else {
              this.toastr.error(res.message);
            }
            //this.addNewUser.reset()
          }
        },
        (error) => {}
      );
    } else {
      if (this.editVehicle.get("vehicleNumber").invalid) {
        this.addvalidatorcss("vehicleNumberctrl");
      } else {
        this.removevalidatorcss("vehicleNumberctrl");
      }

      if (this.editVehicle.get("vehicleType").invalid) {
        this.addvalidatorcss("vehicleTypectrl");
      } else {
        this.removevalidatorcss("vehicleTypectrl");
      }
      if (this.editVehicle.get("ownerName").invalid) {
        this.addvalidatorcss("ownerNamectrl");
      } else {
        this.removevalidatorcss("ownerNamectrl");
      }
      if (this.editVehicle.get("driverName").invalid) {
        this.addvalidatorcss("driverNamectrl");
      } else {
        this.removevalidatorcss("driverNamectrl");
      }
      if (this.editVehicle.get("makerName").invalid) {
        this.addvalidatorcss("makerNamectrl");
      } else {
        this.removevalidatorcss("makerNamectrl");
      }
      if (this.editVehicle.get("chassisNumber").invalid) {
        this.addvalidatorcss("chassisNumberctrl");
      } else {
        this.removevalidatorcss("chassisNumberctrl");
      }
      if (this.editVehicle.get("engineNumber").invalid) {
        this.addvalidatorcss("engineNumberctrl");
      } else {
        this.removevalidatorcss("engineNumberctrl");
      }
      if (this.editVehicle.get("permitNumber").invalid) {
        this.addvalidatorcss("permitNumberctrl");
      } else {
        this.removevalidatorcss("permitNumberctrl");
      }
      if (this.editVehicle.get("policyNumber").invalid) {
        this.addvalidatorcss("policyNumberctrl");
      } else {
        this.removevalidatorcss("policyNumberctrl");
      }
      if (this.editVehicle.get("city").invalid) {
        this.addvalidatorcss("cityctrl");
      } else {
        this.removevalidatorcss("cityctrl");
      }
      if (this.editVehicle.get("permitDueDate").invalid) {
        this.addvalidatorcss("permitDueDatectrl");
      } else {
        this.removevalidatorcss("permitDueDatectrl");
      }
      if (this.editVehicle.get("permitYearsDueDate").invalid) {
        this.addvalidatorcss("permitYearsDueDatectrl");
      } else {
        this.removevalidatorcss("permitYearsDueDatectrl");
      }
      if (this.editVehicle.get("purchaseDate").invalid) {
        this.addvalidatorcss("purchaseDatectrl");
      } else {
        this.removevalidatorcss("purchaseDatectrl");
      }
      if (this.editVehicle.get("roadTaxDate").invalid) {
        this.addvalidatorcss("roadTaxDatectrl");
      } else {
        this.removevalidatorcss("roadTaxDatectrl");
      }
      if (this.editVehicle.get("insuranceDueDate").invalid) {
        this.addvalidatorcss("insuranceDueDatectrl");
      } else {
        this.removevalidatorcss("insuranceDueDatectrl");
      }
      if (this.editVehicle.get("fitnessDueDate").invalid) {
        this.addvalidatorcss("fitnessDueDatectrl");
      } else {
        this.removevalidatorcss("fitnessDueDatectrl");
      }
      if (this.editVehicle.get("pollutionExpiryDate").invalid) {
        this.addvalidatorcss("pollutionExpiryDatectrl");
      } else {
        this.removevalidatorcss("pollutionExpiryDatectrl");
      }
      if (this.editVehicle.get("permit").invalid) {
        this.addvalidatorcss("permitctrl");
      } else {
        this.removevalidatorcss("permitctrl");
      }
      if (this.editVehicle.get("RCCopy").invalid) {
        this.addvalidatorcss("RCCopyctrl");
      } else {
        this.removevalidatorcss("RCCopyctrl");
      }
      if (this.editVehicle.get("insurance").invalid) {
        this.addvalidatorcss("insurancectrl");
      } else {
        this.removevalidatorcss("insurancectrl");
      }
      if (this.editVehicle.get("permitYears").invalid) {
        this.addvalidatorcss("permitYearsctrl");
      } else {
        this.removevalidatorcss("permitYearsctrl");
      }
      if (this.editVehicle.get("roadTax").invalid) {
        this.addvalidatorcss("roadTaxctrl");
      } else {
        this.removevalidatorcss("roadTaxctrl");
      }
      if (this.editVehicle.get("fitness").invalid) {
        this.addvalidatorcss("fitnessctrl");
      } else {
        this.removevalidatorcss("fitnessctrl");
      }
      if (this.editVehicle.get("ownerPancard").invalid) {
        this.addvalidatorcss("ownerPancardctrl");
      } else {
        this.removevalidatorcss("ownerPancardctrl");
      }
      if (this.editVehicle.get("ownerAadharCard").invalid) {
        this.addvalidatorcss("ownerAadharCardctrl");
      } else {
        this.removevalidatorcss("ownerAadharCardctrl");
      }
      if (this.editVehicle.get("cancelledCheque").invalid) {
        this.addvalidatorcss("cancelledChequectrl");
      } else {
        this.removevalidatorcss("cancelledChequectrl");
      }
    }
  }
  resetForm() {
    this.editVehicle.reset();
    this.getparticlarVehicle(this.paramId);
    // this.ngOnInit();
  }
  backTo() {
    window.history.back();
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add("validatorcss");
  }
  removevalidatorcss(id: string) {
  ("validatorcss");
  }
}
