<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Monthly TripID Expense Report : {{month}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>
    </div>
    <div class="contentbox mt-3">
        <div class="row customepadding">
            <div class="col-md-12 table-responsive ">
                <table datatable class="row-border hover tablelist" *ngIf='LrExpenseArray.length > 0'>
                    <thead>
                        <tr>
                            <!-- <td>Month</td> -->
                            <td>Supervisor Name</td>
                            <td>TripID</td>
                            <td>Total Value</td>
                            <td>Total Expense </td>
                            <td>Income</td>
                            <td>Payment Received</td>
                            <td>Payment Due</td>
                            <!-- <td>Company Name</td> -->
                            
                           
                            <td>Expense Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user1 of LrExpenseArray">
                            <!-- <td>{{user1.month}}</td> -->
                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.SupervisorName}}</td>
                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.TripID}}</td>
                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.vehicleRate}}</td> 
                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.amount}}</td>
                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.income}}</td>
                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.balancePaid}}</td>
                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.balanceDue}}</td>
                            <!-- <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">{{user1.companyName}}</td> -->
     

                            <td [routerLink]="['../../../dateWiseTripExpense', paramId, user1.companyId, user1.supervisorId, this.year]">view</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>