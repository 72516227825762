<div class="menupart">
    <div class="row d-none d-xl-block">
        <div class="col-md-9 col-9">
            <div class="logonav position-relative" [routerLink]="['/superAdmin/mydashboard']"><img
                    src="../../../assets/images/logobig.png" class="logobig" width="220" alt="Radha Swami">
                <div class="logosmall position-absolute top-0 w-100">
                    <img src="../assets/images/svg/logoIcon.svg" width="37" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="col-md-3 col-3 my-auto">
            <div class="closemenum ms-3"><img src="../../../assets/images/icons/close.svg"></div>
        </div> -->
    </div>

    <div class="navigation">
        <ul>
            <li [routerLinkActiveOptions]="{exact: false}" [routerLinkActive]="['active']">
                <div id="dash" [routerLink]="['/superAdmin/mydashboard']" class="menuarea">
                    <img src="../assets/images/icons/dashboard.png" alt="dashboard"
                        srcset="../assets/images/icons/dashboard.png">
                    <p>Dashboard</p>
                </div>
            </li>
            <li *ngIf="isRole2 && isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/usermanagement']">
                    <img src="../assets/images/icons/user-managment.png" alt="User management"
                        srcset="../assets/images/icons/user-managment.png">
                    <p>Manage User</p>
                </div>

            </li>
            <li *ngIf="isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/lrmanagement']">
                    <img src="../assets/images/icons/manage-LR-32.png" alt="Manage LS"
                        srcset="../assets/images/icons/manage-LR-32.png">
                    <p>Manage LS</p>
                </div>
            </li>
            <li *ngIf="isRole3 && isRole1" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/challan']">
                    <img src="../assets//images/icons/Challan.png" alt="Challan"
                        srcset="../assets/images/icons/ChallanW.png">
                    <p>Challan</p>
                </div>
            </li>

            <li *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageClient']">
                    <img src="../assets/images/icons/client.png" alt="manageClient"
                        srcset="../assets/images/icons/client.png">
                    <p>Manage Company</p>
                </div>
            </li>
            <li *ngIf="isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageDriver']">
                    <img src="../assets/images/icons/manage-driver.png" alt="manageDriver"
                        srcset="../assets/images/icons/manage-driver.png">
                    <p>Manage Driver</p>
                </div>
            </li>
            <li *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageVehicle']">
                    <img src="../assets/images/icons/manage-vehicle.png" alt="manageVehicle"
                        srcset="../assets/images/icons/manage-vehicle.png">
                    <p>Manage Vehicle</p>
                </div>
            </li>
            <li *ngIf="isRole2 && isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageExpense']">
                    <img src="../assets/images/icons/expenses.png" alt="manageExpense"
                        srcset="../assets/images/icons/expenses.png">
                    <p>Manage Cash</p>
                </div>
            </li>
            <!-- <li [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageShippingParties']">
                    <img src="../assets/images/icons/manageShipingB.png" alt="Manage shipping party"
                        srcset="../assets/images/icons/manageShipingW.png">
                    <p>
                        Manage Shipping Parties
                    </p>
                </div>
            </li> -->
            <!-- <li  [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/loadingslips']">
                    <img src="../assets/images/icons/loadingslipB.png" alt="loadingslip"
                        srcset="../assets/images/icons/loadingslipB.png">
                    <p>Loading Slip</p>
                </div>
            </li> -->
            <!-- <li *ngIf="isRole4" [routerLinkActiveOptions]="{exact:false}" [routerLinkActive]="['active']">
                <div class="menuarea subMenu">
                    <img src="../assets/images/icons/tyremanagement-black.png" alt="manageTyre"
                        srcset="../assets/images/icons/tyremanagement-white.png">
                    <p>Manage Tyre</p>
                </div>
                <div class="customedropdownmenu" *ngIf="isRole4">
                    <ul>
                        <li  [routerLinkActive]="['active']"  routerLink="../superAdmin/tyre-issue/inventorylist"><i class="fa-regular  fa-circle"></i> Tyre Inventory</li>
                        <li  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}"  routerLink="../superAdmin/tyre-issue/tyre-issue-list"><i class="fa-regular  fa-circle"></i> Tyre Issue/Remove</li>
                        <li  [routerLinkActive]="['active']" routerLink="../superAdmin/tyre-issue/tyre-out-list"><i class="fa-regular  fa-circle"></i> Tyre Out Entry</li>
                        <li  [routerLinkActive]="['active']" routerLink="../superAdmin/tyre-issue/tyre-report-list"><i class="fa-regular  fa-circle"></i> Tyre Out Report</li> -->
                        <!-- <li routerLink="../superAdmin/tyre-issue/inventorylist"><i
                                class="fa-solid  fa-circle"></i> Inventory List</li> -->

                    <!-- </ul>
                </div>
            </li> -->
            <!-- <li *ngIf="isRole3 && isRole4  && isRole2" [routerLinkActiveOptions]="{exact: false}" [routerLinkActive]="['active']" >
                <div class="menuarea">
                    <img src="../assets/images/icons/fuelB.png" alt="FuelManagement"
                        srcset="../assets/images/icons/fuelW.png">
                    <p>Fuel Management</p>
                </div>
                <div class="customedropdownmenu dashcolll">
                    <ul>
                        <li  [routerLinkActive]="['active']" [routerLink]="['../superAdmin/fuel/list']"><i class="fa-regular fa-circle"></i> Fuel Management</li>
                        <li  [routerLinkActive]="['active']" [routerLink]="['../superAdmin/fuel/vehicleList']"><i class="fa-regular fa-circle"></i> Vehicle Fuel</li>
                    </ul>
                </div>
            </li> -->
           
            <li *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea subMenu">
                    <img src="../assets/images/icons/manage-log.png" alt="manageExpenseLog"
                        srcset="../assets/images/icons/manage-log.png">
                    <p>Miscellaneous expenses</p>
                </div>
                <div class="customedropdownmenu dashcolll dropMenu">
                    <ul>
                        <li  [routerLinkActive]="['active']" routerLink="../superAdmin/miscellaneous-expenses/officeExpense"><i class="fa-regular fa-circle"></i>  Office Expense</li>
                        <li  [routerLinkActive]="['active']" routerLink="../superAdmin/miscellaneous-expenses/vehicleMaintenence"><i class="fa-regular fa-circle"></i>  Vehicle Maintenance</li>

                        <!-- <li routerLink="../superAdmin/ownVehicleExpence/ownVehicleExpenceList"> Our Own Vehicle Expense</li> -->
                    </ul>
                </div>
            </li>
            <!-- <li *ngIf="isRole3 && isRole4  && isRole2" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/rateChart']">
                    <img src="../assets/images/icons/rate-card-icon-b.png" alt="manageVehicle"
                        srcset="../assets/images/icons/Rate-card-white.png">
                    <p>Rate Chart</p>
                </div>
            </li> -->
            
            
        </ul>
   </div>
    <div class="otherbuttonsnew">
        <ul>
            <li [routerLinkActiveOptions]="{exact: false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/profile']">
                    <img src="../assets/images/icons/profilenew.svg" alt="manageClient"
                        srcset="../assets/images/icons/profilenew.svg">
                    <p>Profile</p>
                </div>
            </li>
            <li>
                <div id="dash" (click)="logout()" class="menuarea">
                    <img src="../assets/images/icons/logoutnew.svg" alt="" srcset="../assets/images/icons/logoutnew.svg">
                    <p>Logout</p>
                </div>
            </li>
            <li>
                <div class="d-none d-xl-block">
                    <div class="menuExtande open ">
                        <i class="fa-solid fa-bars"></i>
                    </div>
                </div>
                <!-- d-block d-sm-block d-md-none d-lg-none -->
                <!-- <div class="d-block d-sm-none d-md-block"> -->
                <div class="d-block d-sm-block d-md-none d-lg-none">
                    <div class="menuExtande close ">
                        <i class="fa-solid fa-arrow-left"></i>
                    </div>
                </div>
            </li>
        </ul>
    </div>
   
</div>