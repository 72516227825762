import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-own-vehicle-expence-list',
  templateUrl: './own-vehicle-expence-list.component.html',
  styleUrls: ['./own-vehicle-expence-list.component.css']
})
export class OwnVehicleExpenceListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
