import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-office-expance-list',
  templateUrl: './office-expance-list.component.html',
  styleUrls: ['./office-expance-list.component.css']
})
export class OfficeExpanceListComponent implements OnInit {

  users: any=[];
  subCat;
  @ViewChild('paginator') paginator: MatPaginator;
  dataSource:  MatTableDataSource<any>;
  displayedColumns: any = ['SupervisorName', 'expenseSubCategory', 'companyName', 'totalExpenseAmount', 'expenseDate', 'expenseDescription', 'action'];
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner:NgxSpinnerService) { }
  userSessionData;

  ngOnInit(): void {
    this.getAllExpense();
    this.getSubCat();
    this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
  }
  getSubCat(){
    this.superAdminService.getSubCategory()
    .subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        var rawdata = res.data;
       
        this.subCat=rawdata.filter(word => word.expenseCategory === 'Office Expense');
      }
      else {
        alert(res.message)
      }
    }, error => {
    });
  }
  getAllExpense() {
    this.spinner.show();
    this.superAdminService.getMasterData()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.users = rawdata;
          // this.users = rawdata.map((item) =>  item ).filter((ar)=> ar.expenseCategory === "Office Expense")
          if(this.userSessionData.user_role_id[0]!=1){
            this.users = this.users.map((item) => item).filter((ar)=> ar.supervisorId === this.userSessionData._id )
          }
          this.superAdminService.getClient().subscribe((companies: { status: any, message: any, data: any }) => {
  
            if (companies.status == true) {
              var companiesData = companies.data;
              this.users.forEach((item,i)=>{
                this.users[i].companyName = companiesData.filter((item)=> this.users[i].companyId==item._id);
                this.users[i].companyName = this.users[i].companyName[0]?this.users[i].companyName[0]['clientName']:this.users[i].companyName[0]
              })
              this.superAdminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
                if(supervisors.status == true){
                  var supervisorsData = supervisors.data;
                  this.users.forEach((item,i)=>{
                    this.users[i].SupervisorName = supervisorsData.filter((item)=> this.users[i].supervisorId==item._id);
                    this.users[i].SupervisorName = this.users[i].SupervisorName[0]?this.users[i].SupervisorName[0]['full_name']:this.users[i].SupervisorName[0]
                  })
                  this.superAdminService.getSubCategory().subscribe((subCategories: {status: any, message: any, data: any}) => {
                    if(subCategories.status == true){
                      var subCategoriesData = subCategories.data;
                      this.users.forEach((item,i)=>{
                        let category = subCategoriesData.filter((item)=> this.users[i].expenseSubCategoryId==item._id);
                        this.users[i].expenseSubCategory = category[0]?category[0]['expenseSubCategory']:category[0];
                        this.users[i].expenseCategory = category[0]?category[0]['expenseCategory']:category[0];
                      })
                      this.users = this.users.filter((item) => item.expenseCategory=='Office Expense');
                      console.log("expense  LIst",this.users)
                      this.dataSource = new MatTableDataSource(this.users);
                      this.dataSource.paginator = this.paginator;
                      this.dataSource.sort = this.sort;
                    }else{
                      console.log(res.message);
                    }
                  }, error => {
                    console.log('error', error);
                  })
                }else{
                  console.log(res.message);
                }
              }, error => {
                console.log('error', error);
              })
            }
            else {
              alert(res.message)
            }
    
            }, error => {
              console.log('error',error);
            }
          );
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
            this.spinner.hide();
          }
        }

      }, error => {
        this.spinner.hide();
      });
  }
  // emitdata(params) {
  //   sessionStorage.setItem("userdata", JSON.stringify(params));
  // }

  deleteExpense(_id) {
    if(this.superAdminService.deleteDailogBox()){
    this.superAdminService.deleteExpense(_id)
      .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          this.toastr.success(res.message);
          //this.router.navigateByUrl('/user/userManagement');
          this.getAllExpense();
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
  }
  filterData(value){
    this.dataSource.filter = value;
  }
  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'SupervisorName':
          return compare(lower(a.SupervisorName), lower(b.SupervisorName), isAsc);
        case 'expenseSubCategory':
          return compare(lower(a.expenseSubCategory), lower(b.expenseSubCategory), isAsc);
        case 'companyName':
          return compare(lower(a.companyName), lower(b.companyName), isAsc);
        case 'totalExpenseAmount':
          return compare(a.totalExpenseAmount, b.totalExpenseAmount, isAsc);
        case 'expenseDate':
          return compare(a.expenseDate, b.expenseDate, isAsc);
        case 'expenseDescription':
          return compare(a.expenseDescription, b.expenseDescription, isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string , b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value){
      return value?value.toLowerCase():value;
    }
  }
}
