import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { config } from "src/app/config";
import { SuperAdminService } from "src/app/super-admin.service";
import { MatDialog } from "@angular/material/dialog";
import { EditInvoiceComponent } from "../edit-invoice/edit-invoice.component";
import { AddInvoiceComponent } from "../add-invoice/add-invoice.component";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ImagePreviewComponent } from "../image-preview/image-preview.component";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-edit-lr",
  templateUrl: "./edit-lr.component.html",
  styleUrls: ["./edit-lr.component.css"],
})
export class EditLrComponent implements OnInit {

  isDisabled: boolean = true;
  
  Tollfiles: Array<File> = [];
  TollfilesString = [];
  userroleid;
  userSessionData;
  isRoleSuperVisor = true;
  isFileNameShown = true;
  isFileSelcted = false;
  notShown = false;
  isShown = true;
  vehicles: any;
  vehiclesNumber = [];
  vehicleTypesNames = [];
  DriverNames = [];
  DriverNumbers = [];
  filteredVehicleTypes: Observable<string[]>;
  filteredVehicleNo: Observable<string[]>;
  filteredDriverName: Observable<string[]>;
  filteredDriverNumber: Observable<string[]>;
  podBack: Array<File> = [];
  loadingSlipsBack: Array<File> = [];
  loadingSlipsBackString = [];
  InvoiceDetails = [];
  clientList = [];
  particlarLR: any;
  fileNameLoad: any;
  fileNameLoadBack:any;
  vehiclesDetail: any;
  submitLRClicked = false;
  actualbalance:any = 0;
  updownkm:any = 0;
  paramId;
  previewLoadingSlipURL: any;
  apiUrl = environment.domain;
  @ViewChild("showLoadingSlipPreview") showLoadingSlipPreview: ElementRef;
  editLR = new FormGroup({
    supervisorname: new FormControl(""),
    clientList: new FormControl(""),
    ConsigneeName: new FormControl(""),
    ConsigneeAddress: new FormControl(""),
    ConsignorName: new FormControl(""),
    ConsignorAddress: new FormControl(""),
    VehicleNo: new FormControl(""),
    vehicleType: new FormControl(""),
    from: new FormControl(""),
    to: new FormControl(""),
    GSTIN: new FormControl(""),
    date: new FormControl(""),
    RoadPermitNo: new FormControl(""),
    StateCode: new FormControl(""),
    DLY: new FormControl(""),
    DeclaredValue: new FormControl(""),
    fixAdhoc: new FormControl(""),
    quantity: new FormControl(""),
    transportorName: new FormControl(""),
    transportorMobile: new FormControl(
      "",
      Validators.compose([Validators.pattern(/^\d{10}$/)])
    ),
    driverName: new FormControl(""),
    driverMobile: new FormControl(""),
    tollAttachment: new FormControl(""),
    loadingSlipAttchment: new FormControl(""),
    loadingSlipAttchmentBack:new FormControl(""),
    Frieght: new FormControl(""),

    startmeterReading: new FormControl(""),
    endmeterReading: new FormControl(""),
    tripSDate: new FormControl(""),
    tripEDate: new FormControl(""),
    outtime: new FormControl(""),
    INtime: new FormControl(""),
    noofTools: new FormControl(""),
    tooltaxPaid: new FormControl(""),
    labourPaid: new FormControl(""),
    otherPayment: new FormControl(""),
    updownkm: new FormControl(""),
    totalhour: new FormControl(""),
    extrahour: new FormControl(""),
    detention: new FormControl(""),
    advance: new FormControl(""),
    balance: new FormControl(""),
    balancePaid: new FormControl(""),

    vehicleRate: new FormControl(""),
    // clientList: new FormControl('', [Validators.required]),
    LR_no: new FormControl(""),
    manualLR_no: new FormControl(""),
    dieselExpense: new FormControl(""),
    driverCash: new FormControl(""),
    truckExpense: new FormControl("")
    // ConsigneeName: new FormControl('', [Validators.required]),
    // ConsigneeAddress: new FormControl('', [Validators.required]),
    // ConsignorName: new FormControl('', Validators.required),
    // ConsignorAddress: new FormControl('', Validators.required),
    // VehicleNo: new FormControl('', [Validators.required]),
    // from: new FormControl('', Validators.required),
    // to: new FormControl('', Validators.required),
    // GSTIN: new FormControl('', [Validators.required]),
    // date: new FormControl('', Validators.required),

    // startmeterReading: new FormControl(''),
    // endmeterReading: new FormControl(''),
    // outtime: new FormControl(''),
    // INtime: new FormControl(''),
    // noofTools: new FormControl(''),
    // tooltaxPaid: new FormControl(''),
    // labourPaid: new FormControl(''),
    // otherPayment: new FormControl(''),
    // updownkm: new FormControl(''),
    // totalhour: new FormControl(''),
    // vehicleRate: new FormControl('')

    //status: new FormControl('', Validators.required)
  });
  docURL:any;
  superVisor: any;
  @ViewChild('showDoc') showDoc: ElementRef;
  get ff() {
    "No Attachment Found";
    return this.editLR.controls;
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private superAdminService: SuperAdminService,
    private sanitizer: DomSanitizer
  ) {
    this.activatedRoute.params.subscribe((param) => {
      this.paramId = param.id;
      this.getLRDetail(this.paramId);
    });
  }

  ngOnInit(): void {
    this.getClientList();
    this.getVehicleType();
    this.getDriverNames();
    this.getVehicleDetails();
    this.userSessionData = JSON.parse(sessionStorage.getItem("userSession"));
    this.userroleid = this.userSessionData.user_role_id[0];
    if (this.userroleid === 2) {
      this.isRoleSuperVisor = false;
      this.editLR.controls.supervisorname.disable()
    }
    this.getSuperVisorName();
   
  }

  getVehicleType() {
    this.superAdminService.getVehicleType().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          rawdata.forEach((item) => {
            this.vehicleTypesNames.push(item.vehicletype_name);
          });
          this.filteredVehicleTypes = this.ff.vehicleType.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterVehicleType(value || ""))
          );
          console.log(this.vehicleTypesNames);
          //this.dtOptions.data = this.users;
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.success(res.message);
          }
        }
      },
      (error) => {}
    );
  }
  private _filterVehicleType(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehicleTypesNames.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getVehicleDetails() {
    this.superAdminService.getvehicles().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          this.vehicles = res.data;
          this.vehicles.forEach((item) => {
            this.vehiclesNumber.push(item.vehicle_no);
          });
          this.filteredVehicleNo = this.ff.VehicleNo.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterVehicleNo(value || ""))
          );
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.success(res.message);
          }
        }
      },
      (error) => {}
    );
  }
  private _filterVehicleNo(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehiclesNumber.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getDriverNames() {
    this.superAdminService.getDrivers().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          rawdata.forEach((item) => {
            if (item.driver_name) {
              this.DriverNames.push(item.driver_name);
            }
            if (item.contactNumber) {
              this.DriverNumbers.push(item.contactNumber);
            }
          });
          this.filteredDriverName = this.ff.driverName.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterDriverName(value || ""))
          );
          this.filteredDriverNumber = this.ff.driverMobile.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterDriverNumber(value || ""))
          );
          //this.dtOptions.data = this.users;
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.success(res.message);
          }
        }
      },
      (error) => {}
    );
  }
  private _filterDriverName(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNames.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filterDriverNumber(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNumbers.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  getClientList() {
    this.superAdminService.getClientList().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.clientList = rawdata;
          //console.log(this.company);
          //this.dtOptions.data = this.users;
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl("superAuth/login");
            } else {
              this.toastr.success(res.message);
            }
          }
        }
      },
      (error) => {}
    );
  }

  getLRDetail(id: string) {
    this.superAdminService.particlarLR(id).subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          
          this.particlarLR = res.data[0].receipts[0];
          this.initform();
          this.vehicles = res.data[0].vehiclesDetail;
          console.log("loadingSlip",this.particlarLR?.loadingSlipAttchment)
          if(this.particlarLR?.loadingSlipAttchment.length > 0){
            this.fileNameLoad = this.particlarLR?.loadingSlipAttchment[0];
            this.fileNameLoadBack = this.particlarLR?.loadingSlipAttchmentBack[0]
            console.log("loading slip",this.fileNameLoadBack)
            const extractFileNameBack = this.fileNameLoadBack.lastIndexOf("202")
            this.fileNameLoadBack = this.fileNameLoadBack.slice(0 + extractFileNameBack)
            const extractFileName = this.fileNameLoad.lastIndexOf("202");
            // console.log(extractFileName)
            this.fileNameLoad = this.fileNameLoad.slice(0 + extractFileName);
            // console.log(this.fileNameLoad );
            //console.log("LR",this.fileNameLoad);
          }
          // else {
          //   var mess = "NO ATTACHMENT FOUND"
          //   this.fileNameLoad = mess

          // }
            
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl("superAuth/login");
            } else {
              this.toastr.success(res.message);
            }
          }
        }
      },
      (error) => {}
    );
  }
  initform() {
    console.log("gcgcggfcgc@@@@@@@@@",this.particlarLR)
    this.editLR.patchValue({
      // Basic Details

      clientList: this.particlarLR.clientId,
      supervisorname: this.particlarLR.supervisorId,
      LR_no: this.particlarLR.LR_no,
      manualLR_no: this.particlarLR.manualLR_no,
      //date: this.particlarLR.date,
      ConsigneeName: this.particlarLR.consignee_name,
      ConsigneeAddress: this.particlarLR.consignee_address,
      ConsignorName: this.particlarLR.consignor_name,
      ConsignorAddress: this.particlarLR.consignor_address,
      VehicleNo: this.particlarLR.vehicle_id,
      vehicleType: this.particlarLR.vehicleType,
      //VehicleNo: this.particlarLR.vehicle_no,
      from: this.particlarLR.from,
      to: this.particlarLR.to,
      GSTIN: this.particlarLR.GSTIN,
      date: new Date(this.particlarLR.date).toISOString().split("T")[0],
      RoadPermitNo: this.particlarLR.road_permit_no,
      StateCode: this.particlarLR.stateCode,
      DLY: this.particlarLR.dly_no,
      DeclaredValue: this.particlarLR.declared_value,
      fixAdhoc: this.particlarLR.fixAdhoc,
      quantity: this.particlarLR.quantity,
      transportorName: this.particlarLR.transportorName,
      transportorMobile: this.particlarLR.transportorMobile,
      driverName: this.particlarLR.driverName,
      driverMobile: this.particlarLR.driverMobile,
      Frieght: this.particlarLR.Frieght,

      // Information For office use only

      startmeterReading: this.particlarLR.startmeterReading,
      endmeterReading: this.particlarLR.endmeterReading,
      tripSDate:this.particlarLR.tripStartDate ? new Date(this.particlarLR.tripStartDate).toISOString().split("T")[0]: '',
      tripEDate: this.particlarLR.tripEndDate ? new Date(this.particlarLR.tripEndDate).toISOString().split("T")[0]: '',
      INtime: this.particlarLR.in_time,
      outtime: this.particlarLR.out_time,
      noofTools: this.particlarLR.noofTools,
      tooltaxPaid: this.particlarLR.tooltaxPaid,
      labourPaid: this.particlarLR.labourPaid,
      otherPayment: this.particlarLR.otherPayment,
      updownkm: this.particlarLR.up_down_km,
      totalhour: this.particlarLR.totalhour,
      extrahour: this.particlarLR.extrahour,
      detention: this.particlarLR.detention,
      advance: this.particlarLR.advance,
      balance: this.particlarLR.balance,
      balancePaid: this.particlarLR.balancePaid,
      vehicleRate: this.particlarLR.vehicleRate,
      dieselExpense: this.particlarLR.diesel_expense,
      driverCash: this.particlarLR.driver_cash,
      truckExpense: this.particlarLR.truck_expense
    });
  }
  onFocusOutEvent(event: any) {
    console.log(event.target.value);
    // this.actualbalance = parseInt(document.getElementById("vehicleRatectrl")['value']) -
    //   parseInt(document.getElementById("advancectrl")['value']) -
    //   parseInt(event.target.value);
    this.actualbalance =
      parseInt(this.editLR.get("vehicleRate").value) -
      parseInt(this.editLR.get("advance").value) -
      parseInt(event.target.value);
    console.log("Actual Balance ", this.actualbalance);
    this.editLR.patchValue({
      balance: this.actualbalance,
    });
  }

  //calculated up-down-km

  //   function updateDue() {
  //     var total = parseInt(document.getElementById("totalval").value);
  //     var val2 = parseInt(document.getElementById("inideposit").value);
  //     // to make sure that they are numbers
  //     if (!total) { total = 0; }
  //     if (!val2) { val2 = 0; }
  //     var ansD = document.getElementById("remainingval");
  //     ansD.value = total - val2;
  // }

  onFocusOutendmeterReading(event: any) {
    console.log(event.target.value);
    this.updownkm =
      parseInt(event.target.value) -
      parseInt(this.editLR.get("startmeterReading").value);
    console.log("updownkm KM ", this.updownkm);
    this.editLR.patchValue({
      updownkm: this.updownkm,
    });
  }

  fileChangeEventtollAttachment(fileInput: any) {
    if (fileInput.target.files.length) {
      this.Tollfiles = [];
      for (var i = 0; i < fileInput.target.files.length; i++) {
        this.Tollfiles.push(fileInput.target.files[i]);
      }
    }

    // this.filesToUpload.push(fileInput.target.files[0] );
  }

  addTollFiles() {
    const formData = new FormData();

    for (let i = 0; i < this.Tollfiles.length; i++) {
      formData.append("Tollfiles[]", this.Tollfiles[i]);
    }

    this.superAdminService.addTollFiles(formData).subscribe(
      (res: {
        rawdata: any;
        status: any;
        message: any;
        authorization: any;
        code: any;
      }) => {
        if (res.status == true) {
          //alert(res.message)
          this.toastr.success(res.message);
          this.TollfilesString = res.rawdata;
          //this.router.navigate(['/superAdmin/lrmanagement']);
          //this.addNewLR.reset()
        } else {
          // alert(res.message)
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.success(res.message);
          }
          //this.addNewLR.reset()
        }
      },
      (error) => {}
    );
  }

  updateLRSubmit() {
    debugger;
    if (this.editLR.valid) {
      // Basic Details
      this.removevalidatorcss("ConsigneeNamectrl");
      this.removevalidatorcss("ConsigneeAddressctrl");
      this.removevalidatorcss("ConsignorAddressctrl");
      this.removevalidatorcss("ConsignorNamectrl");
      this.removevalidatorcss("VehicleNoctrl");
      this.removevalidatorcss("fromctrl");
      this.removevalidatorcss("toctrl");
      this.removevalidatorcss("GSTINctrl");
      this.removevalidatorcss("datectrl");
      // this.removevalidatorcss("statusctrl");
      // Information For office use only
      this.removevalidatorcss("startmeterReadingctrl");
      this.removevalidatorcss("endmeterReadingctrl");
      this.removevalidatorcss("outtimectrl");
      this.removevalidatorcss("INtimectrl");
      this.removevalidatorcss("noofToolsctrl");
      this.removevalidatorcss("tooltaxPaidctrl");
      this.removevalidatorcss("labourPaidctrl");
      this.removevalidatorcss("otherPaymentctrl");
      this.removevalidatorcss("updownkmctrl");
      this.removevalidatorcss("totalhourctrl");
      this.removevalidatorcss("vehicleRatectrl");
      // this.removevalidatorcss("tollAttachmentctrl");
      // this.removevalidatorcss("loadingSlipAttchmentBackctrl");

      //  var c = this.addNewLR.get('InvoiceNo').value
      //   + ',' + document.getElementById("new1")['value']
      //   + ',' + document.getElementById("new2")['value']
      //   + ',' + document.getElementById("new3")['value'];
      var form = {
        // Add Invoice Details
        _id: this.particlarLR._id,

        // Basic Details
        clientList: this.editLR.get("clientList").value,
        supervisorId: this.editLR.get("supervisorname").value,
        manualLR_no: this.editLR.get("manualLR_no").value,
        ConsigneeName: this.editLR.get("ConsigneeName").value,
        ConsigneeAddress: this.editLR.get("ConsigneeAddress").value,
        ConsignorName: this.editLR.get("ConsignorName").value,
        ConsignorAddress: this.editLR.get("ConsignorAddress").value,
        VehicleNo: this.editLR.get("VehicleNo").value,
        vehicleType: this.editLR.get("vehicleType").value,
        GSTIN: this.editLR.get("GSTIN").value,
        from: this.editLR.get("from").value,
        to: this.editLR.get("to").value,
        RoadPermitNo: this.editLR.get("RoadPermitNo").value,
        StateCode: this.editLR.get("StateCode").value,
        DLY: this.editLR.get("DLY").value,
        DeclaredValue: this.editLR.get("DeclaredValue").value,
        fixAdhoc: this.editLR.get("fixAdhoc").value,
        quantity: this.editLR.get("quantity").value,
        transportorName: this.editLR.get("transportorName").value,
        transportorMobile: this.editLR.get("transportorMobile").value,
        driverName: this.editLR.get("driverName").value,
        driverMobile: this.editLR.get("driverMobile").value,
        Frieght: this.editLR.get("Frieght").value,
        date: this.editLR.get("date").value,
        loadingSlipAttchment: this.TollfilesString.length
          ? this.TollfilesString[0]
          : this.particlarLR.loadingSlipAttchment,

          loadingSlipAttchmentBack: this.loadingSlipsBackString.length
        ? this.loadingSlipsBackString[0]
        : this.particlarLR.loadingSlipAttchmentBack,

        //"status": this.editLR.get('status').value,

        // Information For office use only

        startmeterReading: this.editLR.get("startmeterReading").value,
        endmeterReading: this.editLR.get("endmeterReading").value,
        tripSDate: this.editLR.get("tripSDate").value,
        tripEDate: this.editLR.get("tripEDate").value,
        INtime: this.editLR.get("INtime").value,
        outtime: this.editLR.get("outtime").value,
        noofTools: this.editLR.get("noofTools").value,
        tooltaxPaid: this.editLR.get("tooltaxPaid").value,
        labourPaid: this.editLR.get("labourPaid").value,
        otherPayment: this.editLR.get("otherPayment").value,
        updownkm: this.editLR.get("updownkm").value,
        totalhour: this.editLR.get("totalhour").value,
        extrahour: this.editLR.get("extrahour").value,
        detention: this.editLR.get("detention").value,
        advance: this.editLR.get("advance").value,
        balance: this.editLR.get("balance").value,
        balancePaid: this.editLR.get("balancePaid").value,

        vehicleRate: this.editLR.get("vehicleRate").value,
        dieselExpense: this.editLR.get("dieselExpense").value,
        driverCash: this.editLR.get("driverCash").value,
        truckExpense: this.editLR.get("truckExpense").value
      };
      console.log("form",form)
      this.superAdminService.updateLR({ form }).subscribe(
        (res: { status: any; message: any; authorization: any; code: any }) => {
          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(["/superAdmin/lrmanagement"]);
            //this.editLR.reset()
          } else {
            // alert(res.message)
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl("superAuth/login");
            } else {
              this.toastr.success(res.message);
            }
            //this.editLR.reset()
          }
        },
        (error) => {}
      );
    } else {
      // Basic Details
      if (this.editLR.get("supervisorname").invalid) {
        this.addvalidatorcss("supervisornamectrl");
      } else {
        this.removevalidatorcss("supervisornamectrl");
      }
      if (this.editLR.get("ConsigneeAddress").invalid) {
        this.addvalidatorcss("ConsigneeAddressctrl");
      } else {
        this.removevalidatorcss("ConsigneeAddressctrl");
      }
      if (this.editLR.get("ConsignorName").invalid) {
        this.addvalidatorcss("ConsignorNamectrl");
      } else {
        this.removevalidatorcss("ConsignorNamectrl");
      }
      if (this.editLR.get("ConsigneeName").invalid) {
        this.addvalidatorcss("ConsigneeNamectrl");
      } else {
        this.removevalidatorcss("ConsigneeNamectrl");
      }
      if (this.editLR.get("ConsignorAddress").invalid) {
        this.addvalidatorcss("ConsignorAddressctrl");
      } else {
        this.removevalidatorcss("ConsignorAddressctrl");
      }
      if (this.editLR.get("VehicleNo").invalid) {
        this.addvalidatorcss("VehicleNoctrl");
      } else {
        this.removevalidatorcss("VehicleNoctrl");
      }
      if (this.editLR.get("from").invalid) {
        this.addvalidatorcss("fromctrl");
      } else {
        this.removevalidatorcss("fromctrl");
      }
      if (this.editLR.get("to").invalid) {
        this.addvalidatorcss("toctrl");
      } else {
        this.removevalidatorcss("toctrl");
      }
      if (this.editLR.get("date").invalid) {
        this.addvalidatorcss("datectrl");
      } else {
        this.removevalidatorcss("datectrl");
      }
      if (this.editLR.get("GSTIN").invalid) {
        this.addvalidatorcss("GSTINctrl");
      } else {
        this.removevalidatorcss("GSTINctrl");
      }
      // if (this.editLR.get("tollAttachment").invalid) {
      //   this.addvalidatorcss("tollAttachmentctrl");
      // } else {
      //   this.removevalidatorcss("tollAttachmentctrl");
      // }
      if (this.editLR.get("loadingSlipAttchmentBack").invalid) {
        this.addvalidatorcss("loadingSlipAttchmentBackctrl");
      } else {
        this.removevalidatorcss("loadingSlipAttchmentBackctrl");
      }

      // Information For office use only

      if (this.editLR.get("startmeterReading").invalid) {
        this.addvalidatorcss("startmeterReadingctrl");
      } else {
        this.removevalidatorcss("startmeterReadingctrl");
      }
      if (this.editLR.get("endmeterReading").invalid) {
        this.addvalidatorcss("endmeterReadingctrl");
      } else {
        this.removevalidatorcss("endmeterReadingctrl");
      }
      if (this.editLR.get("outtime").invalid) {
        this.addvalidatorcss("outtimectrl");
      } else {
        this.removevalidatorcss("outtimectrl");
      }
      if (this.editLR.get("INtime").invalid) {
        this.addvalidatorcss("INtimectrl");
      } else {
        this.removevalidatorcss("INtimectrl");
      }
      if (this.editLR.get("noofTools").invalid) {
        this.addvalidatorcss("noofToolsctrl");
      } else {
        this.removevalidatorcss("noofToolsctrl");
      }
      if (this.editLR.get("tooltaxPaid").invalid) {
        this.addvalidatorcss("tooltaxPaidctrl");
      } else {
        this.removevalidatorcss("tooltaxPaidctrl");
      }
      if (this.editLR.get("labourPaid").invalid) {
        this.addvalidatorcss("labourPaidctrl");
      } else {
        this.removevalidatorcss("labourPaidctrl");
      }
      if (this.editLR.get("otherPayment").invalid) {
        this.addvalidatorcss("otherPaymentctrl");
      } else {
        this.removevalidatorcss("otherPaymentctrl");
      }
      if (this.editLR.get("updownkm").invalid) {
        this.addvalidatorcss("updownkmctrl");
      } else {
        this.removevalidatorcss("updownkmctrl");
      }
      if (this.editLR.get("totalhour").invalid) {
        this.addvalidatorcss("totalhourctrl");
      } else {
        this.removevalidatorcss("totalhourctrl");
      }
      if (this.editLR.get("vehicleRate").invalid) {
        this.addvalidatorcss("vehicleRatectrl");
      } else {
        this.removevalidatorcss("vehicleRatectrl");
      }
      if (this.editLR.get("transportorMobile").invalid) {
        this.addvalidatorcss("transportorMobilectrl");
      } else {
        this.removevalidatorcss("transportorMobilectrl");
      }
      if (this.editLR.get("dieselExpense").invalid) {
        this.addvalidatorcss("dieselExpensectrl");
      } else {
        this.removevalidatorcss("dieselExpensectrl");
      }
      if (this.editLR.get("driverCash").invalid) {
        this.addvalidatorcss("driverCashctrl");
      } else {
        this.removevalidatorcss("driverCashctrl");
      }
      if (this.editLR.get("truckExpense").invalid) {
        this.addvalidatorcss("truckExpensectrl");
      } else {
        this.removevalidatorcss("truckExpensectrl");
      }
    }
  }

  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add("validatorcss");
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove("validatorcss");
  }
  getSuperVisorName() {
    this.superAdminService.getSuperVisorName().subscribe(
      (res: { status: any; message: any; data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.superVisor = rawdata;
          //this.dtOptions.data = this.users;
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.error(res.message);
          }
        }
      },
      (error) => {}
    );
  }

  // editInvoice(data){
  //   const dialogRef = this.dialog.open(EditInvoiceComponent,data);
  // }
  addnewinv(acc) {
    const dialogRef = this.dialog.open(AddInvoiceComponent, {
      panelClass: "addInvoice",
      data: {
        acc,
        getLrDetail: () => {
          this.getLRDetail(this.paramId);
        },
      },
    });
  }
  editInvoice(acc, id): void {
    const dialogRef = this.dialog.open(EditInvoiceComponent, {
      panelClass: "addInvoice",
      data: {
        acc,
        id,
        getLrDetail: () => {
          this.getLRDetail(this.paramId);
        },
      },
    });
  }
  previewLoadingSlip() {
    console.log("POD front",this.Tollfiles[0])
    let file = this.Tollfiles[0];
    console.log("POD Fronot",file)
    if (file) {
      console.log("workin--front")
      if (
        ["image/jpeg", "image/png", "image/svg", "image/svg+xml"].includes(
          file.type
        )
      ) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: "80%",
          height: "90vh",
          data: { file: file },
          
        });
        console.log(file)
      } else {
        let url = URL.createObjectURL(file);
        this.previewLoadingSlipURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showLoadingSlipPreview.nativeElement.click();
        });
      }
    } else {
      let url = this.particlarLR.loadingSlipAttchment[0];
      const file = url.split("");
      // console.log(file)
      const fileName = url.lastIndexOf("\\");
      const res = url.slice(0 + fileName);
      console.log(res);

      if (url) {
        let fileExtension = url.split(".").slice(-1)[0];
        if (["jpeg", "png", "jpg", "svg"].includes(fileExtension)) {
          const dialogRef = this.dialog.open(ImagePreviewComponent, {
            width: "80%",
            height: "90vh",
            data: { file: url },
          });
        } else {
          this.previewLoadingSlipURL = this.sanitizer.bypassSecurityTrustUrl(
            this.apiUrl + "/" + url
          );
          setTimeout(() => {
            this.showLoadingSlipPreview.nativeElement.click();
          });
        }
      } else {
        this.toastr.error("No attachment");
      }
    }
  }

  chooseFile() {
    // console.log("clicked");
    this.isFileSelcted = true;
    this.isShown = false;
    this.notShown = true;
    this.isFileNameShown = false;
  }
  backTo() {
    window.history.back();
  }
  cancel() {
    this.isFileSelcted = false;
    this.isShown = true;
    this.notShown = false;
    this.isFileNameShown = true;
  }

  fileChangeEventloadingSlipBackAttchment(fileInput: any) {
    this.loadingSlipsBack = [];
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.loadingSlipsBack.push(fileInput.target.files[i]);
    }
    console.log("back pOD",this.loadingSlipsBack)

  }

  onKeyloadingSlipBackAttchment(event: any) {
    if (this.editLR.get('loadingSlipAttchmentBack').invalid) {
      this.addvalidatorcss("loadingSlipAttchmentBackctrl");
    } else {
      this.removevalidatorcss("loadingSlipAttchmentBackctrl");
    }
  }

  addloadingSlipsBack() {
    const formData = new FormData();
    console.log("POD BAck ADD File",this.loadingSlipsBack)
    for (let i = 0; i < this.loadingSlipsBack.length; i++) {
      formData.append("loadingSlipsback[]", this.loadingSlipsBack[i]);
    }
    debugger;
    this.superAdminService.addloadingSlipsBack(formData)
      .subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          this.toastr.success(res.message);
          this.loadingSlipsBackString = res.rawdata;
          console.log("back PODSS",this.loadingSlipsBackString)
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      });


  }
  previewLRBack(){
    // console.log("POD back",this.particlarLR.loadingSlipAttchmentBack[0])
   
    this.podBack = this.particlarLR.loadingSlipAttchmentBack
    let fileback:any = this.podBack[0]

    console.log("POD back",fileback)
    if(fileback){
      console.log("checkinn",fileback)
      console.log("workin")
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
            width:'80%',
            height:'90vh',
            data:{backFile:fileback}
        });
      
    } 
    // else if(){
    //   let url =URL.createObjectURL(fileback);
    //   this.docURL = this.sanitizer.bypassSecurityTrustUrl(url);
    //   setTimeout(()=>{
    //     this.showDoc.nativeElement.click();
    //   })
    // }
    else{
      this.toastr.error("Please upload attachment")
    }

  }
  
}
