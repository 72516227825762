import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipiing-layout',
  templateUrl: './shipiing-layout.component.html',
  styleUrls: ['./shipiing-layout.component.css']
})
export class ShipiingLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}