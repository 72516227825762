<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Add New LS</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LS </li>
                    <li class="breadcrumb-item active" aria-current="page">Add New LS</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="addNewLR">
                <div class="contentbox">
                    <div class="row account">
                        <div class="col-md-12 border-bottom pb-3 border-gray mb-3">
                            <h4 class="text-dark fw-bold mb-0">Basic Details</h4>

                        </div>
                        <div class="col-xl-12">
                            <div class="row">
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="manualLR_noctrl">Manual LS no.</label>
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none"
                                                id="manualLR_noctrl" placeholder="Enter LS no."
                                                formControlName="manualLR_no">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4"
                                    *ngIf="userSessionData.user_role_id[0] == 1">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Supervisor Name </label>
                                        <select id="supervisornamectrl" formControlName="supervisorname"
                                            placeholder="Select Status" class="form-control shadow-none">
                                            <option selected disabled value="">--Select Supervisor Name--</option>
                                            <option *ngFor="let s of superVisor" value="{{s._id}}">{{s.full_name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="clientListctrl">Company Name<sup class="text-danger">*</sup></label>

                                        <select id="clientListctrl" formControlName="clientList"
                                            placeholder="Select Client Name" class="form-control shadow-none"
                                            (change)="onKeyClientName($event)">
                                            <option disabled value="">--Select Company Name--</option>
                                            <option *ngFor="let cl of clientList" value="{{cl?._id}}">
                                                {{cl?.clientName}}</option>
                                        </select>
                                        <span class="text-danger"
                                            *ngIf="ff.clientList.invalid && (ff.clientList.touched || submitLRClicked)">
                                            Please select Company Name
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="ConsignorNamectrl">Consignor Name<sup
                                                class="text-danger">*</sup></label>
                                        <!-- <div class="d-flex align-items-center"> -->
                                        <input type="text" class="form-control me-1 shadow-none" id="ConsignorNamectrl"
                                            placeholder="Enter Consignor Name" formControlName="ConsignorName"
                                            [matAutocomplete]="autoConsignorName">
                                            <span class="text-danger"
                                            *ngIf="ff.ConsignorName.invalid && (ff.ConsignorName.touched || submitLRClicked)">
                                            Please enter Consignor Name
                                        </span>
                                        <!-- <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="companyList"
                                                    id="ConsignorNamectrl" formControlName="ConsignorName" (onSelect)="onKeyConsigneeName($event)" (onSelect)="getCompanyAddress($event)">
                                                    </ng-multiselect-dropdown> -->

                                        <mat-autocomplete autoActiveFirstOption #autoConsignorName="matAutocomplete"
                                            (optionSelected)="getCompanyAddress($event)">
                                            <mat-option *ngFor="let option of clientList" [value]="option.clientName">
                                                {{option.clientName}}
                                            </mat-option>
                                        </mat-autocomplete>


                                        <!-- <select id="ConsignorNamectrl" formControlName="ConsignorName" (keyup)="onKeyConsigneeName($event)"
                                                placeholder="Select Client Name" class="form-control shadow-none" (change)="getCompanyAddress($event)"
                                                (change)="onKeyClientName($event)">
                                                <option disabled value="">--Select Consignee Name--</option>
                                                <option *ngFor="let cl of clientList" value="{{cl?._id}}">
                                                    {{cl?.clientName}}</option>
                                            </select> -->

                                        <!-- </div> -->
                                       
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="ConsignorAddressctrl">Consignor Address<sup
                                                class="text-danger">*</sup></label>
                                        <input type="text" id="ConsignorAddressctrl" class="form-control shadow-none"
                                            placeholder="Enter Consignor Address" formControlName="ConsignorAddress"
                                            (keyup)="onKeyConsignorAddress($event)"
                                            [matAutocomplete]="autoConsignorAddress">
                                        <mat-autocomplete autoActiveFirstOption #autoConsignorAddress="matAutocomplete">
                                            <!-- <mat-option *ngFor="let option of clientList"
                                                    [value]="option">
                                                    {{option.address}}
                                                </mat-option> -->
                                        </mat-autocomplete>
                                        <span class="text-danger"
                                            *ngIf="ff.ConsignorAddress.invalid && (ff.ConsignorAddress.touched || submitLRClicked)">
                                            Please enter Consignor Address
                                        </span>
                                    </div>
                                </div>


                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">

                                        <label for="ConsigneeNamectrl">Consignee Name<sup
                                                class="text-danger">*</sup></label>
                                        <!-- <div class="d-flex align-items-center"> -->
                                        <input type="text" class="form-control me-1 shadow-none" id="ConsigneeNamectrl"
                                            placeholder="Enter Consignee Name" formControlName="ConsigneeName"
                                            [matAutocomplete]="autoConsigneeAddress">
                                        <mat-autocomplete autoActiveFirstOption #autoConsigneeAddress="matAutocomplete"
                                            (optionSelected)="getCompanyAddressCon($event)">
                                            <mat-option *ngFor="let option of clientList" [value]="option.clientName">
                                                {{option.clientName}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <!-- <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="companyList"
                                                    id="ConsigneeNamectrl" formControlName="ConsigneeName" (onSelect)="onKeyConsigneeName($event)" (onSelect)="getCompanyAddressCon($event)">
                                                 -->
                                        <!-- <select id="ConsigneeNamectrl" formControlName="ConsigneeName" (keyup)="onKeyConsigneeName($event)"
                                                placeholder="Select Client Name" class="form-control shadow-none"   (change)="getCompanyAddressCon($event)"
                                                (change)="onKeyClientName($event)">
                                                <option disabled value="">--Select Consignee Name--</option>
                                                <option *ngFor="let cl of clientList" value="{{cl?._id}}">
                                                    {{cl?.clientName}}</option>
                                            </select> -->
                                        <!-- </ng-multiselect-dropdown> -->
                                        <!-- formControls.actualQuestion.errors?.required -->
                                        <!-- *ngIf=" ff.ConsigneeName.errors?.required" -->
                                        <!-- <span class="text-danger" >
                                                    Only 15 characters are allowed in the GSTIN
                                                </span> -->
                                        <!-- </div> -->
                                        <span class="text-danger"
                                            *ngIf="ff.ConsigneeName.invalid && (ff.ConsigneeName.touched || submitLRClicked)">
                                            Please enter Consignee Name
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="ConsigneeAddressctrl">Consignee Address<sup
                                                class="text-danger">*</sup></label>
                                        <input type="text" id="ConsigneeAddressctrl" class="form-control shadow-none"
                                            placeholder="Enter Consignee Address" formControlName="ConsigneeAddress"
                                            (keyup)="onKeyConsigneeAddress($event)"
                                            [matAutocomplete]="autoConsigneeAddress">
                                        <mat-autocomplete autoActiveFirstOption #autoConsigneeAddress="matAutocomplete">
                                        </mat-autocomplete>



                                        <span class="text-danger"
                                            *ngIf="ff.ConsigneeAddress.invalid && (ff.ConsigneeAddress.touched || submitLRClicked)">
                                            Please enter Consignee Address
                                        </span>
                                    </div>
                                </div>


                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="VehicleNoctrl">Vehicle No.<sup class="text-danger">*</sup></label>
                                        <input type="text" id="VehicleNoctrl" class="form-control shadow-none"
                                            placeholder="Enter Vehicle No." formControlName="VehicleNo"
                                            [matAutocomplete]="autoVehicleNum">

                                        <!-- <select id="VehicleNoctrl" formControlName="VehicleNo" name=""
                                            class="form-control shadow-none" (change)="getVehicleTypeFromNumber($event)" 
                                            (change)="onKeyVehicleNo($event)">
                                            <option disabled value="">-- Select Vehicle No. --</option>
                                            <option *ngFor="let t of vehicles" value="{{ t.vehicle_no}}">{{
                                                t.vehicle_no}}</option>

                                        </select> -->
                                        <mat-autocomplete autoActiveFirstOption #autoVehicleNum="matAutocomplete"
                                            (optionSelected)="getVehicleTypeFromNumber($event)">
                                            <mat-option *ngFor="let option of filteredVehicleNo | async"
                                                [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>


                                        <span class="text-danger"
                                            *ngIf="ff.VehicleNo.invalid && (ff.VehicleNo.touched || submitLRClicked)">
                                            Please enter Vehicle number
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="vehicleTypectrl">Vehicle Type</label>
                                        <input type="text" id="vehicleTypectrl" class="form-control shadow-none"
                                            placeholder="Enter Vehicle Type" formControlName="vehicleType"
                                            (keyup)="onKeyVehicleType($event)" [matAutocomplete]="autoVehicleType">
                                        <mat-autocomplete autoActiveFirstOption #autoVehicleType="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredVehicleTypes | async"
                                                [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>

                                        <!-- <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type" class="form-control shadow-none">
                                        <option  disabled value="">--Select Vehicle Type--</option>
                                         <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">{{c.vehicletype_name}}</option> 
                                        
                                    </select> -->

                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="fromctrl">From<sup class="text-danger">*</sup></label>
                                        <input type="text" id="fromctrl" class="form-control shadow-none"
                                            placeholder="Enter From" formControlName="from" (keyup)="onKeyFrom($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.from.invalid && (ff.from.touched || submitLRClicked)">
                                            Please enter value
                                        </span>
                                    </div>

                                </div>


                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="toctrl">To<sup class="text-danger">*</sup></label>
                                        <input type="text" id="toctrl" class="form-control shadow-none"
                                            placeholder="Enter To" formControlName="to" (keyup)="onKeyTo($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.to.invalid && (ff.to.touched || submitLRClicked)">
                                            Please enter value
                                        </span>
                                    </div>
                                </div>

                                <!-- <div class="col-xl-3 col-md-6 col-12 col-sm-6">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Materials</label> -->
                                        <!-- <div class="d-flex align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none" formControlName="materials"
                                                placeholder="Enter Materials" id="materialsctrl">
                                        </div> -->

                                        <!-- <select id="materialsctrl" formControlName="materials"
                                            class="form-control shadow-none">
                                            <option disabled value="" aria-placeholder="--Materials-">--Select
                                                Material--</option>
                                            <option *ngFor="let cat of this.rate">{{cat.materials}}</option>
                                        </select>



                                    </div>
                                </div> -->


                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="quantityctrl">Quantity/Weight<sup
                                                class="text-danger">*</sup></label>
                                        <input type="text" id="quantityctrl" class="form-control shadow-none"
                                            placeholder="Enter Quantity/Weight" formControlName="quantity"
                                            (keyup)="onKeyQuantity($event)" (change)="focusonWeight($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.quantity.invalid && (ff.quantity.touched || submitLRClicked)">
                                            Please enter quantity
                                        </span>
                                    </div>
                                </div>



                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="GSTINctrl">GSTIN<sup class="text-danger">*</sup></label>
                                        <div class=" align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none" id="GSTINctrl"
                                                placeholder="Enter GSTIN" formControlName="GSTIN"
                                                (keyup)="onKeyGSTIN($event)">
                                            <span class="text-danger" *ngIf="gstin.invalid && gstin.touched">
                                                Only 15 characters are allowed in the GSTIN
                                            </span>

                                        </div>
                                        <span class="text-danger"
                                            *ngIf="ff.GSTIN.invalid && (ff.GSTIN.touched || submitLRClicked)">
                                            Please enter gst number
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="toctrl">To<sup class="text-danger">*</sup></label>
                                        <input type="text" id="toctrl" class="form-control shadow-none"
                                            placeholder="Enter To" formControlName="to" (keyup)="onKeyTo($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.to.invalid && (ff.to.touched || submitLRClicked)">
                                            Please enter value
                                        </span>
                                    </div>
                                </div>
                               -->

                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="datectrl">Date<sup class="text-danger">*</sup></label>
                                        <input type="date" id="datectrl" class="form-control shadow-none"
                                            placeholder="Enter Date" formControlName="date"
                                            (change)="onKeyDate($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.date.invalid && (ff.date.touched || submitLRClicked)">
                                            Please enter date
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="RoadPermitNoctrl">Road Permit No.<sup
                                                class="text-danger">*</sup></label>
                                        <input type="text" id="RoadPermitNoctrl" class="form-control shadow-none"
                                            placeholder="Enter Road Permit No." formControlName="RoadPermitNo"
                                            (keyup)="onKeyRoadPermitNo($event)" [matAutocomplete]="autoVehiclePermit">
                                            <mat-autocomplete autoActiveFirstOption #autoVehiclePermit="matAutocomplete">
                                                <mat-option *ngFor="let option of filteredVehicleRoadPermit | async"
                                                    [value]="option">
                                                    {{option}}
                                                </mat-option>
                                            </mat-autocomplete> -->
                                <!-- <span class="text-danger"
                                            *ngIf="ff.RoadPermitNo.invalid && (ff.RoadPermitNo.touched || submitLRClicked)">
                                            Please enter road permit no.
                                        </span> -->
                                <!-- </div>
                                </div> -->

                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="RoadPermitNoctrl">E-way bill number<sup
                                                class="text-danger"></sup></label>
                                        <input type="text" id="RoadPermitNoctrl" class="form-control shadow-none"
                                            placeholder="Enter E-way bill  No." formControlName="RoadPermitNo"
                                            (keyup)="onKeyRoadPermitNo($event)">

                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="StateCodectrl">State Code<sup class="text-danger">*</sup></label>
                                        <input type="text" id="StateCodectrl" class="form-control shadow-none"
                                            placeholder="Enter State Code" formControlName="StateCode"
                                            (keyup)="onKeyStateCode($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.StateCode.invalid && (ff.StateCode.touched || submitLRClicked)">
                                            Please enter state code
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="DLYctrl">DLY<sup class="text-danger">*</sup></label>
                                        <input type="text" id="DLYctrl" class="form-control shadow-none"
                                            placeholder="Enter DLY" formControlName="DLY" (keyup)="onKeyDLY($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.DLY.invalid && (ff.DLY.touched || submitLRClicked)">
                                            Please enter DLY
                                        </span>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="DeclaredValuectrl">Declared Value<sup
                                                class="text-danger">*</sup></label>
                                        <input disabled type="text" id="DeclaredValuectrl"
                                            class="form-control shadow-none" placeholder="Enter Declared Value"
                                            formControlName="DeclaredValue">
                                    </div>
                                </div> -->
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="fixAdhocctrl">FIX / Adhoc<sup class="text-danger">*</sup></label>
                                        <select formControlName="fixAdhoc" id="fixAdhocctrl"
                                            class="form-control shadow-none" (change)="onKeyFixAdhoc($event)">
                                            <option disabled value="">--Select FIX/Adhoc--</option>
                                            <option value="FIX">FIX</option>
                                            <option value="Adhoc">Adhoc</option>
                                        </select>
                                        <span class="text-danger"
                                            *ngIf="ff.fixAdhoc.invalid && (ff.fixAdhoc.touched || submitLRClicked)">
                                            Please enter fixAdhoc
                                        </span>
                                    </div>
                                </div>


                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="transportorNamectrl">Transporter Name<sup
                                                class="text-danger">*</sup></label>
                                        <input type="text" id="transportorNamectrl" class="form-control shadow-none"
                                            placeholder="Enter Transporter Name" formControlName="transportorName"
                                            (keyup)="onKeyTransportorName($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.transportorName.invalid && (ff.transportorName.touched || submitLRClicked)">
                                            Please enter transportor name
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="transportorMobilectrl">Transporter Mobile<sup
                                                class="text-danger">*</sup></label>
                                        <input type="text" id="transportorMobilectrl" class="form-control shadow-none"
                                            placeholder="Enter Transporter Mobile" formControlName="transportorMobile"
                                            (keyup)="onKeyTransportorMobile($event)">
                                        <span class="text-danger"
                                            *ngIf="ff.transportorMobile.invalid && (ff.transportorMobile.touched || submitLRClicked)">
                                            Please enter transportor Mobile number
                                        </span>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Material</label>
                                        <div class=" align-items-center">
                                            <input  type="text" class="form-control me-1 shadow-none"
                                                placeholder="Material" id="" formControlName="">
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="driverNamectrl">Driver Name<sup class="text-danger">*</sup></label>
                                        <input type="text" id="driverNamectrl" class="form-control shadow-none"
                                            placeholder="Enter Driver Name" formControlName="driverName"  (focusout)="onFocusDriverName($event)"> -->
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="DriverLists"
                                             id="driverNamectrl" formControlName="driverName" (onSelect)="onFocusDriverName($event)" (onSelect)="onFocusDriverName($event)">
                                    </ng-multiselect-dropdown>  -->

                                <!-- <select id="driverNamectrl" formControlName="driverName" name=""
                                            class="form-control shadow-none" (change)="onFocusDriverName($event)">
                                            <option disabled value="">-- Select Vehicle No. --</option>
                                            <option *ngFor="let t of DriverList" value="{{ t.driver_name}}"
                                                (change)="onFocusDriverName($event)">{{ t.driver_name}}</option>
                                        </select>
                                        <span class="text-danger"
                                            *ngIf="ff.driverName.invalid && (ff.driverName.touched || submitLRClicked)">
                                            Please Select Driver Name
                                        </span>
                                    </div>
                                </div> -->
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="driverNamectrl">Driver Name</label>
                                        <input type="text" id="driverNamectrl" class="form-control shadow-none"
                                            placeholder="Enter Driver Name" formControlName="driverName"
                                            [matAutocomplete]="autoDriverName">
                                        <!-- <select id="driverNamectrl" formControlName="driverName" name=""
                                            class="form-control shadow-none" (change)="onFocusDriverName($event)">
                                            <option disabled value="">-- Select Vehicle No. --</option>
                                            <option *ngFor="let t of DriverList" value="{{ t.driver_name}}"
                                                (change)="onFocusDriverName($event)">{{ t.driver_name}}</option>
                                        </select> -->
                                        <mat-autocomplete autoActiveFirstOption #autoDriverName="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredDriverName | async"
                                                [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <span class="text-danger"
                                            *ngIf="ff.driverName.invalid && (ff.driverName.touched || submitLRClicked)">
                                            Please Select Driver Name
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="driverMobilectrl">Driver Mobile</label>
                                        <input type="text" id="driverMobilectrl" class="form-control shadow-none"
                                            placeholder="Enter Driver Mobile" formControlName="driverMobile"
                                            [matAutocomplete]="autoDriverNumber">
                                        <mat-autocomplete autoActiveFirstOption #autoDriverNumber="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredDriverNumber | async"
                                                [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>

                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="Frieghtctrl">Freight<sup class="text-danger">*</sup></label>
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none"
                                                placeholder="Enter Freight Value" id="Frieghtctrl"
                                                formControlName="Frieght" (keyup)="onKeyFrieght($event)">
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="ff.Frieght.invalid && (ff.Frieght.touched || submitLRClicked)">
                                            Please enter fright no.
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Toll Attachment</label>
                                    <div class="d-flex align-items-center">
                                        <input type="file" accept="image/*" (change)="fileChangeEventtollAttachment($event)" class="form-control me-1 shadow-none" id="tollAttachmentctrl" formControlName="tollAttachment" multiple>
                                    </div>
                                    <br>
                                    <button (click)="addTollFiles()"
                                        class="btn btn-primary text-white px-5" type="submit"><b>Upload</b>
                                    </button>
                                </div>
                            </div> -->
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">POD Front</label>
                                        <div class="d-flex align-items-center">
                                            <input type="file" accept="image/*,.pdf,.doc"
                                                (change)="fileChangeEventloadingSlipAttchment($event)"
                                                class="form-control me-1 shadow-none" id="loadingSlipAttchmentctrl"
                                                formControlName="loadingSlipAttchment" multiple
                                                (change)="onKeyloadingSlipAttchment($event)">
                                            <button (click)="addloadingSlips()" mat-raised-button matTooltip="Upload"
                                                class="btn btn-dark ms-2" type="submit"><i
                                                    class="fa-solid fa-upload"></i></button>
                                            <a #showDoc [href]="docURL" target="_blank"></a>
                                            <button (click)="previewLR()" mat-raised-button matTooltip="Preview"
                                                class="btn btn-dark ms-2" type="submit"><i
                                                    class="fa-solid fa-eye"></i></button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="ff.loadingSlipAttchment.invalid && (ff.loadingSlipAttchment.touched || submitLRClicked)">
                                            Please add Slip
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">POD Back</label>
                                        <div class="d-flex align-items-center">
                                            <input type="file" accept="image/*,.pdf,.doc"
                                                (change)="fileChangeEventloadingSlipBackAttchment($event)"
                                                class="form-control me-1 shadow-none" id="loadingSlipAttchmentBackctrl"
                                                formControlName="loadingSlipAttchmentBack" multiple
                                                (change)="onKeyloadingSlipBackAttchment($event)">
                                            <button (click)="addloadingSlipsBack()" mat-raised-button
                                                matTooltip="Upload" class="btn btn-dark ms-2" type="submit"><i
                                                    class="fa-solid fa-upload"></i></button>
                                            <a #showDoc [href]="docURL" target="_blank"></a>
                                            <button (click)="previewLRBack()" mat-raised-button matTooltip="Preview"
                                                class="btn btn-dark ms-2" type="submit"><i
                                                    class="fa-solid fa-eye"></i></button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="ff.loadingSlipAttchmentBack.invalid && (ff.loadingSlipAttchmentBack.touched || submitLRClicked)">
                                            Please add Slip
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4" id="generationIDctrl">

                                    <div class="form-group">
                                        <div class="d-flex align-items-center gap-2">
                                            <label class="example-margin"> Use Same TripID: </label>

                                            <div class="d-flex align-items-center gap-2">
                                                <input type="radio" value="True" [checked]="true"
                                                    (change)="checktripid('true')" formControlName="tripIdGeneration">
                                                <span>Yes</span>


                                                <input type="radio" value="False" (change)="checktripid('false')"
                                                    formControlName="tripIdGeneration" checked>
                                                <span>No</span>
                                            </div>
                                        </div>

                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="multipletrips"
                                            *ngIf="check" id="manualTripIDctrl" formControlName="manualTripID"
                                            [placeholder]="'Manual Trip ID'">


                                        </ng-multiselect-dropdown>




                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                <!-- <div class="contentbox mt-3">
                    <div class="row account ">
                        <div class="col-md-12 border-bottom pb-3 border-gray mb-3">
                            <h4 class="text-dark fw-bold mb-0">Add Invoice Details</h4>
                        </div>
                        <div class="col-xl-12">
                            <div class="row py-2">
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Invoice No<sup class="text-danger">*</sup></label>
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none"
                                                placeholder="Enter Invoice No" id="InvoiceNoctrl"
                                                formControlName="InvoiceNo" (keyup)="onKeyInvoiceNo($event)">
                                        </div>
                                        <span class="text-danger" *ngIf="ff.InvoiceNo.invalid && ff.InvoiceNo.touched">
                                            Only numbers should be accepted
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Invoice Value<sup class="text-danger">*</sup></label>
                                        <div class=" align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none"
                                                placeholder="Enter Invoice Value" id="InvoiceValuectrl"
                                                formControlName="InvoiceValue" (keyup)="onKeyInvoiceValue($event)">
                                            <span class="text-danger"
                                                *ngIf="InvoiceValue.invalid && InvoiceValue.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Number of Articles<sup class="text-danger">*</sup></label>
                                        <div class="align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none"
                                                placeholder="Enter No. of Article" id="NoofArticlectrl"
                                                formControlName="NoofArticle" (keyup)="onKeyNoofArticle($event)">
                                            <span class="text-danger"
                                                *ngIf="NoofArticle.invalid && NoofArticle.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Weight<sup class="text-danger">*</sup></label>
                                        <div class=" align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none"
                                                placeholder="Enter Weight Value" id="Weightctrl"
                                                formControlName="Weight" (keyup)="onKeyWeight($event)">
                                            <span class="text-danger" *ngIf="Weight.invalid && Weight.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for=""> Customer Name <sup class="text-danger">*</sup></label>
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="form-control me-1 shadow-none"
                                                placeholder="Enter Customer Name" id="customerNamectrl"
                                                formControlName="customerName" (keyup)="onKeyCustomerName($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Proof of Delivery</label>
                                        <div class="d-flex align-items-center">
                                            <input type="file" accept="image/*,.pdf,.doc"
                                                (change)="fileChangeEventAttachment($event)"
                                                class="form-control me-1 shadow-none" id="PODctrl"
                                                formControlName="POD">
                                            <a #showPOD [href]="podURL" target="_blank"></a>
                                            <button (click)="previewPOD()" mat-raised-button matTooltip="Preview"
                                                class="btn btn-dark ms-2" type="submit"><i
                                                    class="fa-solid fa-eye"></i></button>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 text-end ">
                                    <button (click)="addInvoiceDetail()" mat-raised-button class="btn btn-dark"
                                        type="submit">Submit & + Add
                                        More Invoices</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12" *ngIf='this.InvoiceDetails.filteredData.length > 0'>
                            <div class="contentbox mt-3">
                                <div class="row account">
                                    <table mat-table [dataSource]="InvoiceDetails" class="mat-elevation-z8 demo-table"> -->
                <!-- Position Column -->
                <!-- <ng-container matColumnDef="noOfArticle">
                                            <th mat-header-cell *matHeaderCellDef> NO of Article </th>
                                            <td mat-cell *matCellDef="let inv"> {{inv.NoofArticle}} </td>
                                        </ng-container> -->

                <!-- Name Column -->
                <!-- <ng-container matColumnDef="invoiceNo">
                                            <th mat-header-cell *matHeaderCellDef> Invoice No. </th>
                                            <td mat-cell *matCellDef="let inv"> {{ inv.InvoiceNo}} </td>
                                        </ng-container> -->

                <!-- Weight Column -->
                <!-- <ng-container matColumnDef="customerName">
                                            <th mat-header-cell *matHeaderCellDef> Customer Name </th>
                                            <td mat-cell *matCellDef="let inv"> {{inv.customerName}} </td>
                                        </ng-container> -->

                <!-- Symbol Column -->
                <!-- <ng-container matColumnDef="invoiceValue">
                                            <th mat-header-cell *matHeaderCellDef> Invoice Value </th>
                                            <td mat-cell *matCellDef="let inv"> {{inv.InvoiceValue}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="weight">
                                            <th mat-header-cell *matHeaderCellDef> Weight </th>
                                            <td mat-cell *matCellDef="let inv"> {{inv.Weight}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="pod">
                                            <th mat-header-cell *matHeaderCellDef class="text-center"> POD </th>
                                            <td mat-cell *matCellDef="let inv;let i = index;" class="text-center">
                                                <button class="btn btn-dark btn-sm  px-4 ms-3" type="button"
                                                    (click)="preview(i)" mat-raised-button>Preview</button> </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                    <a #showParticularPOD [href]="podParticularURL" target="_blank"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="contentbox mt-3">
                    <div class="row account">
                        <div class="col-md-12 border-bottom pb-3 border-gray mb-3">
                            <h4 class="text-dark fw-bold mb-0">Information For office use only</h4>
                        </div>
                        <div class="col-xl-12">
                            <div class="row">
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Starting Meter Reading<sup class="text-danger">*</sup></label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Meter Reading" id="startmeterReadingctrl"
                                                formControlName="startmeterReading"
                                                (keyup)="onKeyStartMeterReading($event)">
                                            <span class="text-danger"
                                                *ngIf="ff.startmeterReading.invalid && (ff.startmeterReading.touched || submitLRClicked)">
                                                Please enter Start Meter Reading
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">End Meter Reading</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter  Meter Reading" id="endmeterReadingctrl"
                                                (change)="onFocusOutendmeterReading($event)"
                                                formControlName="endmeterReading"
                                                (keyup)="onKeyEndMeterReading($event)">
                                            <span class="text-danger"
                                                *ngIf="ff.endmeterReading.invalid && ff.endmeterReading.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip Starting Date<sup class="text-danger">*</sup></label>
                                        <div class="d-flex align-items-center">
                                            <input type="date" class="form-control me-1 shadow-none"
                                                placeholder="Enter Start Time" id="tripSDatectrl"
                                                formControlName="tripSDate">
                                                <span class="text-danger"
                                                *ngIf="ff.tripSDate.invalid && (ff.tripSDate.touched || submitLRClicked)">
                                                Please enter Trip Starting Date
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip End Date</label>
                                        <div class="d-flex align-items-center">
                                            <input type="date" class="form-control me-1 shadow-none"
                                                placeholder="Enter End Time" id="tripEDatectrl"
                                                formControlName="tripEDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip Starting Time<sup class="text-danger">*</sup></label>
                                        <div class="d-flex align-items-center">
                                            <input type="time" class="form-control me-1 shadow-none"
                                                placeholder="Enter Start Time" id="outtimectrl"
                                                formControlName="outtime">

                                                <span class="text-danger"
                                                *ngIf="ff.outtime.invalid && (ff.outtime.touched || submitLRClicked)">
                                                Please enter Trip Starting Time
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip End Time</label>
                                        <div class="d-flex align-items-center">
                                            <input type="time" class="form-control me-1 shadow-none"
                                                placeholder="Enter End Time" (change)="onFocusOuttripEDate($event)"
                                                id="INtimectrl" formControlName="INtime">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Number of Tolls</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Tolls" id="noofToolsctrl"
                                                formControlName="noofTools">
                                            <span class="text-danger"
                                                *ngIf="ff.noofTools.invalid && ff.noofTools.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Total Toll Tax Paid</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Tolls Tax Paid" id="tooltaxPaidctrl"
                                                formControlName="tooltaxPaid">
                                            <span class="text-danger"
                                                *ngIf="ff.tooltaxPaid.invalid && ff.tooltaxPaid.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Labour Loading / Labour Unloading</label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Labour Paid" id="labourPaidctrl"
                                                formControlName="labourPaid">
                                            <span class="text-danger"
                                                *ngIf="ff.labourPaid.invalid && ff.labourPaid.touched">
                                                Only numbers should be accepted
                                            </span>

                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Other payment</label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Other payment" id="otherPaymentctrl"
                                                formControlName="otherPayment">
                                            <span class="text-danger"
                                                *ngIf="ff.otherPayment.invalid && ff.otherPayment.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Usage KM</label>
                                        <div class=" align-items-center">
                                            <input disabled type="text" class="form-control me-1 shadow-none"
                                                placeholder="KM" id="updownkmctrl" formControlName="updownkm">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Total Hours</label>
                                        <div class=" align-items-center">
                                            <input disabled type="number" class="form-control me-1 shadow-none"
                                                placeholder="Total Hours" id="totalhourctrl"
                                                formControlName="totalhour">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Extra Hours</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Extra Hours" id="extrahourctrl"
                                                formControlName="extrahour">
                                            <span class="text-danger"
                                                *ngIf="ff.extrahour.invalid && ff.extrahour.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Detention</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Detention" id="detentionctrl" formControlName="detention">
                                            <span class="text-danger"
                                                *ngIf="ff.detention.invalid && ff.detention.touched">
                                                Only numbers should be accepted
                                            </span>

                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Vehicle Rate<sup class="text-danger">*</sup></label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Vehicle Rate" id="vehicleRatectrl"
                                                formControlName="vehicleRate">
                                                <span class="text-danger"
                                                *ngIf="ff.vehicleRate.invalid && (ff.vehicleRate.touched || submitLRClicked)">
                                                Please enter Vehicle Rate
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Advance<sup class="text-danger">*</sup></label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Advance" id="advancectrl" formControlName="advance">
                                                <span class="text-danger"
                                                *ngIf="ff.advance.invalid && (ff.advance.touched || submitLRClicked)">
                                                Please enter Advance
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Balance Paid<sup class="text-danger">*</sup></label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Balance Paid" (change)="onFocusOutEvent($event)"
                                                id="balancePaidctrl" formControlName="balancePaid">
                                                <span class="text-danger"
                                                *ngIf="ff.balancePaid.invalid && (ff.balancePaid.touched || submitLRClicked)">
                                                Please enter Paid Balance
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Actual Balance</label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Actual Balance" disabled id="balancectrl"
                                                formControlName="balance">
                                        </div>
                                    </div>
                                </div>

                            </div>






                            <!-- <div class="row">
                                                                <div class="col-md-12">
                                                        <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                                            <div class="form-group accountsinp  mb-3">
                                                                <label for="">Toll Attachment</label>
                                                                <div class="d-flex align-items-center">
                                                                    <input type="file" accept="image/*" (change)="fileChangeEventtollAttachment($event)"
                                                                        class="form-control me-1 shadow-none" id="tollAttachmentctrl"
                                                                        formControlName="tollAttachment" multiple>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">

                                                            <button class="btn btn-primary text-white px-5" type="button" (click)="resetForm()"
                                                                mat-raised-button>Upload</button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                                            <div class="form-group accountsinp  mb-3">
                                                                <label for="">Loading Slip Attachment</label>
                                                                <div class="d-flex align-items-center">
                                                                    <input type="file" accept="image/*"
                                                                        (change)="fileChangeEventloadingSlipAttchment($event)"
                                                                        class="form-control me-1 shadow-none" id="loadingSlipAttchmentctrl"
                                                                        formControlName="loadingSlipAttchment" multiple>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">

                                                            <button class="btn btn-primary text-white px-5" type="button" (click)="resetForm()"
                                                                mat-raised-button>Upload</button>
                                                        </div>
                                                    </div>
                                                </div> -->
                        </div>
                    </div>
                </div>
                
                <div class="contentbox mt-3">
                    <div class="row account">
                        <div class="col-md-12 border-bottom pb-3 border-gray mb-3">
                            <h4 class="text-dark fw-bold mb-0">Expense Details</h4>
                        </div>
                        <div class="col-xl-12">
                            <div class="row">


                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Number of Tolls</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Tolls" id="noofToolsctrl"
                                                formControlName="noofTools">
                                            <span class="text-danger"
                                                *ngIf="ff.noofTools.invalid && ff.noofTools.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Total Toll Tax Paid</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Tolls Tax Paid" id="tooltaxPaidctrl"
                                                formControlName="tooltaxPaid">
                                            <span class="text-danger"
                                                *ngIf="ff.tooltaxPaid.invalid && ff.tooltaxPaid.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Labour Loading / Labour Unloading</label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Labour Paid" id="labourPaidctrl"
                                                formControlName="labourPaid">
                                            <span class="text-danger"
                                                *ngIf="ff.labourPaid.invalid && ff.labourPaid.touched">
                                                Only numbers should be accepted
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Other payment</label>
                                        <div class="align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Other payment" id="otherPaymentctrl"
                                                formControlName="otherPayment">
                                            <span class="text-danger"
                                                *ngIf="ff.otherPayment.invalid && ff.otherPayment.touched">
                                                Only numbers should be accepted
                                            </span>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Detention</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Detention" id="detentionctrl"
                                                formControlName="detention">
                                            <span class="text-danger"
                                                *ngIf="ff.detention.invalid && ff.detention.touched">
                                                Only numbers should be accepted
                                            </span>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Diesel Expense</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Diesel Expense" id="driverExpensectrl"
                                                formControlName="dieselExpense">
                                            <!-- <span class="text-danger"
                                                    *ngIf="ff.driverExpense.invalid && ff.driverExpense.touched">
                                                    Only numbers should be accepted
                                                </span> -->

                                        </div>
                                    </div>
                                </div>

                                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                                <div class="form-group accountsinp  mb-3">
                                                    <label for="">Driver Cash</label>
                                                    <div class=" align-items-center">
                                                        <input type="number" class="form-control me-1 shadow-none"
                                                            placeholder="Enter Driver Cash" id="driverCashctrl"
                                                            formControlName="driverCash">
                                                        <span class="text-danger"
                                                                *ngIf="ff.driverCash.invalid && ff.driverCash.touched">
                                                                Only numbers should be accepted
                                                            </span>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Truck Expense</label>
                                        <div class=" align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Truck Expense" id="truckExpensectrl"
                                                formControlName="truckExpense">
                                            <span class="text-danger"
                                                    *ngIf="ff.truckExpense.invalid && ff.truckExpense.touched">
                                                    Only numbers should be accepted
                                                </span>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-12 text-end mt-3">
                    <button class="btn btn-outline-dark " type="button" (click)="resetForm()"
                        mat-raised-button>Reset</button>
                    <button *ngIf="!isMultiple" (click)="addNewLRSubmit()" class="btn btn-dark ms-2"
                        type="submit" mat-raised-button>Create LS</button>
                    <button *ngIf="isMultiple" (click)="addNewLRSubmit()" class="btn btn-dark ms-2"
                        type="submit" mat-raised-button>Save & Create New</button>
                </div>

            </form>
        </div>
    </div>
</div>