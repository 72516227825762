import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { AddInvoiceComponent } from '../add-invoice/add-invoice.component';
import { EditInvoiceComponent } from '../edit-invoice/edit-invoice.component';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { AddfuelExpenseComponent } from '../addfuel-expense/addfuel-expense.component';
import { AddDriverExpenseComponent } from '../add-driver-expense/add-driver-expense.component';

@Component({
  selector: 'app-detail-lr',
  templateUrl: './detail-lr.component.html',
  styleUrls: ['./detail-lr.component.css'],

})
export class DetailLrComponent implements OnInit {



  @Output() clearEvent = new EventEmitter<string>();

  isTab = 'fuelExpense'
  fuelExpenseData: any;
  driverCashData: any;
  clientName: any;
  vehicles: any;
  InvoiceDetails = [];
  clientList = [];
  particlarLR: any;
  vehiclesDetail: any;
  paramId;
  previewLoadingSlipURL: any;
  apiUrl = environment.domain;
  @ViewChild('showLoadingSlipPreview') showLoadingSlipPreview: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;
  displayedColumns: any = ['Date', 'Fuel Quantity', 'Fuel Type', 'Amount', 'Drive Name', 'Action'];
  driverExpenseColumns: any = ['Date', 'Amount Paid', 'Payment Mode', 'Drive Name', 'Action'];


  fuelExpenseDataSource: MatTableDataSource<any>;
  dataSource2: MatTableDataSource<any>;
  dataSource3: MatTableDataSource<any>;
  editLR = new FormGroup({
    clientList: new FormControl(''),
    ConsigneeName: new FormControl(''),
    ConsigneeAddress: new FormControl(''),
    ConsignorName: new FormControl(''),
    ConsignorAddress: new FormControl(''),
    VehicleNo: new FormControl(''),
    vehicleType: new FormControl(''),
    from: new FormControl(''),
    to: new FormControl('',),
    GSTIN: new FormControl(''),
    date: new FormControl(''),
    RoadPermitNo: new FormControl(''),
    StateCode: new FormControl(''),
    DLY: new FormControl(''),
    DeclaredValue: new FormControl(''),
    fixAdhoc: new FormControl(''),
    quantity: new FormControl(''),
    transportorName: new FormControl(''),
    transportorMobile: new FormControl(''),
    driverName: new FormControl(''),
    driverMobile: new FormControl(''),
    tollAttachment: new FormControl(''),
    loadingSlipAttchment: new FormControl(''),
    Frieght: new FormControl(''),


    startmeterReading: new FormControl(''),
    endmeterReading: new FormControl(''),
    tripSDate: new FormControl(''),
    tripEDate: new FormControl(''),
    outtime: new FormControl(''),
    INtime: new FormControl(''),
    noofTools: new FormControl(''),
    tooltaxPaid: new FormControl(''),
    labourPaid: new FormControl(''),
    otherPayment: new FormControl(''),
    updownkm: new FormControl(''),
    totalhour: new FormControl(''),
    extrahour: new FormControl(''),
    detention: new FormControl(''),
    advance: new FormControl(''),
    balance: new FormControl(''),
    balancePaid: new FormControl(''),
    vehicleRate: new FormControl(''),
    // clientList: new FormControl('', [Validators.required]),
    LR_no: new FormControl('', [Validators.required]),
    // ConsigneeName: new FormControl('', [Validators.required]),
    // ConsigneeAddress: new FormControl('', [Validators.required]),
    // ConsignorName: new FormControl('', Validators.required),
    // ConsignorAddress: new FormControl('', Validators.required),
    // VehicleNo: new FormControl('', [Validators.required]),
    // from: new FormControl('', Validators.required),
    // to: new FormControl('', Validators.required),
    // GSTIN: new FormControl('', [Validators.required]),
    // date: new FormControl('', Validators.required),

    // startmeterReading: new FormControl(''),
    // endmeterReading: new FormControl(''),
    // outtime: new FormControl(''),
    // INtime: new FormControl(''),
    // noofTools: new FormControl(''),
    // tooltaxPaid: new FormControl(''),
    // labourPaid: new FormControl(''),
    // otherPayment: new FormControl(''),
    // updownkm: new FormControl(''),
    // totalhour: new FormControl(''),
    // vehicleRate: new FormControl('')

    //status: new FormControl('', Validators.required)
  })

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog, private toastr: ToastrService, private superAdminService: SuperAdminService, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getLRDetail(this.paramId);
      this.getfuelExpenseofParticularLR(this.paramId)
      this.getDriverCashofParticularLR(this.paramId)

    })
  }

  ngOnInit(): void {
  }

  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.clientList = rawdata;
          this.clientName = this.clientList.filter((item) => item._id == this.particlarLR.clientId)[0]['clientName'];
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });

  }
  getLRDetail(id: string) {
    this.superAdminService.particlarLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.particlarLR = res.data[0].receipts[0];
        console.log('LR', this.particlarLR);

        this.vehicles = res.data[0].vehiclesDetail;
        this.getClientList();
        this.superAdminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
          if (supervisors.status == true) {
            var supervisorsData = supervisors.data;
            this.particlarLR.supervisorId = supervisorsData.filter((item) => this.particlarLR.supervisorId == item._id);
            this.particlarLR.supervisorId = this.particlarLR.supervisorId[0] ? this.particlarLR.supervisorId[0]['full_name'] : this.particlarLR.supervisorId[0]
            console.log(this.particlarLR.supervisorId);
          } else {
            console.log(res.message);
          }
        }, error => {
          console.log('error', error);
        })
        this.initform();
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');

        } else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });

  }

  getfuelExpenseofParticularLR(id: string) {
    this.superAdminService.getFuelExpenseofParticularLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        let rawdata = res.data
        this.fuelExpenseData = rawdata
        console.log("lr data of fuel expense", rawdata)

        this.fuelExpenseDataSource = new MatTableDataSource(this.fuelExpenseData)
      }
    })
  }


  getDriverCashofParticularLR(id: string) {
    this.superAdminService.getDriverCashofParticularLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.driverCashData = res.data
        console.log("driver Cash Data", this.driverCashData)
        this.dataSource2 = new MatTableDataSource(this.driverCashData)
      } else {
        if (res.status == 401) {
          this.toastr.error(res.message)
        } else {
          this.toastr.error(res.message)
        }
      }
    })
  }
  initform() {
    this.editLR.patchValue({
      // Basic Details

      clientList: this.particlarLR.clientName,
      LR_no: this.particlarLR.LR_no,
      //date: this.particlarLR.date,
      ConsigneeName: this.particlarLR.consignee_name,
      ConsigneeAddress: this.particlarLR.consignee_address,
      ConsignorName: this.particlarLR.consignor_name,
      ConsignorAddress: this.particlarLR.consignor_address,
      VehicleNo: this.particlarLR.vehicle_id,
      vehicleType: this.particlarLR.vehicleType,
      //VehicleNo: this.particlarLR.vehicle_no,
      from: this.particlarLR.from,
      to: this.particlarLR.to,
      GSTIN: this.particlarLR.GSTIN,
      date: new Date(this.particlarLR.date).toISOString().split('T')[0],
      RoadPermitNo: this.particlarLR.road_permit_no,
      StateCode: this.particlarLR.stateCode,
      DLY: this.particlarLR.dly_no,
      DeclaredValue: this.particlarLR.declared_value,
      fixAdhoc: this.particlarLR.fixAdhoc,
      quantity: this.particlarLR.quantity,
      transportorName: this.particlarLR.transportorName,
      transportorMobile: this.particlarLR.transportorMobile,
      driverName: this.particlarLR.driverName,
      driverMobile: this.particlarLR.driverMobile,
      Frieght: this.particlarLR.Frieght,

      // Information For office use only

      startmeterReading: this.particlarLR.startmeterReading,
      endmeterReading: this.particlarLR.endmeterReading,
      tripSDate: new Date(this.particlarLR.tripStartDate).toISOString().split('T')[0],
      tripEDate: new Date(this.particlarLR.tripEndDate).toISOString().split('T')[0],
      INtime: this.particlarLR.in_time,
      outtime: this.particlarLR.out_time,
      noofTools: this.particlarLR.noofTools,
      tooltaxPaid: this.particlarLR.tooltaxPaid,
      labourPaid: this.particlarLR.labourPaid,
      otherPayment: this.particlarLR.otherPayment,
      updownkm: this.particlarLR.up_down_km,
      totalhour: this.particlarLR.totalhour,
      extrahour: this.particlarLR.extrahour,
      detention: this.particlarLR.detention,
      advance: this.particlarLR.advance,
      balance: this.particlarLR.balance,
      balancePaid: this.particlarLR.balancePaid,
      vehicleRate: this.particlarLR.vehicleRate,


    })
  }
  updateLRSubmit() {
    if (this.editLR.valid) {
      // Basic Details 

      this.removevalidatorcss("LR_noctrl");
      this.removevalidatorcss("ConsigneeNamectrl");
      this.removevalidatorcss("ConsigneeAddressctrl");
      this.removevalidatorcss("ConsignorAddressctrl");
      this.removevalidatorcss("ConsignorNamectrl");
      this.removevalidatorcss("VehicleNoctrl");
      this.removevalidatorcss("fromctrl");
      this.removevalidatorcss("toctrl");
      this.removevalidatorcss("GSTINctrl");
      this.removevalidatorcss("datectrl");
      // this.removevalidatorcss("statusctrl");
      // Information For office use only
      this.removevalidatorcss("startmeterReadingctrl");
      this.removevalidatorcss("endmeterReadingctrl");
      this.removevalidatorcss("outtimectrl");
      this.removevalidatorcss("INtimectrl");
      this.removevalidatorcss("noofToolsctrl");
      this.removevalidatorcss("tooltaxPaidctrl");
      this.removevalidatorcss("labourPaidctrl");
      this.removevalidatorcss("otherPaymentctrl");
      this.removevalidatorcss("updownkmctrl");
      this.removevalidatorcss("totalhourctrl");
      this.removevalidatorcss("vehicleRatectrl");


      //  var c = this.addNewLR.get('InvoiceNo').value
      //   + ',' + document.getElementById("new1")['value']
      //   + ',' + document.getElementById("new2")['value']
      //   + ',' + document.getElementById("new3")['value'];
      var form = {
        // Add Invoice Details
        "_id": this.particlarLR._id,

        // Basic Details

        "ConsigneeName": this.editLR.get('ConsigneeName').value,
        "ConsigneeAddress": this.editLR.get('ConsigneeAddress').value,
        "ConsignorName": this.editLR.get('ConsignorName').value,
        "ConsignorAddress": this.editLR.get('ConsignorAddress').value,
        "VehicleNo": this.editLR.get('VehicleNo').value,
        "GSTIN": this.editLR.get('GSTIN').value,
        "from": this.editLR.get('from').value,
        "to": this.editLR.get('to').value,
        "date": this.editLR.get('date').value,
        //"status": this.editLR.get('status').value,

        // Information For office use only

        "startmeterReading": this.editLR.get('startmeterReading').value,
        "endmeterReading": this.editLR.get('endmeterReading').value,
        "INtime": this.editLR.get('INtime').value,
        "outtime": this.editLR.get('outtime').value,
        "noofTools": this.editLR.get('noofTools').value,
        "tooltaxPaid": this.editLR.get('tooltaxPaid').value,
        "labourPaid": this.editLR.get('labourPaid').value,
        "otherPayment": this.editLR.get('otherPayment').value,
        "updownkm": this.editLR.get('updownkm').value,
        "totalhour": this.editLR.get('totalhour').value,
        "vehicleRate": this.editLR.get('vehicleRate').value,

      }
      this.superAdminService.updateLR({ form })
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(['/superAdmin/lrmanagement']);
            //this.editLR.reset()
          }
          else {
            // alert(res.message)
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.success(res.message);
            }
            //this.editLR.reset()
          }

        }, error => {
        });
    }
    else {
      // Basic Details 

      if (this.editLR.get('ConsigneeAddress').invalid) {
        this.addvalidatorcss("ConsigneeAddressctrl");
      } else {
        this.removevalidatorcss("ConsigneeAddressctrl");
      }
      if (this.editLR.get('ConsignorName').invalid) {
        this.addvalidatorcss("ConsignorNamectrl");
      } else {
        this.removevalidatorcss("ConsignorNamectrl");
      }
      if (this.editLR.get('ConsigneeName').invalid) {
        this.addvalidatorcss("ConsigneeNamectrl");
      } else {
        this.removevalidatorcss("ConsigneeNamectrl");
      }
      if (this.editLR.get('ConsignorAddress').invalid) {
        this.addvalidatorcss("ConsignorAddressctrl");
      } else {
        this.removevalidatorcss("ConsignorAddressctrl");
      }
      if (this.editLR.get('VehicleNo').invalid) {
        this.addvalidatorcss("VehicleNoctrl");
      } else {
        this.removevalidatorcss("VehicleNoctrl");
      }
      if (this.editLR.get('from').invalid) {
        this.addvalidatorcss("fromctrl");
      } else {
        this.removevalidatorcss("fromctrl");
      }
      if (this.editLR.get('to').invalid) {
        this.addvalidatorcss("toctrl");
      } else {
        this.removevalidatorcss("toctrl");
      }
      if (this.editLR.get('date').invalid) {
        this.addvalidatorcss("datectrl");
      } else {
        this.removevalidatorcss("datectrl");
      }
      if (this.editLR.get('GSTIN').invalid) {
        this.addvalidatorcss("GSTINctrl");
      } else {
        this.removevalidatorcss("GSTINctrl");
      }

      // Information For office use only  

      if (this.editLR.get('startmeterReading').invalid) {
        this.addvalidatorcss("startmeterReadingctrl");
      } else {
        this.removevalidatorcss("startmeterReadingctrl");
      }
      if (this.editLR.get('endmeterReading').invalid) {
        this.addvalidatorcss("endmeterReadingctrl");
      } else {
        this.removevalidatorcss("endmeterReadingctrl");
      }
      if (this.editLR.get('outtime').invalid) {
        this.addvalidatorcss("outtimectrl");
      } else {
        this.removevalidatorcss("outtimectrl");
      }
      if (this.editLR.get('INtime').invalid) {
        this.addvalidatorcss("INtimectrl");
      } else {
        this.removevalidatorcss("INtimectrl");
      }
      if (this.editLR.get('noofTools').invalid) {
        this.addvalidatorcss("noofToolsctrl");
      } else {
        this.removevalidatorcss("noofToolsctrl");
      }
      if (this.editLR.get('tooltaxPaid').invalid) {
        this.addvalidatorcss("tooltaxPaidctrl");
      } else {
        this.removevalidatorcss("tooltaxPaidctrl");
      }
      if (this.editLR.get('labourPaid').invalid) {
        this.addvalidatorcss("labourPaidctrl");
      } else {
        this.removevalidatorcss("labourPaidctrl");
      }
      if (this.editLR.get('otherPayment').invalid) {
        this.addvalidatorcss("otherPaymentctrl");
      } else {
        this.removevalidatorcss("otherPaymentctrl");
      }
      if (this.editLR.get('updownkm').invalid) {
        this.addvalidatorcss("updownkmctrl");
      } else {
        this.removevalidatorcss("updownkmctrl");
      }
      if (this.editLR.get('totalhour').invalid) {
        this.addvalidatorcss("totalhourctrl");
      } else {
        this.removevalidatorcss("totalhourctrl");
      }
      if (this.editLR.get('vehicleRate').invalid) {
        this.addvalidatorcss("vehicleRatectrl");
      } else {
        this.removevalidatorcss("vehicleRatectrl");
      }

    }
  }

  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }

  // editInvoice(data){
  //   const dialogRef = this.dialog.open(EditInvoiceComponent,data);
  // }
  addnewinv(acc) {
    const dialogRef = this.dialog.open(AddInvoiceComponent, {
      panelClass: 'addInvoice',
      data: {
        acc,
        getLrDetail: () => { this.getLRDetail(this.paramId) }
      }
    })
  }
  editInvoice(acc, id): void {
    const dialogRef = this.dialog.open(EditInvoiceComponent, {
      panelClass: 'addInvoice',
      data: {
        acc,
        id,
        getLrDetail: () => { this.getLRDetail(this.paramId) }
      }
    });
  }
  previewLoadingSlip(imageUrl: any, backImageUrl: any) {
    const url = imageUrl;
    const backUrl = backImageUrl;
    if (url) {
      let fileExtension = url.split('.').slice(-1)[0];
      if (['jpeg', 'png', 'jpg', 'svg'].includes(fileExtension)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: url, backFile: backUrl }
        });
      }
      else {
        // this.previewLoadingSlipURL = this.sanitizer.bypassSecurityTrustUrl(this.apiUrl+'/'+url);
        // setTimeout(()=>{
        //   this.showLoadingSlipPreview.nativeElement.click();
        // })
      }
    }
    else {
      this.toastr.error("No attachment")
    }
  }
  expenseTab(tabname: any) {
    this.isTab = tabname
  }
  sortData(sort: Sort) {

  }

  addExpence() {
    // console.log("workin", this.paramId)
    const dialogRef = this.dialog.open(AddfuelExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { add: this.paramId, heading: "Add" }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result', result);
      if (result === true) {
        this.getfuelExpenseofParticularLR(this.paramId)

      }
    });

  }

  editExpence(id: any) {
    // console.log("workin", this.paramId)
    console.log("workin2", id)
    const dialogRef = this.dialog.open(AddfuelExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { edit: id, heading: "Edit" }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result', result);
      if (result === true) {
        this.getfuelExpenseofParticularLR(this.paramId)

      }
    });
  }
  driverExpence() {
    const dialogRef = this.dialog.open(AddDriverExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { add: this.paramId, heading: "Add" }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result', result);
      if (result === true) {
        this.getDriverCashofParticularLR(this.paramId)

      }
    });
  }
  editdriverExpence(ids: any) {
    const dialogRef = this.dialog.open(AddDriverExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { edit: ids, heading: "Edit" }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result', result);
      if (result === true) {
        this.getDriverCashofParticularLR(this.paramId)

      }
    });
  }
  backTo() {
    window.history.back()
  }

  checkin(fromDate: any, todate: any) {
    switch (this.isTab) {
      case 'fuelExpense':
        var result = [];
        this.fuelExpenseData.forEach(function (data) {
          console.log("workin", data.date_of_expenses)

          if (data.date_of_expenses >= fromDate && data.date_of_expenses <= todate) {
            result.push(data)

          }
        })
        // console.log("Datass",result)
        this.fuelExpenseDataSource = new MatTableDataSource(result)

        return result
        break;
      case 'driverExpense':
        this.driverCashData

        var result = [];
        this.driverCashData.forEach(function (data) {
          console.log("workin", data.date_of_payment)

          if (data.date_of_payment >= fromDate && data.date_of_payment <= todate) {
            result.push(data)

          }
        })
        // console.log("Datass",result)
        this.dataSource2 = new MatTableDataSource(result)

        return result

        break;
      default:
    }



  }

  clear(fromDate: any, todate: any) {
    switch (this.isTab) {
      case 'fuelExpense':
        fromDate.value = '';
        todate.value = '';
        this.getfuelExpenseofParticularLR(this.paramId)

        break;
      case 'driverExpense':
        fromDate.value = '';
        todate.value = '';
        this.getDriverCashofParticularLR(this.paramId)

        break;

    }

  }

  deleteDriverCash(_id){
    if(this.superAdminService.deleteDailogBox()){
      // console.log(_id)
      this.superAdminService.deleteDriverCash(_id)
      .subscribe((res: {status:any, message: any, authorization: any, code: any}) => {
        if(res.status == true){
          this.toastr.success(res.message)
          this.getDriverCashofParticularLR(this.paramId)
        }else {
          if(res.status == 401){
            this.toastr.error(res.message)
          }
        }
      })
    }
  }


  deleteFuelExpense(_id){
    if(this.superAdminService.deleteDailogBox()){
     
      this.superAdminService.deleteFuelExpense(_id)
      .subscribe((res: {status: any, message: any, authorization: any, code: any}) => {
        if(res.status == true){
          this.toastr.success(res.message)
          this.getfuelExpenseofParticularLR(this.paramId)
        }else {
          if(res.status == 401){
            this.toastr.error(res.message)
          }
        }
      })
    }
  }

}
