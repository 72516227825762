import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuperAdminService } from 'src/app/super-admin.service';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';


@Component({
  selector: 'app-lr-list',
  templateUrl: './views-tracker.component.html',
  styleUrls: ['./views-tracker.component.css']
})
export class viewsTrackerComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  filterform = new FormGroup({
    ClientName: new FormControl(''),
    month: new FormControl('')
  });
  @ViewChild('paginator') paginator: MatPaginator;
  dataSource:  MatTableDataSource<any>;
  displayedColumns: any = ['manualLR_no', 'LR_no', 'vehicle_id','vehicle_Types','from','to', 'date', 'stateCode', 'fixAdhoc','startmeterReading','endmeterReading','tripStartDate', 'tripEndDate', 'noofTools','up_down_km','extrahour'];
  @ViewChild(MatSort) sort: MatSort;
  receipts: any[] = [];
  userroleid;
  clientList;
  userSessionData;
  constructor(private router: Router, private toastr: ToastrService,private superAdminService: SuperAdminService, private spinner:NgxSpinnerService) { }

  ngOnInit(): void {

    this.spinner.show();
    this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
    this.clientList = this.userSessionData.clientList;
    this.userroleid = this.userSessionData.user_role_id[0];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: []
    };
    this.superAdminService.getlrs()
        .subscribe((res: { status: any, message: any, data: any }) => {

          if (res.status == true) {
            var rawdata = res.data;
            this.superAdminService.getClientList()
            .subscribe((clients: { status: any, message: any, data: any }) => {
                if (res.status == true) {
                  this.spinner.hide();
                  //this.receipts = rawdata;            
                  if(this.userSessionData.user_role_id[0]!=1){
                    rawdata = rawdata.filter(item=>{
                      return this.clientList.includes(item.receipt.clientId)
                    })
                  }
                  let temp;
                  rawdata.forEach((element,i) => {
                    temp = clients.data.filter((item)=>item._id==element.receipt.clientId);
                    rawdata[i].receipt.clientName = temp[0]? temp[0]['clientName'] : temp[0]
                  });
                  this.receipts = rawdata.sort(function (a: any, b: any) {
                    return <any>new Date(b.receipt.created_date) - <any>new Date(a.receipt.created_date);
                  });
                  let onlyReceipts = this.receipts.map((item)=> item.receipt);
                  this.dataSource = new MatTableDataSource(onlyReceipts);
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                  // this.clientName = clients.data.filter((item)=>item.clientEmail==this.particlarLR.clientName)[0]['clientName'];
                  //this.dtOptions.data = this.users;
                }
                else {
                  if (res.status == 401) {
                    this.spinner.hide();
                    this.toastr.error(res.message);
                    window.sessionStorage.clear();
                    this.router.navigateByUrl('superAuth/login');
                  }else {
                    this.toastr.error(res.message);
                    this.spinner.hide();
                  }
                }

              }, error => {
              });
          }
          else {
            if (res.status == 401) {
              this.spinner.hide();
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');
              
            }else {
              this.toastr.error(res.message);
              this.spinner.hide();
            }
          }

        }, error => {
            this.toastr.error('Something went wrong! Please contact us');
            this.spinner.hide();
        });

  }

  isArray = false;
  
  filterdata() {
    this.isArray = true;           // to check if data is in array or not.
    var form = {
      "ClientName": this.filterform.get("ClientName").value,
      "month": this.filterform.get("month").value
    }
    
    this.superAdminService.filterData({ form })
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.receipts = [{}];
          this.receipts = rawdata;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });


  }

  emitdata(params) {
    sessionStorage.setItem("userdata", JSON.stringify(params));
  }

  deleteuser(email) {
    this.superAdminService.deleteuser(email)
      .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          alert(res.message)
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }
  filterData(value){
    this.dataSource.filter = value;
  }
  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'consignee_name':
          return compare(lower(a.consignee_name), lower(b.consignee_name), isAsc);
        case 'consignor_name':
          return compare(lower(a.consignor_name), lower(b.consignor_name), isAsc);
        case 'clientName':
          return compare(lower(a.clientName), lower(b.clientName), isAsc);
          case 'vehicleType':
          return compare(lower(a.clientName), lower(b.clientName), isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'from':
          return compare(lower(a.from), lower(b.from), isAsc);
        case 'to':
          return compare(lower(a.to), lower(b.to), isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string , b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value){
      return value?value.toLowerCase():value;
    }
  }
  backTo() {
    window.history.back()
  }

}
