import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { data } from 'jquery';

@Component({
  selector: 'app-view-lr',
  templateUrl: './view-lr.component.html',
  styleUrls: ['./view-lr.component.css']
})
export class ViewLrComponent implements OnInit {
  particlarLR: any;
  vehiclesDetail: any;
  paramId;
  constructor(private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,private router: Router ,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getUserDetails(this.paramId);
    })
  }

  ngOnInit(): void {


  }


  getTotalWeight(invoices: any[]) {
    if (invoices.length > 0) {
      let totalWeight = 0;
      invoices.map((item: any) => totalWeight += Number(item.Weight));
      return totalWeight;
    }
    return 0;
  }

  getTotalHrs(totalHour: any, extraHour: any) {
    if (totalHour && extraHour) {
      return Number(extraHour) + Number(totalHour);
    }
    return 0;
  }

  sendEmail(divName) {
    var data = document.getElementById(divName);
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png', 0.3)
      let pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
      let binary = pdf.output();
      binary = binary ? btoa(binary) : '';
      this.superAdminService.sendEmail(this.paramId, binary).subscribe((res: any) => {
        if (res.status == true) {
          this.toastr.success(res.message);
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }
      }, );
    });

  }
  onPrint(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    //window.print();
  }
  getUserDetails(id: string) {
    this.superAdminService.particlarLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.particlarLR = res.data[0].receipts[0];
        this.vehiclesDetail = res.data[0].vehiclesDetail[0];

      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });
  }
  backTo() {
    window.history.back()
  }
}
