// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
      // domain: "http://192.168.1.13:3000",
            //  domain: "http://localhost:3000",
    // domain: "https://exploreapi.optimly.in",
    // domain: "https://rstsapi.optimly.in/"
    //  domain: "http://radhaswamiapi.myconecion.com/"
      // domain: "https://klapi.optimly.in/"
      //  domain: "https://productionapi.optimly.in/"
    // domain: "https://clientdemoapi.optimly.in/"
    domain: "https://demoapi.optimly.in/",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.