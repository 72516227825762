import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ImagePreviewComponent } from '../../LR-management/image-preview/image-preview.component';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-expance',
  templateUrl: './view-expance.component.html',
  styleUrls: ['./view-expance.component.css'],


})
export class ViewExpanceComponent implements OnInit {

  imagess :any

  paramId: any;
  particularExpense: any ={};
  slipURL: any;

  @ViewChild('showSlip') showSlip:ElementRef;
  apiUrl=environment.domain;
  constructor(private activatedRoute: ActivatedRoute,private router :Router, private toastr: ToastrService, private superAdminService: SuperAdminService, public dialog: MatDialog, private sanitizer:DomSanitizer) { 
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularExpense(this.paramId);
    })
  }

  ngOnInit(): void {
    
   
    
  }
  
 
  preview(){
    let imagePath = this.particularExpense.attachReceipt1;
    if(imagePath){
      let fileExtension=imagePath.split('.').slice(-1)[0];
      if(['jpeg','png','jpg','svg'].includes(fileExtension)){
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width:'80%',
          height:'90vh',
          data:{file:'/uploads/'+imagePath}
      });
      }
      else{
        this.slipURL = this.sanitizer.bypassSecurityTrustUrl(this.apiUrl+'/uploads/'+imagePath);
        setTimeout(()=>{
          this.showSlip.nativeElement.click();
        })
      }
    }
    else{
      this.toastr.error('No Slip Attached')
    }
  }
  getParticularExpense(id: string) {
    this.superAdminService.getParticularExpense(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.particularExpense = res.data[0];
        console.log('expance',this.particularExpense);
        this.imagess =  this.apiUrl+'/uploads/'+this.particularExpense.attachReceipt1
        this.superAdminService.getClientList().subscribe((clientList: { status: any, message: any, data: any }) => {
          if (clientList.status == true) {
            let client;
            client = clientList.data.filter((item)=> item._id == this.particularExpense.companyId);
            this.particularExpense.companyName = client[0]?client[0]['clientName']:client[0];
            this.superAdminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
              if(supervisors.status == true){
                var supervisorsData = supervisors.data;
                this.particularExpense.SupervisorName = supervisorsData.filter((item)=> this.particularExpense.supervisorId==item._id);
                this.particularExpense.SupervisorName = this.particularExpense.SupervisorName[0]?this.particularExpense.SupervisorName[0]['full_name']:this.particularExpense.SupervisorName[0]
                this.superAdminService.getSubCategory().subscribe((subCategories: {status: any, message: any, data: any}) => {
                  if(subCategories.status == true){
                    var subCategoriesData = subCategories.data;
                    let category = subCategoriesData.filter((item)=> this.particularExpense.expenseSubCategoryId==item._id);
                    this.particularExpense.expenseSubCategory = category[0]?category[0]['expenseSubCategory']:category[0];
                    this.particularExpense.expenseCategory = category[0]?category[0]['expenseCategory']:category[0];
                  }else{
                    console.log(res.message);
                  }
                }, error => {
                  console.log('error', error);
                })
              }else{
                console.log(res.message);
              }
            }, error => {
              console.log('error', error);
            })
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
          }

        }, error => {
        });
       
      }
      else {
        console.log(res.message)
      }
  
    }, error => {
    });
  
  }

  backTo() {
    window.history.back()
  }
}
