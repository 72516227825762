<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Fuel Expense</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" (click)="backTo()">Manage LS </li>
                    <li class="breadcrumb-item active" aria-current="page">View Fuel Expense</li>

                </ol>
            </nav>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
                <div class="contentbox">
                    <div class="row account">
                        <div class="col-xl-12">
                            <div class="detailsBox">
                                <div class="row">
                                    <div class="col-xl-3">
                                        <label for="">Fuel Pump</label>
                                        <h5>{{this.particularExpense?.fuel_pump}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Date Of Expenses</label>
                                        <h5>{{this.particularExpense?.date_of_expenses}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Driver Name</label>
                                        <h5>{{this.particularExpense?.driver_name}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Fuel Expense Amount</label>
                                        <h5>{{this.particularExpense?.fuel_expense_amount}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Fuel Quantity</label>
                                        <h5>{{this.particularExpense?.fuel_quantity}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Fuel Type</label>
                                        <h5>{{this.particularExpense?.fuel_type}}</h5>
                                    </div>

                                    <div class="col-xl-6">
                                        <label for="">Payment Mode</label>
                                        <h5>{{this.particularExpense?.payment_mode}}</h5>
                                    </div>

                                    <div class="col-xl-3">
                                        <label for="">Fuel Attachment</label>
                                        <!-- <h5>{{this.particularExpense?.fuel_slip}}</h5> -->
                                        <div class="viewImage" *ngIf="this.particularExpense?.fuel_slip[0]">
                                            <img [id]="apiUrl+'/'+this.particularExpense.fuel_slip[0]" onClick="viewImages(this, 'Fuel Attachment')" *ngIf="particularExpense.fuel_slip[0]" [src]="apiUrl+'/'+this.particularExpense.fuel_slip[0]" alt="fuel Slip" />
                                        </div>
                                    </div>
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</div>